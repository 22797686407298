import React, { useEffect } from "react";
import LRModal from "./LRModal";

import successTick from "assets/images/successTick.svg";
import happySmile from "assets/images/happySmile.svg";

interface ILRSuccessPopupProps {

  title?: string;
  message: JSX.Element;
  isVisible: boolean;
  hasSmile?: boolean;
  hasCloseTimer?: boolean;
  closeIn?: number;
  onClose: (v: boolean) => void;
  onActionClick?(): void;
}

const LRSuccessPopup = ({

  title,
  hasCloseTimer,
  isVisible,
  message,
  hasSmile,
  onClose,
  closeIn,
  onActionClick,
}: ILRSuccessPopupProps) => {
  useEffect(() => {
    if (!hasCloseTimer) return;
    if (!isVisible) return;
    const closeModalTimer = setTimeout(() => {
      onClose(false);
    }, closeIn);
    return () => {
      clearTimeout(closeModalTimer);
    };
  }, [isVisible]);

  return (
    <LRModal
      isSmaller
      isShortest
      isVisible={isVisible}
      onCloseModal={onClose}
      noPadding
      extraClassName="md:max-w-[320px]"
    >
      <>
        <div className="container-end p-7">
        
          <button
            onClick={() => {
              onClose(false);
            }}
            className="border-0 outline-none bg-transparent hidden sm:inline-block"
          >
            <i className="ri-close-fill ri-lg text-black" />
          </button>
        </div>
        <div
          className={`container-center flex-col p-10 pt-0 ${hasSmile ? "space-y-6" : "space-y-4"}`}
        >
          <div className="w-20 md:w-16 h-20 md:h-16 mx-auto">
            <img
              src={hasSmile ? happySmile : successTick}
              alt="success"
              className="w-full h-full"
            />
          </div>
          <div className="text-center space-y-2">
            {title ? <p className="text-base font-medium max-w-[230px] mx-auto">{title}</p> : null}
            <p className="text-sm md:text-xs text-black-80 grotesk">{message}</p>
          </div>
          <div className="py-2">
            <button
              onClick={() => {
                onClose(false);
              }}
              className="text-purple text-sm font-medium"
            >
              Dismiss
            </button>
          </div>
        
        </div>
      </>
    </LRModal>
  );
};

export default LRSuccessPopup;
