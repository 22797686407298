import useMobile from "hooks/useMobile";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { checkIfAuthenticated } from "utility/auth";
import Sidebar from "./Sidebar";

interface IDashboardLayoutProps {}
const DashboardLayout = (props: IDashboardLayoutProps) => {
  const [isMobile] = useMobile();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!checkIfAuthenticated()) {
      navigate("/auth/login/merchant", {
        state: {
          data: location.pathname,
        },
      });
    }
  }, [location.pathname]);
  return (
    <div className="flex items-stretch justify-start relative">
      {isMobile ? null : (
        <>
          <div className="w-[272px] h-screen bg-blue-700"></div>
          <Sidebar />
        </>
      )}
      <div className="bg-[#fafafa] flex items-stretch flex-grow">
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default DashboardLayout;
