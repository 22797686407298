import LRButtonIconGhost from "components/LRButtonIconGhost";
import React, { useEffect, useState } from "react";
import LRInputTableSearch from "./LRInputTableSearch";
import LRTableBody from "./LRTableBody";
import LRTableFilterForm, { TFilterField } from "./LRTableFilterForm/index";
import LRTableRowHead from "./LRTableRowHead";
import ReactPaginate from "react-paginate";
import ExportRecord from "containers/ExportRecord";
import LRSuccessPopup from "components/LRSuccessPopup";
import { getCSVContent } from "utility";

export interface ILRTableColumnProps<T> {
	key: string;
	title: string;
	width?: number;
	render?: (cellData: any, rowData: T) => JSX.Element;
}

interface ILRTableProps<T> {
	data: T[];
	pagination: any;
	hasNoFilters?: boolean;
	isTeamTable?: boolean;
	isLoading?: boolean;
	columns: ILRTableColumnProps<T>[];
	filterFields?: TFilterField[];
	mobileOnlyChild?: JSX.Element | null;
	onRowClick?(row: any): void;
	onChange?(value: any): void;
	fetchDataFunc?: any;
	dataState?: string;
	hasNoQuery?: boolean;
	isHistory?: boolean;
}

const LRTable = ({
	columns,
	data,
	pagination,
	isTeamTable,
	isLoading,
	hasNoFilters,
	onRowClick,
	onChange,
	mobileOnlyChild,
	filterFields = [],
	fetchDataFunc,
	dataState,
	hasNoQuery = false,
	isHistory = false,
}: ILRTableProps<any>) => {
	const [areFiltersVisible, setAreFiltersVisible] = useState(false);
	const [isExportRecordVisible, setIsExportRecordVisible] = useState(false);
	const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
	const [isExportingData, setIsExportingData] = useState(false);
	const [appliedFilters, setAppliedFilters] = useState({});

	const handleQuerySubmit = (query: string) => {
		onChange && onChange({ query, ...pagination });
	};
	const handleFilterFormSubmit = (value: any) => {
		const data = { ...value };
		if (data.hasOwnProperty("receiverCurrency")) {
			data.receiverCurrency = data.receiverCurrency?.coin;
		}
		if (data.hasOwnProperty("senderCurrency")) {
			data.senderCurrency = data.senderCurrency?.fiat;
		}
		if (data.hasOwnProperty("paymentMethod")) {
			data.paymentMethod = data.paymentMethod?.id;
		}
		setAppliedFilters(data);
		onChange && onChange(data);
	};
	const toggleFilters = () => {
		setAreFiltersVisible(!areFiltersVisible);
	};
	const handleExport = (exportType: string) => {
		if (!data?.length) return;
		setIsExportingData(true);
		if (fetchDataFunc) {
			if (isHistory) {
				fetchDataFunc(appliedFilters)
					.then((res: any) => {
						const dataToExport = res?.data?.data?.data;
						if (dataToExport) {
							getCSVContent(dataToExport);
							setIsExportRecordVisible(false);
							setIsSuccessModalVisible(true);
						}
					})
					.finally(() => {
						setIsExportingData(false);
					});
			} else {
				fetchDataFunc({ state: dataState, ...appliedFilters, page: 1, limit: pagination?.count })
					.then((res: any) => {
						const dataToExport = res?.data?.data?.data;
						if (dataToExport) {
							getCSVContent(dataToExport);
							setIsExportRecordVisible(false);
							setIsSuccessModalVisible(true);
						}
					})
					.finally(() => {
						setIsExportingData(false);
					});
			}
		}
	};
	useEffect(() => {
		setAppliedFilters(false);
	}, [dataState]);

	return (
		<>
			<div className="w-full mb-3 space-y-6">
				{isTeamTable ? null : (
					<div className="justify-between hidden mb-3 md:flex item-center">
						<p className="text-sm font-medium grotesk text-black-40">{isLoading ? null : `${pagination?.count || 0} Results`}</p>
						<div className="space-x-3 container-end ">
							{data?.length && !hasNoFilters ? (
								<button
									onClick={() => {
										setIsExportRecordVisible(true);
									}}
								>
									<div className="space-x-1 container-start text-purple">
										<i className="ri-arrow-right-up-line ri-lg"></i>
										<p className="text-sm">Export</p>
									</div>
								</button>
							) : null}
							{data?.length && !hasNoFilters ? <div className="h-8 w-[1.5px] bg-gray-border"></div> : null}
							<div className="space-x-3 container-end">
								{areFiltersVisible || hasNoQuery ? null : <LRInputTableSearch onSubmit={handleQuerySubmit} isDisabled={isLoading} />}
								{data?.length && !hasNoFilters ? (
									<div className="min-w-[96px]">
										<LRButtonIconGhost
											iconName="ri-equalizer-line"
											onClick={toggleFilters}
											text={`${areFiltersVisible ? "Hide" : "Show"} filter`}
										/>
									</div>
								) : null}
							</div>
						</div>
					</div>
				)}
				{isTeamTable ? null : (
					<div className="block space-y-6 md:hidden">
						{hasNoQuery ? null : <LRInputTableSearch onSubmit={handleQuerySubmit} isDisabled={isLoading} />}
						<div className="flex items-start justify-between">
							<p className="text-sm grotesk text-black-40">{isLoading ? null : `${pagination?.count || 0} Transactions`}</p>
							<div>{mobileOnlyChild}</div>
						</div>
					</div>
				)}
				{isTeamTable || hasNoFilters ? null : (
					<div className={!areFiltersVisible ? "hidden" : "hidden md:block"}>
						<LRTableFilterForm
							currentTab={dataState || ""}
							filterFields={filterFields}
							onSubmit={handleFilterFormSubmit}
							appliedFilters={appliedFilters}
						/>
					</div>
				)}
				<div className="w-full bg-white border rounded-lg lg:bg-transparent border-gray-border lg:rounded-none lg:border-none ">
					<LRTableRowHead isLastChildRight={!isTeamTable} columns={columns} />

					<LRTableBody
						isLastChildRight={!isTeamTable}
						columns={columns}
						data={data}
						isLoading={isLoading}
						isEmpty={!data?.length}
						onRowClick={onRowClick}
					/>

					{isTeamTable || !data?.length || isLoading ? null : (
						<div className={`space-y-3 md:space-y-0 md:space-x-3 md:flex items-center justify-start mt-5 mb-6 mx-5 lg:m-0 lg:mt-3`}>
							<ReactPaginate
								breakLabel="..."
								nextLabel={<i className="ri-arrow-right-s-line ri-lg" />}
								onPageChange={(pn) => {
									onChange && onChange({ page: pn.selected + 1, limit: 10 });
								}}
								pageRangeDisplayed={10}
								forcePage={pagination.page - 1}
								pageCount={Math.ceil((pagination?.count || 0) / pagination?.limit)}
								previousLabel={<i className="ri-arrow-left-s-line ri-lg" />}
								nextClassName="lr-pagination-next"
								previousClassName="lr-pagination-previous"
								containerClassName="lr-pagination"
								pageClassName="lr-pagination-item"
								activeClassName="lr-pagination-item_active"
								renderOnZeroPageCount={() => {}}
							/>
							<p className="text-xs font-semibold grotesk">
								Showing <b>{data.length}</b> of <b>{pagination?.count}</b> entries
							</p>
						</div>
					)}
				</div>
			</div>
			<ExportRecord isLoading={isExportingData} isVisible={isExportRecordVisible} onClose={setIsExportRecordVisible} onExport={handleExport} />
			<LRSuccessPopup
				title="Export Complete"
				message={<>Your file has been exported and is ready for download.</>}
				isVisible={isSuccessModalVisible}
				onClose={setIsSuccessModalVisible}
			/>
		</>
	);
};

export default LRTable;
