import React, { useEffect, useState } from "react";
import { ILRTableColumnProps } from ".";

const useColumnWidth = (columns: ILRTableColumnProps<any>[]) => {
  const [customWidthSum, setCustomWidthSum] = useState(0);
  const [customWidthColsNumber, setCustomWidthColsNumber] = useState(0);
  useEffect(() => {
    setCustomWidthSum(columns.reduce((a, b) => a + (b.width || 0), 0));
  }, [columns]);

  useEffect(() => {
    columns.forEach(({ width }) => {
      if (width || 0 > 0) {
        setCustomWidthColsNumber(customWidthColsNumber + 1);
      }
    });
  }, []);

  return {
    customWidthSum,
    customWidthColsNumber,
  };
};

export default useColumnWidth;
