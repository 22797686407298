import React, { useState } from "react";

const LRInputTableSearch = (props: { onSubmit(q: string): void; isDisabled?: boolean }) => {
  const [query, setQuery] = useState("");
  const handleQuerySubmit = (e: any) => {
    e.preventDefault();
    props.onSubmit(query);
  };
  return (
    <form className="w-full container-center md:container-end">
      <div className="border-2 border-r-0 border-gray-border rounded-sm px-4 contianer-start rounded-l-[4px] bg-white flex-grow md:flex-grow-0">
        <div className="container-start space-x-2">
          <i className="ri-search-2-line text-black-20"></i>
          <input
            disabled={props.isDisabled}
            type="text"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            placeholder="Search..."
            className={`text-xs w-full font-medium grotesk text-black-80 text-left py-[10px] md:py-[6px] outline-none hover:outline-none ${
              props.isDisabled ? "cursor-not-allowed" : ""
            }`}
          />
        </div>
      </div>
      <button
        type="submit"
        disabled={props.isDisabled}
        onClick={handleQuerySubmit}
        className={`h-10 md:h-8 w-10 bg-black-80 container-center rounded-r-[4px] ${
          props.isDisabled ? "cursor-not-allowed" : ""
        }`}
      >
        <i className="ri-arrow-right-line text-white"></i>
      </button>
    </form>
  );
};

export default LRInputTableSearch;
