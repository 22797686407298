import React, { useEffect, useState } from "react";

const useRefetch = (fetchCall: () => Promise<any>, duration: number, dep: any[]) => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [isFetchDone, setIsFetchDone] = useState(false);
  useEffect(() => {
    fetchCall().then(() => {
      setIsFetchDone(true);
    });
  }, [...dep]);

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      setIsRefetching(true);
      fetchCall().then((res) => {
        setIsRefetching(false);
      });
    }, duration * 1000);
    return () => {
      clearInterval(refetchInterval);
    };
  }, [isFetchDone, ...dep]);
  return [isRefetching];
};

export default useRefetch;
