import DashboardGreeting from "modules/dashboard/components/DashboardGreeting";
import React from "react";
import { getMerchantFromStorage } from "utility/storage";

import happySmile from "assets/images/happySmile.svg";

const MerchantNotVerified = () => {
  return (
    <div className="px-14 py-8 w-full">
      <DashboardGreeting name={getMerchantFromStorage()?.first_name} />
      <div className="mt-[136px]">
        <div className="space-y-6">
          <img src={happySmile} alt="icon" className="mx-auto" />
          <div className="text-sm text-black-40 font-medium text-center max-w-sm mx-auto space-y-4">
            <div>
              <p>
                Your business + LocalRamp = An <span className="text-black-80">unstoppable</span>{" "}
                force.
              </p>
              <p>We can't wait to work with you.</p>
            </div>
            <p>
              If we don't reach out in a day.{" "}
              <a href="mailto:support@localramp.co">
                <span className="text-purple cursor-pointer">contact support</span>.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantNotVerified;
