import { yupResolver } from "@hookform/resolvers/yup";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { emailValidator, login2FASchema, loginSchema } from "utility/validators";
import useLogin, { IFormValues } from "./useLogin";

const LoginMerchant = () => {
  const { isSubmittingForm, onSubmit, shouldAccept2FA } = useLogin();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(shouldAccept2FA ? login2FASchema : loginSchema),
  });

  return (
    <form className="space-y-6">
      <div className="space-y-3">
        <LCInput
          label="Email address"
          placeholder="joesmith@xyz.com"
          type="email"
          inputRegister={register("email", emailValidator)}
          error={errors.email?.message}
        />
        <LCInput
          hasAutoComplete={false}
          label="Password"
          type="password"
          placeholder="Enter password"
          inputRegister={register("password", { required: true })}
          error={errors.password?.message}
        />
        {shouldAccept2FA ? (
          <LCInput
            hasAutoComplete={false}
            label="Two factor token"
            placeholder="Enter token"
            inputRegister={register("twoFactorToken", { required: true })}
            error={errors.twoFactorToken?.message}
          />
        ) : null}
      </div>

      <div className="space-y-6 text-center grotesk">
        <LRButton text="Login" onClick={handleSubmit(onSubmit)} isLoading={isSubmittingForm} />
        <div className="space-y-4 grotesk">
          <p className="font-light text-black-80 text-xs">
            Forgot password?{" "}
            <Link to={"/auth/reset"} className="text-purple-dark font-semibold">
              <span>Reset Password</span>
            </Link>
          </p>
          <p className="font-light text-xs">
            Do you have an account?{" "}
            <Link to={"/auth/signup"} className="text-purple-dark font-semibold">
              <span>Sign Up</span>
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default LoginMerchant;
