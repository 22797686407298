import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

interface ILRModalProps {
	children: JSX.Element;
	isVisible: boolean;
	height?: string | number;
	onCloseModal: (v: boolean) => void;
	isShort?: boolean;
	isSmall?: boolean;
	isSmaller?: boolean;
	isMid?: boolean;
	noPadding?: boolean;
	extraClassName?: string;
	isShorter?: boolean;
	isShortest?: boolean;
	hasMobileClose?: boolean;
}
const LRModal = ({
	children,
	isVisible,
	height,
	onCloseModal,
	isShort,
	isShorter,
	isShortest,
	isSmall,
	isSmaller,
	isMid,
	noPadding,
	extraClassName = "",
	hasMobileClose = true,
}: ILRModalProps) => {
	const [isBrowser, setIsBrowser] = useState(false);
	const modal = useRef<any>(null);
	const handleClick = (e: any) => {
		if (!modal || !modal.current) return;
		if (modal?.current?.contains(e?.target)) {
			return;
		}

		onCloseModal(false);
		// outside click
	};
	useEffect(() => {
		// add when mounted
		document.addEventListener("mousedown", handleClick);
		// return function to be called when unmounted
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	useEffect(() => {
		const body = document.querySelector("body") as HTMLBodyElement;
		if (isVisible) {
			body.style.overflowY = "hidden";
		}
		return () => {
			body.style.overflowY = "scroll";
		};
	}, [isVisible]);

	const modalContent = isVisible ? (
		<div
			className={`absolute h-screen min-w-full sm:h-full sm:min-h-screen w-screen top-0 left-0 bottom-0 right-0 flex justify-center items-end sm:items-center bg-gray-600/20 animate__slideInUp overflow-hidden z-50`}
		>
			<div className={`w-full ${isSmaller ? "max-w-xs" : isSmall ? "sm:max-w-md" : isMid ? "sm:max-w-[480px]" : "max-w-md sm:max-w-[792px]"}`}>
				<button
					onClick={() => {
						onCloseModal(false);
					}}
					className="w-8 h-8 ml-auto mr-1 border-0 rounded-full outline-none bg-white/80 container-center sm:hidden"
				>
					<i className="text-black ri-close-fill ri-lg" />
				</button>
				<motion.div
					initial={{ y: 300 }}
					animate={{ y: [300, 0, 10] }}
					transition={{ duration: 0.4 }}
					style={{ minHeight: `${height}%` }}
					className={`rounded-t-3xl mx-auto sm:rounded-xl bg-white ${
						isShortest ? "h-[344px]" : isShorter ? "h-[418px] sm:h-auto" : isShort ? "h-[70%] sm:h-auto" : "h-[80%] sm:h-auto"
					} ${!noPadding && "p-6 sm:p-8 pt-[3%]"} ${extraClassName}`}
					ref={modal}
				>
					<>
						{isShorter || !hasMobileClose ? null : (
							<div className="pt-2">
								<div className="h-1 mx-auto rounded-full sm:hidden w-14 bg-gray-border"></div>
							</div>
						)}
						<div className="h-full overflow-y-scroll transition duration-700 transform hide-scrollbar sm:overflow-y-auto">{children}</div>
					</>
				</motion.div>
			</div>
		</div>
	) : null;
	useEffect(() => {
		setIsBrowser(true);
	}, [height]);
	const root = document.getElementById("root");
	if (isBrowser) {
		return ReactDOM.createPortal(modalContent, document.getElementById("modal-root") as Element | DocumentFragment);
	} else {
		return null;
	}
};

export default LRModal;
