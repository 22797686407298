import { useAppDispatch, useAppSelector } from "app/hooks";
import { unloadSwapTransactionDetails } from "app/slices/transaction";
import { fetchSwapTransactionDetails } from "app/slices/transaction/thunk";
import LRDetailItem from "components/LRDetailItem";
import LRModal from "components/LRModal";
import LRStatusTag from "components/LRStatusTag";
import React, { useEffect, useState } from "react";
import { before_, friendlyDateFormatter } from "utility";

interface ISwapTransactionModalProps {
  isVisible: boolean;
  transactionRef: string;
  onClose: (v: boolean) => void;
}
const SwapTransactionModal = ({
  transactionRef,
  isVisible,
  onClose,
}: ISwapTransactionModalProps) => {
  const dispatch = useAppDispatch();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const transactionDetail = useAppSelector(({ transaction }) => transaction.swap.single);
  useEffect(() => {
    if (transactionDetail?.reference) {
      setIsLoadingData(false);
    }
  }, [transactionDetail]);
  useEffect(() => {
    dispatch(fetchSwapTransactionDetails({ reference: transactionRef }));
    return () => {
      setIsLoadingData(true);
      dispatch(unloadSwapTransactionDetails());
    };
  }, []);

  return (
    <LRModal
      hasMobileClose={false}
      isSmall
      isVisible={isVisible}
      onCloseModal={onClose}
      key={transactionDetail?.reference}
    >
      <>
        <div className="container-end">
          <button
            onClick={() => {
              onClose(false);
            }}
            className="border-0 outline-none bg-transparent hidden sm:inline-block"
          >
            <i className="ri-close-fill ri-lg text-black" />
          </button>
        </div>
        <div className="space-y-6">
          <div className="container-start space-x-2 pt-5 md:pt-0">
            <h3>Transaction Details</h3>
            {isLoadingData ? null : <LRStatusTag text={transactionDetail?.state} />}
          </div>
          <div className="grid grid-cols-1 gap-x-10">
            <ul className="bordered-list">
              <LRDetailItem
                isLoading={isLoadingData}
                copy={`${transactionDetail?.reference}`}
                title="Reference"
                value={`${transactionDetail?.reference}`}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                hasCoin={!!transactionDetail?.from_amount}
                title="Swapped From"
                coin={{ coin: transactionDetail?.from_currency }}
                value={
                  transactionDetail?.from_amount
                    ? `${transactionDetail?.from_amount} ${before_(
                        transactionDetail?.from_currency
                      )}`
                    : "..."
                }
              />
              <LRDetailItem
                isLoading={isLoadingData}
                hasCoin={!!transactionDetail?.to_amount}
                title="Swapped To"
                coin={{ coin: transactionDetail?.to_currency }}
                value={
                  transactionDetail?.to_amount
                    ? `${transactionDetail?.to_amount} ${before_(transactionDetail?.to_currency)}`
                    : "..."
                }
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Date Created"
                value={friendlyDateFormatter(transactionDetail?.created_at)}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Initiated From"
                value={transactionDetail?.initiated_from}
              />
            </ul>
          </div>
        </div>
      </>
    </LRModal>
  );
};

export default SwapTransactionModal;
