import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/auth/index";
import dashboardReducer from "./slices/dashboard/index";
import transactionReducer from "./slices/transaction/index";
import settingsReducer from "./slices/settings/index";
import teamReducer from "./slices/team/index";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    transaction: transactionReducer,
    settings: settingsReducer,
    team: teamReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
