import React from "react";

interface ILRButtonIconGhostProps {
  iconName: string;
  text: string;
  onClick(): void;
}
const LRButtonIconGhost = ({ iconName, text, onClick }: ILRButtonIconGhostProps) => {
  return (
    <button onClick={onClick} className="w-full">
      <div className="container-end text-purple space-x-1 w-full">
        <i className={iconName}></i>
        <p className="text-sm">{text}</p>
      </div>
    </button>
  );
};

export default LRButtonIconGhost;
