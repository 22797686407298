import React from "react";
import { afterHyphen, beforeHyphen, before_ } from "../utility";
import { DropDownDatatype } from "./LCDropdown";

interface ILCDropdownListItem {
  option: any;
  onSelect?: (v: DropDownDatatype) => void;
  logo: any;
}

const LCDropdownListItem = ({ option, onSelect, logo }: ILCDropdownListItem) => {
  const handleSelectedItem = () => {
    onSelect && onSelect(option);
  };
  return (
    <button
      onClick={handleSelectedItem}
      className="flex justify-between items-center w-full bg-white py-2 pl-8 pr-5 outline-none rounded-lg"
    >
      <div className="items-center gap-x-2 grid" style={{ gridTemplateColumns: "auto auto" }}>
        <div className={`w-4 h-4 ${!logo?.length && "bg-black-20"} rounded-full`}>
          <img src={logo} alt="" className="rounded-full" />
        </div>
        <div>
          <p className="text-xs text-black-80 uppercase text-left grotesk">
            {afterHyphen(option?.name || option?.coin || option?.fiat).length ? (
              <>
                {beforeHyphen(option?.name || option?.coin || option?.fiat)}
                {" - "}
                <span className="text-black-40">
                  {afterHyphen(option?.name || option?.coin || option?.fiat)}
                </span>
              </>
            ) : (
              `${before_(option?.name || option?.coin || option?.fiat)}`
            )}
          </p>
        </div>
      </div>
      <i className="ri-arrow-right-s-line ri-md text-black-40"></i>
    </button>
  );
};

export default LCDropdownListItem;
