import { useClickOutside } from "hooks/useClickOutside";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { friendlyDateFormatter } from "utility";
import LRDatePicker from "./LRDatePicker";

interface ILRInputDateProps {
  label: string | JSX.Element;
  hasSearch?: boolean;
  placeholder: string;
  selected: Date | string | undefined;
  onSelect: (date: Date | undefined) => void;
}
const LRInputDate = ({ label, placeholder, selected, onSelect }: ILRInputDateProps) => {
  const [shouldShowDatePicker, setShouldShowDatePicker] = useState(false);
  const { popedComponentRef } = useClickOutside(setShouldShowDatePicker);
  const [selectedDate, setSelectedDate] = useState<any>();

  useEffect(() => {
    if (selected !== moment(selectedDate).format("YYYY-MM-DD")) {
      setSelectedDate("");
    }
  }, [selected]);

  const handleOptionsShow = () => {
    setShouldShowDatePicker(!shouldShowDatePicker);
  };

  const handleSelect = (date: Date | undefined) => {
    setShouldShowDatePicker(false);
    onSelect(date);
    setSelectedDate(date);
  };

  return (
    <div ref={popedComponentRef} className="relative min-w-[200px]">
      <div role={"button"} onClick={handleOptionsShow} className="flex flex-col space-y-2">
        <>
          {label}
          <div className="border-2 border-gray-border rounded-[4px] px-3 py-2 container-between bg-white">
            <p className={`text-xs font-light grotesk ${!selected && "text-black-40"}`}>
              {friendlyDateFormatter(selected) || placeholder}
            </p>
          </div>
        </>
      </div>
      <div
        className={`absolute z-20 mt-2 w-full min-w-[296px] min-h-[324px] bg-white border-black-10 rounded-lg ${
          shouldShowDatePicker ? "" : "hidden"
        }`}
        style={{ boxShadow: "0px 0px 8px 0px #EAEAEA8C" }}
      >
        <LRDatePicker selected={selectedDate} setSelected={handleSelect} />
      </div>
    </div>
  );
};

export default LRInputDate;
