import React from "react";

import settingsProfile from "assets/images/settingsProfile.svg";
import { getMerchantFromStorage } from "utility/storage";
import { friendlyDateFormatter } from "utility";

const Profile = () => {
  const merchant = getMerchantFromStorage();
  const businessName = merchant?.operating_business_name;
  const fullName = merchant?.first_name + " " + merchant?.last_name;
  const email = merchant?.email;
  const website = merchant?.website;
  const joined = friendlyDateFormatter(merchant?.joined);
  return (
    <div className="md:pl-12 py-3">
      <div className="space-y-8 md:space-y-0 md:flex md:items-center md:justify-start md:space-x-24">
        <div className="w-full md:w-36 space-y-4 text-left md:text-center">
          <div className="w-20 h-20 mr-auto md:mx-auto">
            <img src={settingsProfile} alt="profile" className="w-full h-full" />
          </div>
          <p className="text-lg font-semibold text-black-80 capitalize">{fullName}</p>
          <div className="space-y-1 text-sm font-semibold grotesk text-black-40">
            <p className="">{merchant?.access || ""}</p>
            <p className="">{businessName}</p>
          </div>
        </div>
        <div className="w-full h-[1px] md:h-56 md:w-[1.5px] bg-gray-border"></div>
        <div className="space-y-4">
          <div className="text-sm font-semibold grotesk ">
            <p className="text-black-20">Full name</p>
            <p className="text-black-80 capitalize">{fullName}</p>
          </div>
          <div className="text-sm font-semibold grotesk ">
            <p className="text-black-20">Email</p>
            <p className="text-black-80">{email}</p>
          </div>
          <div className="text-sm font-semibold grotesk ">
            <p className="text-black-20">Website</p>
            <p className="text-purple">{website || "--"}</p>
          </div>
          <div className="text-sm font-semibold grotesk ">
            <p className="text-black-20">Joined: {joined}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
