import LRButton from "components/LRButton";
import React from "react";

const DashboardErrorPage = () => {
  return (
    <div className="w-full min-h-screen h-full container-center flex-col">
      <div className="space-y-9 max-w-xs">
        <div className=" text-black-40 font-semibold grotesk space-y-1">
          <p className="font-xl text-base text-black">Something went wrong</p>
          <p className="text-sm">Let's try again</p>
        </div>
        <LRButton
          text="Refresh"
          onClick={() => {
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
};
export default DashboardErrorPage;
