import { useAppDispatch, useAppSelector } from "app/hooks";
import { unloadBuyTransactionDetails, unloadSwapTransactionDetails } from "app/slices/transaction";
import { getSwapTransactions } from "app/slices/transaction/api";
import { fetchSwapCurrencies, fetchSwapTransactions } from "app/slices/transaction/thunk";
import LRButton from "components/LRButton";
import LRDateFormatter from "components/LRDateFormatter";
import LRStatusTag from "components/LRStatusTag";
import LRTable, { ILRTableColumnProps } from "components/LRTable";
import LRTabs from "components/LRTabs";
import AuthenticatedPagesOnly from "containers/AuthenticatedPagesOnly";
import React, { useEffect, useState } from "react";
import { checkIfAuthenticated } from "utility/auth";
import { isMerchantAdmin, isMerchantReadWrite } from "utility/storage";
import SwapForm from "../components/SwapForm";
import SwapTransactionModal from "../components/SwapTransactionModal";
import { DataType, ISwapData } from "../types";

const tabs = [
  { title: "Completed", key: "completed" },
  { title: "Pending", key: "pending" },
];
const SwapTransactions = () => {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState("completed");

  const swapTransaction = useAppSelector(({ transaction }) => transaction.swap);

  const { list, supportedCurrencies } = swapTransaction;
  const data = list?.data || [];
  const pagination = list?.pagination || [];

  const [selectedTransactionRef, setSelectedTransactionRef] = useState("");
  const [shouldShowTransactionModal, setShouldShowTransactionModal] = useState(false);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(true);
  const [tableFilter, setTableFilter] = useState({});
  const [allData, setAllData] = useState<{ [x: string]: { data: any[]; pagination: any } }>({});

  const initiateFetchData = (params: any) => {
    dispatch(fetchSwapTransactions({ state: params?.state || currentTab, ...params }))
      .then(({ payload }) => {
        if (payload?.data) setAllData({ ...allData, [params?.state || currentTab]: payload?.data });
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };
  const initiateFetchSupportedCurrencies = () => {
    dispatch(fetchSwapCurrencies({})).finally(() => {
      setIsLoadingCurrencies(false);
    });
  };
  useEffect(() => {
    if (!checkIfAuthenticated()) return;
    initiateFetchData({ state: "completed" });
    initiateFetchSupportedCurrencies();
    return () => {
      setIsDataLoading(true);
    };
  }, []);

  const columns: ILRTableColumnProps<ISwapData>[] = [
    {
      key: "state",
      title: "Status",
      render: (state, {}) => <LRStatusTag text={currentTab} />,
    },
    {
      key: "from_amount",
      title: "Swapped from",
      render: (amount, { from_currency }) =>
        amount ? (
          <p className="font-medium text-black-40 text-xs grotesk">
            <span className="text-black-80 font-bold">{amount}</span> {from_currency}
          </p>
        ) : (
          <p className="text-black-20 text-xs grotesk">...</p>
        ),
    },
    {
      key: "to_amount",
      title: "Swapped to",
      render: (amount, { to_currency }) =>
        amount ? (
          <p className="font-medium text-black-40 text-xs grotesk">
            <span className="text-black-80 font-bold">{amount}</span> {to_currency}
          </p>
        ) : (
          <p className="text-black-20 text-xs grotesk">...</p>
        ),
    },
    {
      key: "created_at",
      title: "Date Created",
      render: (date) => <LRDateFormatter date={date} />,
    },
    {
      key: "updated_at",
      title: "Last Activity",
      render: (date) => <LRDateFormatter date={date} />,
    },
  ];

  const [shouldShowSwapForm, setShouldShowSwapForm] = useState(false);
  const handleRowClick = (row: DataType) => {
    if (!row?.reference) return;
    setSelectedTransactionRef(row.reference);
    setShouldShowTransactionModal(true);
  };
  const handleTableChange = (paramValue: any) => {
    setIsDataLoading(true);
    const value = { ...paramValue };
    if (value.hasOwnProperty("toCurrency")) {
      value.toCurrency = value.toCurrency?.coin;
    }
    if (value.hasOwnProperty("fromCurrency")) {
      value.fromCurrency = value.fromCurrency?.coin;
    }
    if (!value.query) {
      delete value.query;
    }
    if (!value.page || value.query) {
      setTableFilter({ ...value });
      initiateFetchData({ ...value, page: 1 });
    } else {
      initiateFetchData({ ...tableFilter, ...value });
    }
  };
  const handleTabChange = (key: string) => {
    if (key === currentTab) {
      return;
    }
    setIsDataLoading(true);
    initiateFetchData({ state: key });
    setCurrentTab(key);
  };
  return (
    <AuthenticatedPagesOnly pageName="Swap transactions">
      <>
        <div className="px-6 md:px-14 pt-6 space-y-6">
          <LRTabs hasLine tabs={tabs} onChange={handleTabChange} activeTab={currentTab}>
            <div className="w-[110px] pb-2">
              {isMerchantAdmin() || isMerchantReadWrite() ? (
                <LRButton
                  prefixIconName="ri-arrow-right-up-line"
                  text="Swap coins"
                  onClick={() => {
                    setShouldShowSwapForm(true);
                  }}
                />
              ) : null}
            </div>
          </LRTabs>
          <LRTable
            key={"swap-table"}
            dataState={currentTab}
            fetchDataFunc={getSwapTransactions}
            isLoading={isDataLoading}
            columns={columns}
            data={allData[currentTab]?.data || []}
            pagination={allData[currentTab]?.pagination || {}}
            onRowClick={handleRowClick}
            mobileOnlyChild={
              isMerchantAdmin() || isMerchantReadWrite() ? (
                <LRButton
                  prefixIconName="ri-arrow-right-up-line"
                  text="Swap coins"
                  onClick={() => {
                    setShouldShowSwapForm(true);
                  }}
                />
              ) : null
            }
            filterFields={[
              {
                type: "coinType",
                isSearchable: true,
                key: "fromCurrency",
                keyForOption: "coin",
                hasNetwork: false,
                label: "Swapped from",
                isLoading: isLoadingCurrencies,
                options: supportedCurrencies?.coins,
              },
              {
                type: "coinType",
                key: "toCurrency",
                isSearchable: true,
                keyForOption: "coin",
                hasNetwork: false,
                label: "Swapped to",
                isLoading: isLoadingCurrencies,
                options: supportedCurrencies?.coins,
              },
              { type: "startDate" },
              { type: "endDate" },
              { type: "query" },
            ]}
            onChange={handleTableChange}
          />
        </div>
        {shouldShowTransactionModal ? (
          <SwapTransactionModal
            transactionRef={selectedTransactionRef}
            isVisible={shouldShowTransactionModal}
            onClose={setShouldShowTransactionModal}
          />
        ) : null}
        <SwapForm isVisible={shouldShowSwapForm} onClose={setShouldShowSwapForm} />
      </>
    </AuthenticatedPagesOnly>
  );
};

export default SwapTransactions;
