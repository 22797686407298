import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchBalanceWithdrawalNetworks,
  sendBalanceWithdrawCrypto,
} from "app/slices/transaction/thunk";

import LRButton from "components/LRButton";
import LRInputAmountDropdown from "components/LRInputAmountDropdown";
import LRInput from "components/LRInput";
import React, { useEffect, useState } from "react";

import LRInputSelect from "components/LRInputSelect";
import { useForm } from "react-hook-form";
import { resetWithdrawalNetworks } from "app/slices/transaction";
import {
  getBalanceCurrencyWithdrawalLimit,
  getBalanceCurrencyWithdrawalsFee,
} from "app/slices/transaction/api";
import { toast } from "react-toastify";
import { commalize } from "utility";
import { sub } from "utility/arithmeticFuncs";

interface IFormValues {
  amount: string;
  address: string;
  destination_tag?: string;
  network: string;
}
const BalanceWithdrawalForm = ({
  currency,
  onSubmit,
  balanceAmount,
}: {
  currency: string;
  onSubmit: (x: any) => void;
  balanceAmount: string;
}) => {
  const dispatch = useAppDispatch();
  const networks = useAppSelector(({ transaction }) => transaction.balances.withdrawalNetworks);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState<{
    name: string;
    id: string;
    requires_tag: boolean;
    max_decimal: number;
    address_regex: string;
    memo_regex: string;
  }>();
  const [isProcessingWithdraw, setIsProcessingWithdraw] = useState(false);
  const [withdrawalLimit, setWithdrawalLimit] = useState<{
    min_withdraw_amount: string;
    max_withdraw_amount: string | null;
  }>();
  const [rates, setRates] = useState<{ fee: string; type: string }>();
  const [fieldError, setFieldError] = useState({ amount: false });
  const [isFetchingNetworks, setIsFetchingNetworks] = useState(true);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({});

  useEffect(() => {
    if (!currency) return;
    getBalanceCurrencyWithdrawalsFee({ currency: currency.toUpperCase() })
      .then((res) => {
        setRates(res?.data?.data);
      })
      .catch((e) => {
        toast.error(e?.msg);
      });
    getBalanceCurrencyWithdrawalLimit({ currency: currency.toUpperCase() })
      .then((res) => {
        setWithdrawalLimit(res?.data?.data);
      })
      .catch((e) => {
        toast.error(e?.msg);
      });
  }, [currency]);

  useEffect(() => {
    setIsFetchingNetworks(true);
    setSelectedNetwork(undefined);
    dispatch(fetchBalanceWithdrawalNetworks({ currency })).finally(() => {
      setIsFetchingNetworks(false);
    });
    return () => {
      dispatch(resetWithdrawalNetworks());
    };
  }, []);
  useEffect(() => {
    setSelectedNetwork(networks[0]);
    return () => {
      setSelectedNetwork(undefined);
    };
  }, [networks]);

  const onContinue = (formData: IFormValues) => {
    setHasAttemptedSubmit(true);
    const data = { ...formData };
    if (Number(withdrawalAmount) < Number(withdrawalLimit?.min_withdraw_amount || 0)) {
      setFieldError({ amount: true });
      return;
    } else {
      setFieldError({ amount: false });
    }
    setIsProcessingWithdraw(true);
    dispatch(
      sendBalanceWithdrawCrypto({
        currency: currency.toUpperCase(),
        body: {
          destination_tag: "",
          ...data,
          amount: withdrawalAmount,
          network: selectedNetwork?.id,
        },
      })
    )
      .then(({ payload }) => {
        if (payload?.error_id) return;
        onSubmit(data.address);
      })
      .finally(() => {
        setIsProcessingWithdraw(false);
      });
  };

  const handleAmountChange = (amount: string = "") => {
    setWithdrawalAmount(amount.replaceAll(",", ""));
    if (!hasAttemptedSubmit) return;
    if (Number(amount) < Number(withdrawalLimit?.min_withdraw_amount || 0)) {
      setFieldError({ amount: true });
    } else {
      setFieldError({ amount: false });
    }
  };
  return (
    <div className="space-y-5">
      <div className="space-y-2">
        <div className="space-y-4">
          <LRInputAmountDropdown
            hasNoDropdown
            selectedOption={{ coin: currency.toUpperCase() }}
            label="Amount to withdraw"
            decimalsLimit={selectedNetwork?.max_decimal || 8}
            hasError={fieldError.amount}
            value={withdrawalAmount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            minValue={Number(withdrawalLimit?.min_withdraw_amount || 0)}
            error={errors.amount?.message}
            hasMaxAmountButton
            handleMaxClick={() => {
              setWithdrawalAmount(
                `${Math.max(
                  Number(sub(balanceAmount, rates?.fee, selectedNetwork?.max_decimal)),
                  0
                )}`
              );
            }}
          />
          <LRInputSelect
            label="Select network"
            options={networks}
            displayKey={"name"}
            isLoadingOptions={isFetchingNetworks}
            selectedValueText={isFetchingNetworks ? "" : selectedNetwork?.name}
            handleOptionSelect={(option) => {
              setSelectedNetwork(option);
            }}
            placeholder="Network"
          />
          <LRInput
            label="Wallet Address"
            inputRegister={register("address", {
              required: { value: true, message: "Enter a wallet address" },
              pattern: {
                value: new RegExp(selectedNetwork?.address_regex || ""),
                message: "Enter valid address",
              },
            })}
            placeholder="Enter wallet address"
            error={errors.address?.message}
          />
          {selectedNetwork?.requires_tag && !isFetchingNetworks ? (
            <LRInput
              label="Memo / Tag"
              inputRegister={register("destination_tag", {
                required: {
                  value: selectedNetwork?.requires_tag,
                  message: "Enter a destination tag",
                },
                pattern: {
                  value: new RegExp(selectedNetwork?.memo_regex || ""),
                  message: "Enter valid tag",
                },
              })}
              placeholder="Enter tag"
              error={errors.destination_tag?.message}
            />
          ) : null}
        </div>
        {rates?.fee ? (
          <div className="container-between text-black-40 text-xs mt-2">
            <p>Withdrawal Fee</p>
            <p>
              {" "}
              <span className="font-medium text-black-80">
                {rates?.type === "flat"
                  ? `${commalize(Number(rates?.fee), 2, 4)} ${currency?.toUpperCase() || ""}`
                  : `${rates.fee}%`}
              </span>
            </p>
          </div>
        ) : null}
      </div>
      <div className="w-full">
        <LRButton
          text="Continue"
          onClick={handleSubmit(onContinue)}
          isLoading={isProcessingWithdraw}
          isDisabled={!withdrawalLimit?.min_withdraw_amount}
        />
      </div>
    </div>
  );
};

export default BalanceWithdrawalForm;
