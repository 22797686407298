import LRDateFormatter from "components/LRDateFormatter";
import LRDetailItem from "components/LRDetailItem";
import LRModal from "components/LRModal";
import LRStatusTag from "components/LRStatusTag";
import React, { useEffect, useState } from "react";
import { friendlyDateFormatter, maskWalletAddress } from "utility";
import { balanceDetailsStateMap } from "../pages/Balances/BalanceHistory";
import { IBalanceDetails } from "../types";

interface IBalanceTransactionModalProps {
  isVisible: boolean;
  record?: IBalanceDetails;
  onClose: (v: boolean) => void;
}
const BalanceTransactionModal = ({ record, isVisible, onClose }: IBalanceTransactionModalProps) => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  useEffect(() => {
    const t = setTimeout(() => {
      setIsLoadingData(false);
    }, 1000);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <LRModal hasMobileClose={false} isSmall isVisible={isVisible} onCloseModal={onClose}>
      <>
        <div className="container-end">
          <button
            onClick={() => {
              onClose(false);
            }}
            className="border-0 outline-none bg-transparent hidden sm:inline-block"
          >
            <i className="ri-close-fill ri-lg text-black" />
          </button>
        </div>
        <div className="space-y-6">
          <div className="container-start space-x-2">
            <h3>Transaction Details</h3>
            <LRStatusTag
              state={balanceDetailsStateMap(record?.status || "pending")}
              text={record?.status}
            />
          </div>
          <div className="grid grid-cols-1 gap-x-10">
            <ul className="bordered-list">
              <LRDetailItem
                isLoading={isLoadingData}
                hasCoin
                title="Coin"
                coin={{ coin: record?.currency?.toUpperCase() }}
                value={
                  <p className="text-xs text-black-40 font-semibold grotesk uppercase">
                    {record?.currency}
                  </p>
                }
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title={record?.transaction_note ? "Amount Withdrawn" : "Amount Deposited"}
                value={
                  <p className="text-xs text-black-40 font-semibold grotesk uppercase">
                    {record?.amount} {record?.currency}
                  </p>
                }
              />
              <LRDetailItem
                isLoading={isLoadingData}
                copy={record?.destination_address}
                title="Destination Address"
                value={maskWalletAddress(record?.destination_address)}
              />
              <LRDetailItem isLoading={isLoadingData} title="Fee" value={record?.fee} />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Description"
                value={record?.transaction_note ? `Withdrawal` : `Deposit`}
              />
              {record?.transaction_note ? (
                <>
                  <LRDetailItem
                    isLoading={isLoadingData}
                    title="Narration"
                    value={record?.narration}
                  />
                  <LRDetailItem
                    isLoading={isLoadingData}
                    title="Network"
                    value={record?.network}
                  />
                </>
              ) : null}
              <LRDetailItem
                isLoading={isLoadingData}
                title="Date Created"
                value={<LRDateFormatter date={`${record?.created_at}`} />}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Date Updated"
                value={<LRDateFormatter date={`${record?.updated_at}`} />}
              />
            </ul>
          </div>
        </div>
      </>
    </LRModal>
  );
};

export default BalanceTransactionModal;
