import { useAppDispatch } from "app/hooks";
import { getBalanceCrypto } from "app/slices/transaction/api";
import { fetchBalanceCrypto } from "app/slices/transaction/thunk";
import React, { useEffect, useState } from "react";

export const useWalletBalance = (currency?: string) => {
	const dispatch = useAppDispatch();

	const [balances, setBalances] = useState<any>([]);
	const [selectedCoinToSell, setSelectedCoinToSell] = useState(currency || "ltc");

	useEffect(() => {
		getBalanceCrypto({ page: 1, limit: 100 }).then(({ data }) => {
			setBalances(data?.data?.balances);
		});
	}, [selectedCoinToSell]);

	const balanceCoin = () =>
		selectedCoinToSell.length ? balances.filter((coin: any) => selectedCoinToSell?.toLowerCase() === coin.currency)[0]?.currency : "";
	const balanceAmount = () =>
		selectedCoinToSell.length ? balances.filter((coin: any) => selectedCoinToSell?.toLowerCase() === coin.currency)[0]?.balance || 0 : "";

	return {
		balanceCoin,
		balanceAmount,
		setSelectedCoinToSell,
	};
};
