import moment from "moment";
import { toast } from "react-toastify";
import snakify from "snakecase-keys";
import { div, add, mul, sub } from "./arithmeticFuncs";
import logos, { PHONE_CODE_LIST } from "./logos";
import { obj as coinLogoSlug } from "./logoSlugMap";
import { getMerchantFromStorage } from "./storage";
import ngn from "assets/icons/flags/ngn.svg";

export const before_ = (str = "") =>
  str.indexOf("_") > 0 ? str?.substring(0, str?.indexOf("_")) : str;

export const commalize = (v = 0, position = 2, maxPosition?: number) =>
  !isNaN(v)
    ? parseFloat(String(v)).toLocaleString("en-US", {
      minimumFractionDigits: position,
      maximumFractionDigits: maxPosition,
    })
    : v;

export const friendlyDateFormatter = (date: any): string => {
  const momentDate = moment(date);
  if (!date || (typeof date === "string" && !date?.trim().length) || !momentDate.isValid()) {
    return "";
  }
  return momentDate.format("DD MMM, YYYY");
};

export const copyToClipboard = async (stuff: string) => {
  try {
    await navigator.clipboard.writeText(stuff);
    toast.success("Copied!", {
      // icon: "🚀",
      // style: {
      //   fontSize: "12px",
      // },
    });
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const timeOfDay = () => {
  const hour = moment().hour();
  if (hour > 16) {
    return "evening";
  }
  if (hour > 11) {
    return "afternoon";
  }
  return "morning";
};

export const getLogoFor = (logoName: string | undefined) => `https://flagicons.lipis.dev/flags/4x3/${logoName?.toLowerCase()}.svg`

export const findCoinLogo = (option: any) => {
  const formattedKey = (() =>
    Array.isArray(coinLogoSlug[before_(option?.coin)])
      ? coinLogoSlug[before_(option?.coin)][0]
      : coinLogoSlug[before_(option?.coin)] || "")();
  return `https://cdn.jsdelivr.net/gh/godsreal/crypto-icons-plus-32/src/${formattedKey || "coin-fallback"
    }.png`;
};

export const constructParams = (payload: any): string => {
  const formattedParams = snakify(payload);
  if (!Object.keys(payload)?.length) return "";
  let paramString = "";

  for (const [key, value] of Object.entries(formattedParams)) {
    paramString += `${`${formattedParams[key]}`?.length ? `${key}=${value}` : ""}&`;
  }
  return paramString;
};

export const maskWalletAddress = (address = "") => {
  if (!address?.length) return "...";
  return address.length < 50
    ? `${address.substring(0, 6)}...${address.substring(29)}`
    : `${address.substring(0, 6)}...${address.substring(49)}`;
};

export const calculateReceiverAmount = (value: {
  senderAmount: number | string;
  exchangeRate: number | string;
  fee: number | string;
  dpGet?: number;
  dpPay?: number;
}) => {
  if (!value.exchangeRate) return 0;
  return Number(
    sub(mul(value.senderAmount, value.exchangeRate, value.dpGet), value.fee, value.dpPay)
  );
};
export const calculateSenderAmount = (value: {
  receiverAmount: number | string;
  exchangeRate: number | string;
  fee: number | string;
  dpGet?: number;
  dpPay?: number;
}) => {
  if (!value.exchangeRate) return 0;
  return Number(div(mul(value.receiverAmount, 1, value.dpGet), value.exchangeRate, value.dpGet));
};

export const calculateAmountToSwapTo = (value: {
  amountToSwap: number | string;
  exchangeRate: number | string;
  dpGet?: number;
  dpPay?: number;
}) => {
  if (!value.exchangeRate) return 0;
  return Number(mul(value.amountToSwap, value.exchangeRate, value.dpGet));
};

export const calculateAmountToSwap = (value: {
  amountToSwapTo: number | string;
  exchangeRate: number | string;
  dpGet?: number;
  dpPay?: number;
}) => {
  if (!value.exchangeRate) return 0;
  return Number(div(mul(value.amountToSwapTo, 1, value.dpGet), value.exchangeRate, value.dpPay));
};

export const formatProcessorFee = (
  v: {
    type: "FLAT" | "PERCENT";
    fee: number;
  },
  amount: number
) => {
  if (v?.type === "PERCENT") {
    return mul(div(v?.fee, 100), amount);
  }
  return v?.fee;
};

export const afterHyphen = (str: string = "") =>
  str.indexOf("-") > 0 ? str.substring(str.indexOf("-") + 1) : "";

export const beforeHyphen = (str: string = "") =>
  str.indexOf("-") > 0 ? str.substring(0, str.indexOf("-")) : str;

export const getCSVContent = (rows: any[]) => {
  const data = [Object.keys(rows[0]), ...rows.map((row) => Object.values(row))]
    .map((e) => e.join(","))
    .join("\n");
  const blob = new Blob([data], { type: "text/csv" });
  const objUrl = URL.createObjectURL(blob);
  const merchantName = () => getMerchantFromStorage()?.first_name;

  const a = document.createElement("a");

  a.setAttribute("href", objUrl);

  a.setAttribute("download", `${merchantName()}_localramp.csv`);

  a.click();
};

export const toastCaseErrorMSG = (state: any, { payload }: any) => {
  toast.error(payload?.msg || (payload?.message as string));
};

export const getCountryPhoneCode = (country_code: string | undefined) => {
  let a = PHONE_CODE_LIST.find((country) => country.code === country_code)?.dial_code;
  return a
}
