import { createAsyncThunk } from "@reduxjs/toolkit";
import { processCall } from "services/api";
import {
  getBalanceCrypto,
  getBalanceCurrencyDepositAddress,
  getBalanceCurrencyDeposits,
  getBalanceCurrencyWithdrawals,
  getBalanceCurrencyWithdrawalsFee,
  getBalanceDepositNetworks,
  getBalanceWithdrawalNetworks,
  getBuyPaymentMethods,
  getBuySupportedCurrencies,
  getBuyTransactionDetials,
  getBuyTransactions,
  getSellLimits,
  getSellRates,
  getSellSupportedBanks,
  getSellSupportedCurrencies,
  getSellTransactionDetials,
  getSellTransactions,
  getSellTransactionStatus,
  getSwapCurrencies,
  getSwapLimits,
  getSwapQuotes,
  getSwapTransactionDetails,
  getSwapTransactions,
  getSwapVerifyTransaction,
  postBalanceWithdrawCrypto,
  postSellProcessTransaction,
  postSellSetAmounts,
  postSellSetDestination,
  postSellVerifyBank,
  postSwapProcessConvertPairs,
} from "./api";

// BUY
export const fetchBuyTranasactions = createAsyncThunk(
  "transaction/getBuyTransactions",
  processCall(getBuyTransactions)
);
export const fetchBuyTransactionDetials = createAsyncThunk(
  "transaction/getBuyTransactionDetials",
  processCall(getBuyTransactionDetials)
);
export const fetchBuyPaymentMethods = createAsyncThunk(
  "transaction/getBuyPaymentMethods",
  processCall(getBuyPaymentMethods)
);
export const fetchBuySupportedCurrencies = createAsyncThunk(
  "transaction/getBuySupportedCurrencies",
  processCall(getBuySupportedCurrencies)
);

// SELL
export const fetchSellTranasactions = createAsyncThunk(
  "transaction/getSellTransactions",
  processCall(getSellTransactions)
);
export const fetchSellTranasactionDetails = createAsyncThunk(
  "transaction/getSellTransactionDetials",
  processCall(getSellTransactionDetials)
);
export const fetchSellSupportedCurrencies = createAsyncThunk(
  "transaction/getSellSupportedCurrencies",
  processCall(getSellSupportedCurrencies)
);
export const fetchSellLimits = createAsyncThunk(
  "transaction/getSellLimits",
  processCall(getSellLimits)
);
export const fetchSellTransactionStatus = createAsyncThunk(
  "transaction/getSellTransactionStatus",
  processCall(getSellTransactionStatus)
);
export const fetchSellSupportedBanks = createAsyncThunk(
  "transaction/getSellSupportedBanks",
  processCall(getSellSupportedBanks)
);
export const fetchSellRates = createAsyncThunk(
  "transaction/getSellRates",
  processCall(getSellRates)
);
export const sendSellVerifyBank = createAsyncThunk(
  "transaction/postSellVerifyBank",
  processCall(postSellVerifyBank)
);
export const sendSellProcessTransaction = createAsyncThunk(
  "transaction/postSellProcessTransaction",
  processCall(postSellProcessTransaction)
);
export const sendSellSetDestination = createAsyncThunk(
  "transaction/postSellSetDestination",
  processCall(postSellSetDestination)
);
export const sendSellSetAmounts = createAsyncThunk(
  "transaction/postSellSetAmounts",
  processCall(postSellSetAmounts)
);

// SWAP

export const fetchSwapTransactions = createAsyncThunk(
  "transaction/getSwapTransactions",
  processCall(getSwapTransactions)
);
export const fetchSwapTransactionDetails = createAsyncThunk(
  "transaction/getSwapTransactionDetails",
  processCall(getSwapTransactionDetails)
);
export const fetchSwapCurrencies = createAsyncThunk(
  "transaction/getSwapCurrencies",
  processCall(getSwapCurrencies)
);
export const fetchSwapQuotes = createAsyncThunk(
  "transaction/getSwapQuotes",
  processCall(getSwapQuotes)
);
export const fetchSwapLimits = createAsyncThunk(
  "transaction/getSwapLimits",
  processCall(getSwapLimits)
);
export const sendSwapProcessConvertPairs = createAsyncThunk(
  "transaction/postSwapProcessConvertPairs",
  processCall(postSwapProcessConvertPairs)
);
export const fetchSwapVerifyTransaction = createAsyncThunk(
  "transaction/getSwapVerifyTransaction",
  processCall(getSwapVerifyTransaction)
);

// BALANCES
export const fetchBalanceCrypto = createAsyncThunk(
  "transaction/getBalanceCrypto",
  processCall(getBalanceCrypto)
);
export const fetchBalanceCurrencyDeposits = createAsyncThunk(
  "transaction/getBalanceCurrencyDeposits",
  processCall(getBalanceCurrencyDeposits)
);
export const fetchBalanceCurrencyDepositAddress = createAsyncThunk(
  "transaction/getBalanceCurrencyDepositAddress",
  processCall(getBalanceCurrencyDepositAddress)
);
export const fetchBalanceCurrencyWithdrawals = createAsyncThunk(
  "transaction/getBalanceCurrencyWithdrawals",
  processCall(getBalanceCurrencyWithdrawals)
);
export const fetchBalanceCurrencyWithdrawalsFee = createAsyncThunk(
  "transaction/getBalanceCurrencyWithdrawalsFee",
  processCall(getBalanceCurrencyWithdrawalsFee)
);
export const fetchBalanceDepositNetworks = createAsyncThunk(
  "transaction/getBalanceDepositNetworks",
  processCall(getBalanceDepositNetworks)
);
export const fetchBalanceWithdrawalNetworks = createAsyncThunk(
  "transaction/getBalanceWithdrawalNetworks",
  processCall(getBalanceWithdrawalNetworks)
);
export const sendBalanceWithdrawCrypto = createAsyncThunk(
  "transaction/postBalanceWithdrawCrypto",
  processCall(postBalanceWithdrawCrypto)
);
