import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getBalanceCurrencyDeposits,
  getBalanceCurrencyWithdrawals,
} from "app/slices/transaction/api";
import {
  fetchBalanceCurrencyDeposits,
  fetchBalanceCurrencyWithdrawals,
} from "app/slices/transaction/thunk";

import LRDateFormatter from "components/LRDateFormatter";
import LRStatusTag from "components/LRStatusTag";
import LRTable, { ILRTableColumnProps } from "components/LRTable";
import LRTabs from "components/LRTabs";
import AuthenticatedPagesOnly from "containers/AuthenticatedPagesOnly";
import BalanceTransactionModal from "modules/transactions/components/BalanceTransactionModal";
import { IBalanceDetails } from "modules/transactions/types";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { commalize, findCoinLogo } from "utility";
import { checkIfAuthenticated } from "utility/auth";

const tabs = [
  { title: "Deposits", key: "deposits" },
  { title: "Withdrawal", key: "withdrawals" },
];
export const balanceDetailsStateMap = (state: string) =>
  state.toLowerCase() === "done" ||
  state.toLowerCase() === "accepted" ||
  state.toLowerCase() === "success"
    ? "success"
    : "pending";

const BalanceHistory = () => {
  const [currentTab, setCurrentTab] = useState("deposits");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { coin } = useParams();
  const currency = coin?.toUpperCase();
  const [tableFilter, setTableFilter] = useState<any>();

  const [selectedTransaction, setSelectedTransaction] = useState<IBalanceDetails>();
  const [shouldShowTransactionModal, setShouldShowTransactionModal] = useState(false);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [depositState, setDepositState] = useState<any>();
  const [withdrawState, setWithdrawState] = useState<any>();

  const initiateFetchDepositsData = (data: { currency: string; params: any }) => {
    setDepositState({ ...depositState, isLoading: true });
    let updatedState: any;
    dispatch(fetchBalanceCurrencyDeposits({ currency: data.currency, params: data.params }))
      .then(({ payload }) => {
        if (payload?.data) {
          updatedState = { ...payload.data };
          setDepositState({ ...payload?.data, isLoading: false });
        }
      })
      .finally(() => {
        setDepositState({ ...updatedState, isLoading: false });
      });
  };
  const initiateFetchWithdrawalsData = (data: { currency: string; params: any }) => {
    setWithdrawState({ ...withdrawState, isLoading: true });
    let updatedState: any;
    dispatch(fetchBalanceCurrencyWithdrawals({ currency: data.currency, params: data.params }))
      .then(({ payload }) => {
        if (payload?.data) {
          updatedState = { ...payload.data };
          setWithdrawState({ ...payload?.data, isLoading: false });
        }
      })
      .finally(() => {
        setWithdrawState({ ...updatedState, isLoading: false });
      });
  };

  const initiateFetchData = (tab: any, params: any) => {
    if (!currency) return;
    if (tab?.state === "withdrawals") {
      initiateFetchWithdrawalsData({ currency, params });
    } else {
      initiateFetchDepositsData({ currency, params });
    }
  };

  useEffect(() => {
    if (!currency || !checkIfAuthenticated()) return;
    initiateFetchDepositsData({ currency, params: {} });
  }, []);

  const columns: ILRTableColumnProps<IBalanceDetails>[] = [
    {
      key: "status",
      title: "Status",
      render: (status, {}) => <LRStatusTag state={balanceDetailsStateMap(status)} text={status} />,
    },
    {
      key: "amount",
      title: "Amount",
      render: (amount, { currency }) => (
        <p className="font-bold text-black-20 text-xs grotesk">
          <span className="text-black-80 font-semibold">{amount}</span>{" "}
          <span className="uppercase">{currency}</span>
        </p>
      ),
    },
    {
      key: "currency",
      title: "Description",
      render: (amount) => (
        <p className="font-medium text-black-40 text-xs grotesk">
          {currentTab === "withdrawals" ? "Withdraw" : "Deposits"}
        </p>
      ),
    },
    {
      key: "created_at",
      title: "Date Created",
      render: (date) => <LRDateFormatter date={date} />,
    },
    {
      key: "updated_at",
      title: "Last Activity",
      render: (date) => <LRDateFormatter date={date} />,
    },
  ];

  const handleRowClick = (row: IBalanceDetails) => {
    setSelectedTransaction(row);
    setShouldShowTransactionModal(true);
  };
  const handleTableChange = (paramValue: any) => {
    setIsDataLoading(true);
    const value = { ...paramValue };
    delete value.count;
    if (!value.query) {
      delete value.query;
    }
    if (!value.page || value.query) {
      setTableFilter({ ...value });
      initiateFetchData({ state: currentTab }, { ...value, page: 1 });
    } else {
      initiateFetchData({ state: currentTab }, { ...tableFilter, ...value });
    }
  };
  const handleTabChange = (key: string) => {
    if (key === currentTab) {
      return;
    }
    setIsDataLoading(true);
    setCurrentTab(key);
    initiateFetchData({ state: key }, {});
  };
  return (
    <AuthenticatedPagesOnly pageName="Balances">
      <>
        <div className="px-6 md:px-14 pt-6 space-y-6">
          <div className="container-start space-x-[2px]">
            <Link to="/dashboard/balances">
              <span className="text-sm text-purple-dark">Balances</span>
            </Link>
            <i className="ri-arrow-right-s-line text-black-20 mt-1" />
            <p className="text-sm text-black-20 mt-[2px]">{currency}</p>
          </div>
          <LRTabs hasLine tabs={tabs} onChange={handleTabChange} activeTab={currentTab}>
            <div className="flex-grow pb-2 text-right">
              <p className="text-xxs grotesk font-semibold text-black-40">BALANCE</p>
              <div className="container-end space-x-1">
                <div>
                  <div className="w-4 h-4">
                    <img src={findCoinLogo({ coin: currency || "" })} alt="" className="w-4 h-4" />
                  </div>
                </div>
                <p className="flex-grow">{location?.state?.balance || "0.0"}</p>
              </div>
            </div>
          </LRTabs>
          <LRTable
            key={currentTab}
            columns={columns}
            hasNoQuery={true}
            data={currentTab === "withdrawals" ? withdrawState?.data : depositState?.data}
            pagination={
              currentTab === "withdrawals" ? withdrawState?.pagination : depositState?.pagination
            }
            onRowClick={handleRowClick}
            isLoading={
              currentTab === "withdrawals" ? withdrawState?.isLoading : depositState?.isLoading
            }
            isHistory={true}
            fetchDataFunc={
              currentTab === "withdrawals"
                ? (filters: any) => {
                    return getBalanceCurrencyWithdrawals({
                      currency: coin || "",
                      params: {
                        ...filters,
                        page: 1,
                        limit: withdrawState?.pagination?.count,
                      },
                    });
                  }
                : (filters: any) => {
                    return getBalanceCurrencyDeposits({
                      currency: coin || "",
                      params: {
                        ...filters,
                        page: 1,
                        limit: depositState?.pagination?.count,
                      },
                    });
                  }
            }
            filterFields={[{ type: "startDate" }, { type: "endDate" }]}
            onChange={handleTableChange}
          />
        </div>
        {shouldShowTransactionModal ? (
          <BalanceTransactionModal
            record={selectedTransaction}
            isVisible={shouldShowTransactionModal}
            onClose={setShouldShowTransactionModal}
          />
        ) : null}
      </>
    </AuthenticatedPagesOnly>
  );
};

export default BalanceHistory;
