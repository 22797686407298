import LRInput from "components/LRInput";
import { useClickOutside } from "hooks/useClickOutside";
import React, { useEffect, useState } from "react";
import { before_ } from "utility";

interface ILRInputSelectProps {
  label: string;
  options: any[];
  hasSearch?: boolean;
  noIcon?: boolean;
  isLoadingOptions?: boolean;
  displayKey?: string;
  placeholder: string;
  selectedValueText?: string;
  handleOptionSelect: (option: any) => void;
}
const LRInputSelect = ({
  label,
  options,
  noIcon = true,
  hasSearch,
  displayKey,
  placeholder,
  isLoadingOptions,
  selectedValueText,
  handleOptionSelect,
}: ILRInputSelectProps) => {
  const [shouldShowOptions, setShouldShowOptions] = useState(false);
  const [query, setQuery] = useState("");
  const [optionsToDisplay, setOptionsToDisplay] = useState(options);
  const { popedComponentRef } = useClickOutside(setShouldShowOptions);

  useEffect(() => {
    setOptionsToDisplay(options);
  }, [options]);

  const handleOptionsShow = () => {
    setShouldShowOptions(!shouldShowOptions);
  };
  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    setOptionsToDisplay(
      options.filter(
        (option) =>
          option?.fiat?.toLowerCase().includes(value.toLowerCase()) ||
          option?.coin?.toLowerCase().includes(value.toLowerCase()) ||
          option[displayKey || ""].toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <div className="relative min-w-[200px]" ref={popedComponentRef}>
      <div role={"button"} onClick={handleOptionsShow} className="flex flex-col space-y-2">
        <p className="text-xs font-medium text-black-40">{label}</p>
        <div className="py-1 container-between border-b border-black-20">
          <p className={`text-sm font-normal ${!selectedValueText && "text-black-40"}`}>
            {selectedValueText || placeholder}
          </p>
          <i
            className={`${
              shouldShowOptions ? "ri-arrow-up-s-fill" : "ri-arrow-down-s-fill"
            } text-black`}
          ></i>
        </div>
      </div>
      {shouldShowOptions ? (
        <div
          className="absolute mt-2 w-full  max-h-[206px] bg-white border-black-10 rounded-lg z-10 py-2"
          style={{ boxShadow: "0px 0px 8px 0px #EAEAEA8C" }}
        >
          {isLoadingOptions ? (
            <span className="mx-auto w-5 h-5">
              <div className="animate-spin text-center">
                <i className="ri-loader-5-line text-black-40" />
              </div>
            </span>
          ) : (
            <>
              {hasSearch ? (
                <div className="px-3 py-2">
                  <LRInput
                    label=""
                    placeholder={placeholder}
                    value={query}
                    onChange={handleQueryChange}
                  />
                </div>
              ) : null}
              <div className="max-h-[90px] overflow-y-scroll">
                {optionsToDisplay.map((option, i) => (
                  <button
                    key={i}
                    onClick={() => {
                      setShouldShowOptions(false);
                      handleOptionSelect(option);
                    }}
                    className="px-3 py-2 hover:bg-gray-50 w-full bg-white border-0"
                  >
                    <div className="container-start space-x-3">
                      {noIcon ? null : <div className="w-4 h-4 bg-black-10 rounded-full"></div>}
                      <p className="font-medium text-xs text-black-80">
                        {displayKey ? option[displayKey] : before_(option)}
                      </p>
                    </div>
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default LRInputSelect;
