import LRTabsLight from "components/LRTabsLight";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const tabs = [{ title: "Merchant" }, { title: "Team Member" }];

const Login = () => {
  const [activeTab, setActiveTab] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("merchant")) {
      setActiveTab(0);
    } else {
      setActiveTab(1);
    }
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LocalRamp | Login</title>
        <link rel="canonical" href="http://merchant.localramp.co" />
      </Helmet>
      <div className="px-6 md:px-10 pt-8 md:pt-10 pb-14">
        <div>
          <div className="text-center space-y-2 mb-3 sm:mb-4">
            <h2 className="">Merchant Login</h2>
            <p className="text-xs text-black-40 font-light grotesk">Do more with LocalRamp.</p>
          </div>
          <div className="space-y-6">
            <div className="flex items-center justify-center space-x-6">
              {tabs.map((tab, i) => (
                <LRTabsLight
                  key={i}
                  {...tab}
                  isActive={i === activeTab}
                  onClick={() => {
                    setActiveTab(i);
                    navigate(i ? "team" : "");
                  }}
                />
              ))}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
