import { CLIENT_USER_DATA } from "constants/storage";

export const getJsonFromStorage = (key: string) =>
  JSON.parse(window.localStorage.getItem(key) || "{}");

export const setJsonInStorage = (key: string, value: string) =>
  window.localStorage.setItem(key, JSON.stringify(value));

export const setInStorage = (key: string, value: string) => window.localStorage.setItem(key, value);

export const clearAllStorage = () => window.localStorage.clear();

export const getFromStorage = (key: string) => window.localStorage.getItem(key);

export const setInStorageLocal = (key: string, value: string) =>
  window.localStorage.setItem(key, value);

export const getFromStorageLocal = (key: string) => window.localStorage.getItem(key);

// SPECIFIC
export const getMerchantFromStorage = () => {
  if (
    !window.localStorage.getItem(CLIENT_USER_DATA) ||
    window.localStorage.getItem(CLIENT_USER_DATA) === "undefined"
  ) {
    clearAllStorage();
    return {};
  }
  return JSON.parse(window.localStorage.getItem(CLIENT_USER_DATA) || "{}");
};

export const storageMerchantRole = () => getMerchantFromStorage()?.access;
export const isMerchantAdmin = () => getMerchantFromStorage()?.access === "ADMIN";
export const isMerchantReadWrite = () => getMerchantFromStorage()?.access === "READ/WITHDRAW";
export const isMerchantReadOnly = () => getMerchantFromStorage()?.access === "READ";
