import React, { useEffect } from "react";
import LRModal from "./LRModal";
import { toast } from "react-toastify";
import { toastMessage } from "utility/toast";
import successTick from "assets/images/successTick.svg";
import happySmile from "assets/images/happySmile.svg";

interface ILRSuccessPopupProps {
	headerTitle?: string;
	title?: string;
	message: JSX.Element;
	isVisible: boolean;
	hasSmile?: boolean;
	hasCloseTimer?: boolean;
	closeIn?: number;
	onClose: (v: boolean) => void;
	onActionClick?(): void;
}

const LRPartnershipPopup = ({
	headerTitle,
	title,
	hasCloseTimer,
	isVisible,
	message,
	hasSmile,
	onClose,
	closeIn,
	onActionClick,
}: ILRSuccessPopupProps) => {
	useEffect(() => {
		if (!hasCloseTimer) return;
		if (!isVisible) return;
		const closeModalTimer = setTimeout(() => {
			onClose(false);
		}, closeIn);
		return () => {
			clearTimeout(closeModalTimer);
		};
	}, [isVisible]);

	return (
		<LRModal isSmaller isShortest isVisible={isVisible} onCloseModal={onClose} noPadding extraClassName="md:max-w-[320px]">
			<>
				<div className={` ${headerTitle ? "flex justify-between items-center" : " container-end"} p-7`}>
					{headerTitle ? <p className="text-base font-medium">{headerTitle}</p> : null}

					<button
						onClick={() => {
							onClose(false);
						}}
						className="hidden bg-transparent border-0 outline-none sm:inline-block"
					>
						<i className="text-black ri-close-fill ri-lg" />
					</button>
				</div>
				<div className={`container-center flex-col gap-3 justify-center items-center p-10 pt-0 ${hasSmile ? "space-y-6" : "space-y-4"}`}>
					<div className="text-center space-y-2 bg-[#E0DAFE33] p-[1rem]">
						{title ? <p className="text-sm font-medium max-w-[230px] mx-auto">{title}</p> : null}
					</div>

					<div className="text-center space-y-2 my-[2rem]">
						<p className="text-sm md:text-xs text-black-80 grotesk">{message}</p>
					</div>

					<button
						onClick={() => {
							navigator.clipboard.writeText(`${title}`).then(() => toastMessage.success("copied"));
						}}
						className="text-white  text-sm font-medium my-[4rem] bg-purple w-full p-4 rounded-[4px] h-[40px] flex items-center justify-center"
					>
						Copy Link
					</button>
				</div>
			</>
		</LRModal>
	);
};

export default LRPartnershipPopup;
