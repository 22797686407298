import useMobile from "hooks/useMobile";
import React, { useEffect, useState } from "react";
import { ILRTableColumnProps } from ".";
import useColumnWidth from "./useColumnWidth";

interface ILRTableRowHeadProps {
  columns: ILRTableColumnProps<any>[];
  isLastChildRight?: boolean;
}
const LRTableRowHead = ({ columns, isLastChildRight = true }: ILRTableRowHeadProps) => {
  const [isMobile] = useMobile();
  const { customWidthColsNumber, customWidthSum } = useColumnWidth(columns);

  return (
    <div
      className={`w-full pb-3 bg-transparent items-center ${
        isLastChildRight ? "table-head-row" : ""
      } hidden lg:flex`}
    >
      {columns.map(({ title, width }, i) => (
        <p
          key={i}
          className="grotesk font-semibold text-xxs text-black-20 px-4 uppercase"
          style={{
            width: isMobile
              ? "100%"
              : `${width || (100 - customWidthSum) / (columns.length - customWidthColsNumber)}%`,
          }}
        >
          {title}
        </p>
      ))}
    </div>
  );
};

export default LRTableRowHead;
