import { createAsyncThunk } from "@reduxjs/toolkit";
import { processCall } from "services/api";
import { getDashboardStats } from "./api";

// THE
// THUNKS

export const fetchDashboardStats = createAsyncThunk(
  "dashboard/getDashboardStats",
  processCall(getDashboardStats)
);
