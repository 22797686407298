import React from "react";

interface ILRTransactionOverviewItemProps {
  value: string | number;
  title: string;
}
const LRTransactionOverviewItem = ({ value, title }: ILRTransactionOverviewItemProps) => {
  return (
    <div className="space-y-1 text-center lg:text-left w-full">
      <p className="text-2xl font-semibold text-black-80">{value}</p>
      <p className="text-xxs text-black-20 uppercase grotesk tracking-[0.04em]">{title}</p>
    </div>
  );
};

export default LRTransactionOverviewItem;
