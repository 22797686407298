import React from "react";

interface ILRRecommendationProps {
  icon: string;
  emphasis: string;
  otherText: string;
  handleStartClick(): void;
}
const LRRecommendation = ({
  icon,
  emphasis,
  otherText,
  handleStartClick,
}: ILRRecommendationProps) => {
  return (
    <div>
      <div className="space-y-4">
        <div className="space-y-3">
          <img src={icon} alt="icon" className="w-7 h-7" />
          <p className="w-40 text-black-40 text-xs grotesk">
            <b className="text-black-80">{emphasis}</b> {otherText}
          </p>
        </div>
        <div>
          <button onClick={handleStartClick}>
            <div className="container-start space-x-2">
              <div className="text-purple font-medium text-base">Start</div>
              <i className="ri-arrow-right-s-line text-purple text-xl mt-[1px]" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LRRecommendation;
