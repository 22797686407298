import React, { MouseEventHandler } from "react";
interface ILRButton {
  text: string;
  isDisabled?: boolean;
  isBlack?: boolean;
  isGreen?: boolean;
  isRed?: boolean;
  isLoading?: boolean;
  isGray?: boolean;
  size?: "sm" | "bg";
  prefixIconName?: string;
  onClick: MouseEventHandler<Element>;
}
const LRButton = ({
  text,
  onClick,
  size,
  prefixIconName,
  isDisabled = false,
  isLoading = false,
  isBlack = false,
  isGray = false,
  isGreen = false,
  isRed = false,
}: ILRButton) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      disabled={isDisabled || isLoading}
      className={`w-full ${size === "sm" ? "py-2 px-4" : "p-3"} text-xs rounded-[4px] ${
        isGray
          ? "text-black-40 font-semibold bg-[#F2F2F2]"
          : isDisabled || isLoading
          ? "cursor-not-allowed bg-purple/70 text-white"
          : isBlack
          ? "bg-black text-white"
          : isGreen
          ? "bg-green-accent-2 text-white"
          : isRed
          ? "bg-red text-white"
          : "bg-purple text-white"
      } `}
    >
      <div className="flex items-center justify-center space-x-1">
        {prefixIconName ? <i className={`${prefixIconName} ri-white ri-lg`}></i> : null}

        <span>{text}</span>
        {isLoading ? (
          <span className="ml-2">
            <div className="animate-spin">
              <i className="ri-loader-4-line text-white" />
            </div>
          </span>
        ) : null}
      </div>
    </button>
  );
};

export default LRButton;
