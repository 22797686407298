import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const LRInputPhoneNumber = (props: {
  label: string;
  error?: string;
  phoneNumber: string;
  setPhoneNumber: (x: string) => void;
}) => {
  return (
    <div className={`space-y-1 flex flex-col items-start`}>
      <p className={`text-xs font-medium text-black-40`}>Phone Number</p>
      <PhoneInput
        country={"gb"}
        placeholder="*** *** ****"
        value={props.phoneNumber}
        autoFormat={false}
        enableLongNumbers={17}
        enableSearch={true}
        onChange={props.setPhoneNumber}
      />
      {!!props.error?.trim().length && (
        <p className="text-xs text-left text-red mt-1">{props.error}</p>
      )}
    </div>
  );
};

export default LRInputPhoneNumber;
