import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  fetchSettingsAPIKeys,
  fetchSettingsMerchantCurrency,
  fetchSettingsWebhookInfo,
  removeSettings2FA,
  sendSettingsComplete2FA,
  sendSettingsEnableCurrency,
  sendSettingsResetAPIKeys,
  sendSettingsSetup2FA,
  sendSettingsWebhookInfo,
  updateSettingsPasswordChange,
} from "./thunk";

const initialState = {
  twoFA: { qr: "", two_factor_authenticator_key: "", url: "" },
  webhook: {
    webhook_url: "",
    webhook_key: "",
  },
  apiKeys: {
    public_key: "",
    secret_key: "",
  },
  preferences: {
    fiat_list: {},
    coin_list: {},
  },
};
export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase<any>(sendSettingsSetup2FA.fulfilled, (state, { payload }) => {
      state.twoFA = payload?.data;
    });
    builder.addCase<any>(sendSettingsComplete2FA.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(fetchSettingsAPIKeys.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(fetchSettingsAPIKeys.fulfilled, (state, { payload }) => {
      state.apiKeys = payload?.data;
    });
    builder.addCase<any>(fetchSettingsWebhookInfo.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(fetchSettingsWebhookInfo.fulfilled, (state, { payload }) => {
      state.webhook = payload?.data;
    });
    builder.addCase<any>(sendSettingsWebhookInfo.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(sendSettingsWebhookInfo.fulfilled, (state, { payload }) => {
      toast.success("Webhook update successful");
    });
    builder.addCase<any>(fetchSettingsMerchantCurrency.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(fetchSettingsMerchantCurrency.fulfilled, (state, { payload }) => {
      state.preferences = payload.data;
    });
    builder.addCase<any>(sendSettingsResetAPIKeys.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(sendSettingsResetAPIKeys.fulfilled, (state, { payload }) => {
      toast.success("New API keys generated");
    });
    builder.addCase<any>(removeSettings2FA.fulfilled, (state, { payload }) => {
      toast.success(payload?.message);
    });
    builder.addCase<any>(updateSettingsPasswordChange.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(sendSettingsEnableCurrency.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(sendSettingsEnableCurrency.fulfilled, (state, { payload }) => {
      toast.success(payload?.msg as string);
    });
  },
});

export const {} = settingsSlice.actions;

export default settingsSlice.reducer;
