import { useAppDispatch } from "app/hooks";
import LRModal from "components/LRModal";
import LRSuccessPopup from "components/LRSuccessPopup";
import useMobile from "hooks/useMobile";
import React, { useEffect, useState } from "react";
import InitiateBalanceDeposit from "./InitiateBalanceDeposit";

enum SCREENS {
  INITIATE_DEPOSIT,
}

interface IBalanceDepositFormProps {
  isVisible: boolean;
  currency: string;
  onClose: (v: boolean) => void;
}

const BalanceDepositForm = ({ isVisible, onClose, currency }: IBalanceDepositFormProps) => {
  const dispatch = useAppDispatch();
  const [isMobile] = useMobile();
  const [currentScreen, setCurrentScreen] = useState(SCREENS.INITIATE_DEPOSIT);
  const [currencyToDeposit, setCurrencyToDeposit] = useState("");
  const [shouldShowStatusModal, setShouldShowStatusModal] = useState(false);
  const handleSetAmounts = (currency: any) => {
    onClose(false);
    setShouldShowStatusModal(true);
  };
  useEffect(() => {
    setCurrencyToDeposit(currency?.toUpperCase());
  }, [currency]);

  const screenToDisplay = () => {
    switch (currentScreen) {
      case SCREENS.INITIATE_DEPOSIT:
        return <InitiateBalanceDeposit currency={currency} onSubmit={handleSetAmounts} />;
    }
  };

  return (
    <>
      {isVisible ? (
        <LRModal
          height={isMobile ? 90 : undefined}
          isSmaller
          noPadding
          isVisible={isVisible}
          onCloseModal={onClose}
        >
          <div className="pt-6 pb-10 min-h-[390px]">
            <div className="container-between pb-4 px-7">
              <div>
                {currentScreen !== SCREENS.INITIATE_DEPOSIT ? (
                  <button
                    onClick={() => {
                      setCurrentScreen(SCREENS.INITIATE_DEPOSIT);
                    }}
                    className="container-start space-x-1 border-none outline-none"
                  >
                    <i className="ri-arrow-left-s-line ri-lg"></i>
                    <span className="text-sm font-medium">Sell coins</span>
                  </button>
                ) : null}
              </div>

              <button
                onClick={() => {
                  onClose(false);
                }}
                className="border-0 outline-none bg-transparent hidden sm:inline-block"
              >
                <i className="ri-close-fill ri-lg text-black" />
              </button>
            </div>
            <div className="space-y-6">{screenToDisplay()}</div>
          </div>
        </LRModal>
      ) : null}

      <LRSuccessPopup
        isVisible={shouldShowStatusModal}
        message={
          <>
            Your localramp wallet will be updated as soon as we receive your{" "}
            {currencyToDeposit?.toUpperCase()}{" "}
          </>
        }
        onClose={setShouldShowStatusModal}
      />
    </>
  );
};

export default BalanceDepositForm;
