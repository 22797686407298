import axios, { AxiosHeaders, AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_URL } from "./endpoints";
import { IAPI, IHTTPMethodsParams } from "./types";
import snakify from "snakecase-keys";
import { clearAllStorage, getFromStorage } from "utility/storage";
import { toast } from "react-toastify";
import { CLIENT_STORAGE_TOKEN } from "constants/storage";
import { store } from "app/store";
import { setAuthHasSession } from "app/slices/auth";

export const apiInstance = axios.create({
  baseURL: `${BASE_URL}/v1/`,
  headers: { "x-auth-token": window.localStorage.getItem("app.PublicKey") },
});

export const processCall =
  (apiCall: (payload?: any) => Promise<any>) => async (payload: any, thunkAPI: any) => {
    try {
      const response = await apiCall(snakify(payload));
      if (response?.data.hasOwnProperty("error_id") || response.status >= 400) {
        return thunkAPI.rejectWithValue(
          response?.data || { msg: "Something went wrong from our end" }
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  };

apiInstance.interceptors.request.use((config): AxiosRequestConfig<any> => {
  if (config.headers) {
    (config.headers as AxiosHeaders).set(
      "Authorization",
      `Bearer ${getFromStorage(CLIENT_STORAGE_TOKEN)}`
    );
  }
  return config;
});

apiInstance.interceptors.response.use(
  (config): AxiosResponse<any> => {
    return config;
  },
  ({ response }) => {
    if (response?.data?.error_id === "InvalidBearerToken") {
      // try {
      //   store.dispatch(setAuthHasSession(false));
      // } catch (error) {
      //   console.log(error);
      // }
      clearAllStorage();
      toast.error("Session Expired");
      window.location.reload();
    }
    return response;
  }
);

export const api: IAPI = {
  get: (endpoint: string, { success, failure, final }: IHTTPMethodsParams): Promise<any> =>
    apiInstance
      .get(endpoint)
      .then(function (response) {
        if (success) {
          return success(response);
        }
        return response;
      })
      .catch(function (error) {
        if (failure) {
          failure(error);
        } else {
          throw error;
        }
      })
      .finally(function () {
        final && final();
      }),
  delete: (endpoint: string, body, { success, failure, final }: IHTTPMethodsParams): Promise<any> =>
    apiInstance
      .delete(endpoint, { data: body })
      .then(function (response) {
        if (success) {
          return success(response);
        }
        return response;
      })
      .catch(function (error) {
        if (failure) {
          failure(error);
        } else {
          throw error;
        }
      })
      .finally(function () {
        final && final();
      }),
  put: (
    endpoint: string,
    body: any,
    { success, failure, final }: IHTTPMethodsParams
  ): Promise<any> =>
    apiInstance
      .put(endpoint, body)
      .then(function (response) {
        if (success) {
          return success(response);
        }
        return response;
      })
      .catch(function (error) {
        if (failure) {
          failure(error);
        } else {
          throw error.response.data;
        }
      })
      .finally(function () {
        final && final();
      }),
  post: (
    endpoint: string,
    body: any,
    { success, failure, final }: IHTTPMethodsParams
  ): Promise<any> =>
    apiInstance
      .post(endpoint, body)
      .then(function (response) {
        if (success) {
          return success(response);
        }
        return response;
      })
      .catch(function (error) {
        if (failure) {
          failure(error);
        } else {
          throw error.response.data;
        }
      })
      .finally(function () {
        final && final();
      }),
};
