import React from "react";

const TeamMemberStatus = ({ state }: { state: string }) => {
  return state === "invite" ? (
    <p className="text-xs text-black-20 grotesk">Invite Sent</p>
  ) : (
    <div className="container-start space-x-2">
      <div
        className="w-1 h-1 rounded-full"
        style={{ backgroundColor: state === "active" ? "#44A55E" : "#DD8808" }}
      ></div>
      <p className="text-xs text-black-80 capitalize grotesk">{state}</p>
    </div>
  );
};

export default TeamMemberStatus;
