import { useAppDispatch, useAppSelector } from "app/hooks";
import { unloadBuyTransactionDetails } from "app/slices/transaction";
import { fetchBuyTransactionDetials } from "app/slices/transaction/thunk";
import LRDetailItem from "components/LRDetailItem";
import LRModal from "components/LRModal";
import LRStatusTag from "components/LRStatusTag";
import useMobile from "hooks/useMobile";
import React, { useEffect, useState } from "react";
import { commalize, friendlyDateFormatter, maskWalletAddress } from "utility";

interface IBuyTransactionModalProps {
  isVisible: boolean;
  transactionRef: string;
  onClose: (v: boolean) => void;
}
const BuyTransactionModal = ({ transactionRef, isVisible, onClose }: IBuyTransactionModalProps) => {
  const dispatch = useAppDispatch();
  const [isMobile] = useMobile();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const transactionDetail = useAppSelector(({ transaction }) => transaction.buy.single);
  useEffect(() => {
    if (transactionDetail?.reference.length) {
      setIsLoadingData(false);
    }
  }, [transactionDetail]);

  useEffect(() => {
    if (transactionRef.length) {
      dispatch(fetchBuyTransactionDetials({ reference: transactionRef }));
    }
    return () => {
      dispatch(unloadBuyTransactionDetails());
      setIsLoadingData(true);
    };
  }, [transactionRef]);

  return (
    <LRModal
      hasMobileClose={false}
      height={isMobile ? 87 : undefined}
      isVisible={isVisible}
      onCloseModal={onClose}
    >
      <>
        <div className="container-end">
          <button
            onClick={() => {
              onClose(false);
            }}
            className="border-0 outline-none bg-transparent hidden sm:inline-block"
          >
            <i className="ri-close-fill ri-lg text-black" />
          </button>
        </div>
        <div className="space-y-6 md:space-y-6">
          <div className="container-start space-x-2 pt-5 md:pt-0">
            <h3>Transaction Details</h3>
            {isLoadingData ? null : <LRStatusTag text={transactionDetail?.state} />}
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-10 md:gap-y-0">
            <ul className="bordered-list">
              <LRDetailItem
                isLoading={isLoadingData}
                title="Amount Paid"
                value={
                  transactionDetail?.sent_amount
                    ? `${transactionDetail?.sender_currency} ${commalize(
                        Number(transactionDetail?.sent_amount || 0)
                      )}`
                    : "..."
                }
              />
              <LRDetailItem
                isLoading={isLoadingData}
                copy={transactionDetail?.txid}
                title="Transaction ID"
                value={`${maskWalletAddress(transactionDetail?.txid)}`}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                copy={transactionDetail?.reference}
                title="Reference"
                value={`${transactionDetail?.reference}`}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Description"
                value={
                  transactionDetail?.received_amount
                    ? `Bought  ${transactionDetail?.received_amount} ${transactionDetail?.receiver_currency}`
                    : "..."
                }
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Created At"
                value={friendlyDateFormatter(transactionDetail?.created_at)}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Updated At"
                value={friendlyDateFormatter(transactionDetail?.updated_at)}
              />
            </ul>
            <div className="space-y-5">
              <p className="text-base font-medium">Payer's Information</p>
              <ul className="space-y-4">
                <LRDetailItem
                  isLoading={isLoadingData}
                  title="Email Address"
                  value={transactionDetail?.email}
                />
                <LRDetailItem
                  isLoading={isLoadingData}
                  title="Currency"
                  value={transactionDetail?.sender_currency}
                />
                <LRDetailItem
                  isLoading={isLoadingData}
                  title="Payment Method"
                  value={transactionDetail?.payment_method}
                />
                <LRDetailItem
                  isLoading={isLoadingData}
                  copy={transactionDetail?.destination_address}
                  title="Wallet Address"
                  value={maskWalletAddress(transactionDetail?.destination_address)}
                />
                <LRDetailItem
                  isLoading={isLoadingData}
                  title="Destination Tag"
                  value={transactionDetail?.destination_tag}
                />
              </ul>
            </div>
          </div>
        </div>
      </>
    </LRModal>
  );
};

export default BuyTransactionModal;
