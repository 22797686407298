import { yupResolver } from "@hookform/resolvers/yup";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { requestPasswordResetSchema } from "utility/validators";
import useResetPassword from "./useResetPassword";

interface IFormValues {
  email: string;
}
const ResetMerchant = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(requestPasswordResetSchema),
  });
  const { isSubmittingForm, onSubmit } = useResetPassword();
  return (
    <form className="space-y-6">
      <LCInput
        label="Email address"
        placeholder="joesmith@xyz.com"
        type="email"
        inputRegister={register("email")}
      />
      <div className="space-y-6 text-center">
        <LRButton
          text="Reset Password"
          isLoading={isSubmittingForm}
          onClick={handleSubmit(onSubmit)}
        />
        <div className="space-y-4 grotesk">
          <p className="font-light text-black-80 text-xs">
            I remember my password{" "}
            <Link to={"/auth/login/merchant"} className="text-purple-dark font-medium">
              <span>Login</span>
            </Link>
          </p>
          <p className="font-light text-xs">
            Do you have an account?{" "}
            <Link to={"/auth/signup"} className="text-purple-dark font-medium">
              <span>Sign Up</span>
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default ResetMerchant;
