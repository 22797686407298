import { toast, ToastOptions } from "react-toastify";

const toastConfig: ToastOptions = {
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const toastMessage = {
  success: (text: string) =>
    toast.success(`${text}`, {
      ...toastConfig,
    }),
  error: (text: string) =>
    toast.error(`${text}`, {
      ...toastConfig,
    }),
  pending: (text: string) =>
    toast.info(`${text}`, {
      ...toastConfig,
    }),
  flow: (req: any, { pendingText, successText }: { pendingText: string; successText: string }) =>
    toast.promise(req, {
      pending: {
        render() {
          return `<div className="ml-4">{pendingText}</div>`;
        },
        ...toastConfig,
      },
      success: {
        render() {
          return `<div className="flex items-center justify-center space-x-4 text-xs">
              <p>{successText}</p>
            </div>`;
        },
        ...toastConfig,
        icon: false,
      },
      error: {
        render() {
          return `😕 Something went wrong, please refresh`;
        },
        ...toastConfig,
        icon: false,
      },
    }),
  closeAll: () => toast.dismiss(),
};

// export const truncateText = (t: string, length: number) =>
//   _.truncate(`${t}`, { length, omission: "..." });
