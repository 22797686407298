import { isVisible } from "@testing-library/user-event/dist/utils";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { unloadBuyTransactions } from "app/slices/transaction";
import { getBuyTransactions } from "app/slices/transaction/api";
import { fetchBuyPaymentMethods, fetchBuySupportedCurrencies, fetchBuyTranasactions } from "app/slices/transaction/thunk";
import LRDateFormatter from "components/LRDateFormatter";
import LRStatusTag from "components/LRStatusTag";
import LRTable, { ILRTableColumnProps } from "components/LRTable";
import LRTabs from "components/LRTabs";
import AuthenticatedPagesOnly from "containers/AuthenticatedPagesOnly";
import React, { useEffect, useState } from "react";
import { before_, commalize } from "utility";
import { checkIfAuthenticated } from "utility/auth";
import BuyTransactionModal from "../components/BuyTransactionModal";
import { DataType } from "../types";

const tabs = [
	{ title: "Completed", key: "completed" },
	{ title: "Pending", key: "pending" },
	{ title: "Waiting for payment", key: "awaiting_payment" },
];
const BuyTransactions = () => {
	const dispatch = useAppDispatch();
	const [currentTab, setCurrentTab] = useState("completed");
	const [tableFilter, setTableFilter] = useState({});

	const buyTransaction = useAppSelector(({ transaction }) => transaction.buy);

	const { paymentMethods, supportedCurrencies } = buyTransaction;

	const [selectedTransactionRef, setSelectedTransactionRef] = useState("");
	const [shouldShowTransactionModal, setShouldShowTransactionModal] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(true);
	const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(true);
	const [allData, setAllData] = useState<{ [x: string]: { data: any[]; pagination: any } }>({});

	const initiateFetchData = (params: any) => {
		dispatch(fetchBuyTranasactions({ state: params?.state || currentTab, ...params }))
			.then(({ payload }) => {
				if (payload?.data) setAllData({ ...allData, [params?.state || currentTab]: payload?.data });
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};
	const initiateFetchSupportedCurrencies = () => {
		dispatch(fetchBuySupportedCurrencies({})).finally(() => {
			setIsLoadingCurrencies(false);
		});
	};
	const initiateFetchPaymentMethods = () => {
		dispatch(fetchBuyPaymentMethods({})).finally(() => {
			setIsLoadingPaymentMethods(false);
		});
	};
	useEffect(() => {
		dispatch(unloadBuyTransactions());
		if (!checkIfAuthenticated()) return;
		initiateFetchData({ state: "completed" });
		initiateFetchSupportedCurrencies();
		initiateFetchPaymentMethods();
	}, []);

	const columns: ILRTableColumnProps<DataType>[] = [
		{
			key: "state",
			title: "Status",
			render: (state, {}) => <LRStatusTag text={currentTab} />,
		},
		{
			key: "sent_amount",
			title: "Amount",
			render: (amount, { sender_currency }) =>
				amount ? (
					<p className="text-xs font-bold text-black-20 grotesk">
						{sender_currency} <span className="text-black-80">{commalize(amount)}</span>
					</p>
				) : (
					<p className="text-xs italic text-black-20 grotesk">...</p>
				),
		},
		{
			key: "received_amount",
			title: "Description",
			width: 30,
			render: (amount, { receiver_currency }) => (
				<p className="text-xs font-medium text-black-40 grotesk">
					Bought {amount ? <span className="font-bold text-black-80">{amount}</span> : <span className="italic">...</span>}{" "}
					{receiver_currency}
				</p>
			),
		},
		{
			key: "created_at",
			title: "Date Created",
			render: (date) => <LRDateFormatter date={`${date}`} />,
		},
		{
			key: "updated_at",
			title: "Last Activity",
			render: (date) => <LRDateFormatter date={`${date}`} />,
		},
	];

	const handleRowClick = (row: DataType) => {
		if (!row?.reference) return;
		setSelectedTransactionRef(row.reference);
		setShouldShowTransactionModal(true);
	};
	const handleTableChange = (paramValue: any) => {
		setIsDataLoading(true);
		const value = { ...paramValue };
		delete value.count;

		if (!value.query) {
			delete value.query;
		}
		if (!value.page || value.query) {
			setTableFilter({ ...value });
			initiateFetchData({ ...value, page: 1 });
		} else {
			initiateFetchData({ ...tableFilter, ...value });
		}
	};
	const handleTabChange = (key: string) => {
		if (key === currentTab) {
			return;
		}
		dispatch(unloadBuyTransactions());
		setIsDataLoading(true);
		setCurrentTab(key);
		initiateFetchData({ state: key });
	};

	return (
		<AuthenticatedPagesOnly pageName="Buy transactions">
			<>
				<div className="px-6 pt-6 space-y-6 md:px-14">
					<LRTabs hasLine tabs={tabs} onChange={handleTabChange} activeTab={currentTab} />
					<LRTable
						key={currentTab}
						columns={columns}
						isLoading={isDataLoading}
						onRowClick={handleRowClick}
						data={allData[currentTab]?.data || []}
						pagination={allData[currentTab]?.pagination || {}}
						dataState={currentTab}
						fetchDataFunc={getBuyTransactions}
						filterFields={[
							{
								type: "coinType",
								key: "senderCurrency",
								keyForOption: "fiat",
								label: "Sender currency",
								placeholder: "Select currency",
								isSearchable: true,
								searchPlaceholder: "Search currency",
								isLoading: isLoadingCurrencies,
								options: supportedCurrencies?.sender_currency,
							},
							{
								type: "coinType",
								key: "receiverCurrency",
								label: "Receiver currency",
								searchPlaceholder: "Search coin",
								placeholder: "Select coin",
								isSearchable: true,
								isLoading: isLoadingCurrencies,
								options: supportedCurrencies?.receiver_currency,
							},
							{ type: "startDate" },
							{ type: "endDate" },
							{
								type: "paymentMethod",
								isLoading: isLoadingPaymentMethods,
								options: paymentMethods,
							},
							{ type: "query" },
						]}
						onChange={handleTableChange}
					/>
				</div>
				{shouldShowTransactionModal ? (
					<BuyTransactionModal
						transactionRef={selectedTransactionRef}
						isVisible={shouldShowTransactionModal}
						onClose={setShouldShowTransactionModal}
					/>
				) : null}
			</>
		</AuthenticatedPagesOnly>
	);
};

export default BuyTransactions;
