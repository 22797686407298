import { Router } from "@remix-run/router";
import DashboardLayout from "containers/DashboardLayout";
import LoginMerchant from "modules/auth/pages/Login/LoginMerchant";
import LoginTeam from "modules/auth/pages/Login/LoginTeam";
import Reset from "modules/auth/pages/Reset/MerchantOrTeamReset";
import CreateNewPassword from "modules/auth/pages/Reset/CreateNewPassword";
import ResetPasswordSent from "modules/auth/pages/Reset/ResetPasswordSent";
import Signup from "modules/auth/pages/Signup";
import EmailVerification from "modules/auth/pages/Signup/EmailVerification";
import JoinTeam from "modules/auth/pages/Signup/JoinTeam";
import Dashboard from "modules/dashboard";
import APISettings from "modules/settings/containers/APISettings";
import Preferences from "modules/settings/containers/Preferences";
import Profile from "modules/settings/containers/Profile";
import Security from "modules/settings/containers/Security";
import Settings from "modules/settings/pages/Settings";
import Team from "modules/team/pages/Team";
import Balances from "modules/transactions/pages/Balances";
import BalanceHistory from "modules/transactions/pages/Balances/BalanceHistory";
import BuyTransactions from "modules/transactions/pages/BuyTransactions";
import SellTransactions from "modules/transactions/pages/SellTransactions";
import SwapTransactions from "modules/transactions/pages/SwapTransactions";
import { createBrowserRouter, Navigate, redirect } from "react-router-dom";
import { checkIfAuthenticated } from "utility/auth";
import { getMerchantFromStorage } from "utility/storage";
import AuthLayout from "./modules/auth/AuthLayout";
import Login from "./modules/auth/pages/Login";
import ResetMerchant from "modules/auth/pages/Reset/MerchantOrTeamReset/ResetMerchant";
import ResetTeam from "modules/auth/pages/Reset/MerchantOrTeamReset/ResetTeam";
import ManageSupportedCoins from "modules/settings/containers/Preferences/ManageSupportedCoins";
import PreferenceList from "modules/settings/containers/Preferences/PreferenceList";
import ManageSupportedFiat from "modules/settings/containers/Preferences/ManageSupportedFiat";
import { AuthErrorPage } from "modules/error/AuthErrorPage";
import DashboardErrorPage from "modules/error/DashboardErrorPage";

const isMerchantVerifiedAndActivated = () =>
  getMerchantFromStorage()?.is_verified && !getMerchantFromStorage()?.is_deactivated;

const redirectToLogin = async (p: any) => {
  throw redirect("/auth/login");
};
const redirectToSignup = async (p: any) => {
  throw redirect("/auth/login");
};
const handleUserOnly = async () => {
  throw redirect("/auth/login");
};
const handleGuestOnly = async () => {
  throw redirect("/");
};

const redirectIfUserNotVerified = (config: any) => {
  if (checkIfAuthenticated()) {
    if (!isMerchantVerifiedAndActivated()) {
      throw redirect("/dashboard");
    }
  }
  return null;
};

const APP_ROUTES: Router = createBrowserRouter([
  {
    path: "/login",
    element: <div></div>,
    loader: redirectToLogin,
  },
  {
    path: "/signup",
    element: <div></div>,
    loader: redirectToSignup,
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="login" replace />,
      },
      {
        path: "login",
        element: <Login />,
        errorElement: <AuthErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="merchant" replace />,
          },
          {
            path: "merchant",
            element: <LoginMerchant />,
          },
          {
            path: "team",
            element: <LoginTeam />,
          },
        ],
      },
      {
        path: "signup",
        element: <Signup />,
        errorElement: <AuthErrorPage />,
      },
      {
        path: "team-invite",
        element: <JoinTeam />,
        errorElement: <AuthErrorPage />,
      },
      {
        path: "reset",
        element: <Reset />,
        errorElement: <AuthErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="merchant" replace />,
          },
          {
            path: "merchant",
            element: <ResetMerchant />,
          },
          {
            path: "team",
            element: <ResetTeam />,
          },
        ],
      },
      {
        path: "validate-email",
        element: <EmailVerification />,
        errorElement: <AuthErrorPage />,
      },
      {
        path: "reset-password-sent",
        element: <ResetPasswordSent />,
        errorElement: <AuthErrorPage />,
      },
      {
        path: "new-password",
        element: <CreateNewPassword />,
        errorElement: <AuthErrorPage />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
        errorElement: <DashboardErrorPage />,
      },
      {
        path: "buy-transactions",
        element: <BuyTransactions />,
        loader: redirectIfUserNotVerified,
        errorElement: <DashboardErrorPage />,
      },
      {
        path: "sell-transactions",
        element: <SellTransactions />,
        loader: redirectIfUserNotVerified,
        errorElement: <DashboardErrorPage />,
      },
      {
        path: "swap-transactions",
        element: <SwapTransactions />,
        loader: redirectIfUserNotVerified,
        errorElement: <DashboardErrorPage />,
      },
      {
        path: "balances",
        element: <Balances />,
        loader: redirectIfUserNotVerified,
        errorElement: <DashboardErrorPage />,
      },
      {
        path: "balances/:coin",
        element: <BalanceHistory />,
        loader: redirectIfUserNotVerified,
        errorElement: <DashboardErrorPage />,
      },
      {
        path: "settings",
        element: <Settings />,
        loader: redirectIfUserNotVerified,
        errorElement: <DashboardErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="profile" replace />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "security",
            element: <Security />,
          },
          {
            path: "api",
            element: <APISettings />,
          },
          {
            path: "preferences",
            element: <Preferences />,
            children: [
              {
                index: true,
                element: <Navigate to="list" replace />,
              },
              {
                path: "list",
                element: <PreferenceList />,
              },
              {
                path: "supported-coins",
                element: <ManageSupportedCoins />,
              },
              {
                path: "supported-fiats",
                element: <ManageSupportedFiat />,
              },
            ],
          },
        ],
      },
      {
        path: "team",
        element: <Team />,
        loader: redirectIfUserNotVerified,
        errorElement: <DashboardErrorPage />,
      },
    ],
  },
  {
    path: "*",
    element: <div></div>,
    loader: handleUserOnly,
    errorElement: <div>Error</div>,
  },
]);

export default APP_ROUTES;
