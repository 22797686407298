import LRModal from "components/LRModal";
import React from "react";
import loaderSVG from "assets/images/loader.svg";

interface IPaymentLoadingProps {
  isVisible: boolean;
  onClose(x: boolean): void;
}
const PaymentLoading = ({ isVisible, onClose }: IPaymentLoadingProps) => {
  return (
    <LRModal
      isSmaller
      isVisible={isVisible}
      onCloseModal={onClose}
      noPadding
      extraClassName="max-w-[320px] h-[320px]"
    >
      <div className="space-y-4 w-full max-x-[180px] mx-auto h-full container-center flex-col py-28">
        <div className="w-16 h-16 mx-auto animate-spin">
          <img src={loaderSVG} alt="" className="w-16 h-16" />
        </div>
        <p className="text-sm text-black">Processing your transaction</p>
      </div>
    </LRModal>
  );
};

export default PaymentLoading;
