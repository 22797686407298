import LRInput from "components/LRInput";
import { useClickOutside } from "hooks/useClickOutside";
import React, { useEffect, useState } from "react";
import { afterHyphen, beforeHyphen, before_, findCoinLogo, getLogoFor } from "utility";

interface ILRTableFilterFormSelectProps {
	label: string;
	keyForOption?: string;
	options: any[];
	hasSearch?: boolean;
	hasLogo?: boolean;
	placeholder: string;
	selectedValueText?: any;
	isLoading?: boolean;
	hasNetwork?: boolean;
	handleOptionSelect: (option: any) => void;
	searchPlaceholder?: string;
}
const LRTableFilterFormSelect = ({
	label,
	keyForOption,
	options,
	isLoading,
	hasSearch,
	placeholder,
	selectedValueText,
	hasLogo = true,
	handleOptionSelect,
	hasNetwork = true,
	searchPlaceholder,
}: ILRTableFilterFormSelectProps) => {
	const [shouldShowOptions, setShouldShowOptions] = useState(false);
	const { popedComponentRef } = useClickOutside(setShouldShowOptions);
	const [query, setQuery] = useState("");
	const [optionsToDisplay, setOptionsToDisplay] = useState(options || []);

	useEffect(() => {
		setOptionsToDisplay(options || []);
	}, [options]);

	const handleOptionsShow = () => {
		setShouldShowOptions(!shouldShowOptions);
	};
	const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setQuery(value);
		const queryToLowercase = value.toLowerCase();

		const rankedIndex = options
			.filter(
				(option) =>
					option?.coin?.toLowerCase().includes(queryToLowercase) ||
					option?.name?.toLowerCase().includes(queryToLowercase) ||
					option?.country_code?.toLowerCase().includes(queryToLowercase) ||
					option?.fiat?.toLowerCase().includes(queryToLowercase)
			)
			.map((option) => {
				let points = 0;
				if (before_(option?.coin).toLowerCase().includes(queryToLowercase)) {
					points += 2;
				}
				if (option?.name?.toLowerCase().includes(queryToLowercase)) {
					points += 1;
				}

				return { ...option, points };
			})
			.sort((a, b) => b.points - a.points);

		setOptionsToDisplay(rankedIndex);
	};
	return (
		<div ref={popedComponentRef} className="relative min-w-[200px]">
			<div role={"button"} onClick={handleOptionsShow} className="flex flex-col space-y-2">
				<p className="font-medium text-xxs text-black-40">{label}</p>
				<div className="border-2 border-gray-border rounded-[4px] px-3 py-1 container-between bg-white">
					<p className={`text-xs font-light grotesk ${!selectedValueText && "text-black-40"}`}>{selectedValueText || placeholder}</p>
					<i className={`${shouldShowOptions ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"} text-black-10`}></i>
				</div>
			</div>
			{shouldShowOptions ? (
				<div
					className="absolute mt-2 w-full bg-white border py-1 px-[1px] border-black-10 rounded-lg z-10 "
					style={{ boxShadow: "0px 0px 8px 0px #EAEAEA8C" }}
				>
					{isLoading ? (
						<div className="relative flex flex-col items-center justify-center rounded-lg" style={{ minHeight: "120px" }}>
							<div className="animate-spin ">
								<div className="w-4 h-4 mx-auto">
									<i className="ri-loader-5-line text-purple ri-lg" />
								</div>
							</div>
						</div>
					) : (
						<>
							{hasSearch ? (
								<div className="px-3 py-2 rounded-lg">
									<LRInput label="" placeholder={searchPlaceholder} value={query} onChange={handleQueryChange} />
								</div>
							) : null}
							<div className="max-h-[186px] overflow-y-auto bg-white rounded-lg">
								{optionsToDisplay.map((option, i) => (
									<button
										key={i}
										onClick={() => {
											handleOptionSelect(option);
											setShouldShowOptions(false);
										}}
										className="w-full px-3 py-2 bg-white border-0 hover:bg-gray-50"
									>
										<div className="space-x-3 container-start">
											{!hasLogo ? null : (
												<div>
													<div className="w-4 h-4 rounded-full">
														<img src={option?.fiat ? getLogoFor(option?.country_code) : findCoinLogo(option)} alt="" />
													</div>
												</div>
											)}
											{keyForOption?.length ? (
												<p className="text-xs font-medium text-left grotesk text-black-80">
													{afterHyphen(option[keyForOption]) && hasNetwork ? (
														<>
															{beforeHyphen(option[keyForOption])}
															{" - "}
															<span className="text-black-40">{afterHyphen(option[keyForOption])}</span>
														</>
													) : (
														`${option[keyForOption] || ""}`
													)}
												</p>
											) : (
												<p className="text-xs font-medium text-left grotesk text-black-80">
													{afterHyphen(option?.name) && hasNetwork ? (
														<>
															{beforeHyphen(option?.name)}
															{" - "}
															<span className="text-black-40">{afterHyphen(option?.name)}</span>
														</>
													) : (
														`${before_(option?.name)}`
													)}
												</p>
											)}
										</div>
									</button>
								))}
							</div>
						</>
					)}
				</div>
			) : null}
		</div>
	);
};

export default LRTableFilterFormSelect;
