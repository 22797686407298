import moment from "moment";
import React from "react";

const LRDateFormatter = ({ date }: { date: string }) => {
  const momentDate = moment(date);
  if (!date.trim().length || !momentDate.isValid()) {
    return null;
  }
  return (
    <p className="text-xs font-medium text-black-80 grotesk space-x-1">
      <span>{momentDate.format("DD MMM, YYYY")}</span>
      <span className="text-xxs text-black-20">{momentDate.format("hh:mm a")}</span>
    </p>
  );
};

export default LRDateFormatter;
