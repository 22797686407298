export const BASE_URL = process.env.REACT_APP_BACKEND_HOST;

export const ENDPOINTS = {
  auth: {
    PRE_REGISTER_MERCHANT: "merchant/send-otp",
    REGISTER_MERCHANT: "merchant/register",
    LOGIN: "merchant/login",
    REQUEST_PASSWORD_RESET: "merchant/request-password-reset",
    RESET_PASSWORD: "merchant/reset-password",
    REGEISTER_TEAM_MEMBER: "dashboard/team/invite/new",
  },
  dashboard: {
    STATS: "dashboard/stats",
  },
  transaction: {
    // BUY
    BUY_TRANSACTIONS: "dashboard/buy/transactions",
    BUY_TRANSACTION_DETAILS: "dashboard/buy/transaction",
    BUY_SUPPORTED_CURRENCY: "dashboard/buy/currencies",
    BUY_PAYMENT_METHODS: "dashboard/buy/payment-methods",
    // SELL
    SELL_TRANSACTIONS: "dashboard/sell/transactions",
    SELL_TRANSACTION_DETAILS: "dashboard/sell/transaction",
    sell: {
      SET_AMOUNTS: "dashboard/sell/amounts",
      SET_DESTINATION: "dashboard/sell/destination",
      PROCESS_TRANSACTION: "dashboard/sell/process",
      VERIFY_BANK_ACCOUNT: "dashboard/sell/verify-bank",
      RATE: "dashboard/sell/rate",
      SUPPORTED_BANKS: "dashboard/sell/supported-banks",
      SUPPORTED_MOMO: "dashboard/sell/supported-momo",
      TRANSACTION_STATUS: "dashboard/sell/status",
      LIMITS: "dashboard/sell/limits",
      SUPPORTED_CURRENCIES: "dashboard/sell/currencies",
    },
    // SWAP
    SWAP_TRANSACTIONS: "dashboard/swap/transactions",
    SWAP_TRANSACTION_DETAILS: "dashboard/swap/transaction",
    swap: {
      AVAILABLE_CURRENCIES: "dashboard/swap/currencies",
      GET_QUOTES: "dashboard/swap/rate",
      LIMITS: "dashboard/swap/limits",
      PROCESS_CONVERT_PAIRS: "dashboard/swap/initiate",
      VERIFY_TRANSACTION: "dashboard/swap/status",
    },
    // BALANCES
    BALANCE_CRYPRO: "dashboard/balance",
  },
  settings: {
    API: {
      KEYS: "dashboard/setting/keys",
      WEBHOOK_INFO: "dashboard/setting/webhook",
    },
    PREFERENCES: {
      SET_BUY_FEE: "dashboard/setting/keys",
      GET_MERCHANT_CURRENCY: "dashboard/setting/currencies",
      ENABLE_CURRENCY: "dashboard/setting/currencies/enable",
      RESET_CURRENCY_LIST: "dashboard/setting/currencies/reset",
      PARTHNERSHIP_PROGRAM: "referral/code"
    },
    SECURITY: {
      TWO_FA: "dashboard/security/2fa",
      TWO_FA_VERIFY: "dashboard/security/2fa/verify",
      CHANGE_PASSWORD: "dashboard/security/change-password",
    },
  },
  teams: {
    TEAM: "dashboard/team",
    TEAM_INVITE: "dashboard/team/invite",
    TEAM_INVITEE: "dashboard/team/invite/get",
    ACCESS_TYPES: "dashboard/team/access-types",
  },
  util: { SUPPORTED_CURRENCIES: "widget/buy/currencies" },
};
