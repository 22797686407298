import { api } from "services/api";
import { ENDPOINTS } from "services/endpoints";

const { teams } = ENDPOINTS;

export const getTeamMembers = () => api.get(`${teams.TEAM}`, {});
export const getTeamAccessTypes = () => api.get(`${teams.ACCESS_TYPES}`, {});
export const postToGetInviteeTeam = (body: any) => api.post(`${teams.TEAM_INVITEE}`, body, {});
export const postInviteTeam = (body: any) => api.post(`${teams.TEAM_INVITE}`, { ...body }, {});
export const putInviteTeam = (payload: { reference: string; data: any }) =>
  api.put(`${teams.TEAM}/${payload.reference}`, payload.data, {});
export const deleteTeamMember = (payload: any) =>
  api.delete(`${teams.TEAM}/${payload.reference}`, {}, {});
export const deleteInviteTeamMember = (body: any) => {
  return api.delete(`${teams.TEAM_INVITE}`, body, {});
};
