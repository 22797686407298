import LRTransactionOverviewItem from "components/LRTransactionOverviewItem";
import React from "react";
import { commalize } from "utility";

interface ITransactionOverviewProps {
  todayReceivedAmount: number;
  monthReceivedAmount: number;
  lifetimeReceivedAmount: number;
  todayReceivedCount: number;
  monthReceivedCount: number;
  lifetimeReceivedCount: number;
}
const TransactionOverview = ({
  todayReceivedAmount,
  monthReceivedAmount,
  lifetimeReceivedAmount,
  todayReceivedCount,
  monthReceivedCount,
  lifetimeReceivedCount,
}: ITransactionOverviewProps) => {
  return (
    <div>
      <div className="border-b border-b-[#EAEAEA] mb-6">
        <p className="text-base font-medium text-black-80 pb-3">Transaction Overview</p>
        <div className="w-10 h-[2px] -mb-[2px] bg-purple"></div>
      </div>
      <div
        className="bg-white w-full max-w-4xl rounded-2xl px-12 py-14"
        style={{ boxShadow: "0px 0px 8px rgba(234, 234, 234, 0.55)" }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-8 lg:gap-y-10 items-center justify-between">
          <LRTransactionOverviewItem
            value={commalize(Number(todayReceivedAmount), 2, 2)}
            title={"RECEIVED TODAY (USD)"}
          />
          <LRTransactionOverviewItem
            value={commalize(Number(monthReceivedAmount), 2, 2)}
            title={"RECEIVED THIS MONTH (USD)"}
          />
          <LRTransactionOverviewItem
            value={commalize(Number(lifetimeReceivedAmount), 2, 2)}
            title={"RECEIVED LIFETIME (USD)"}
          />
          <LRTransactionOverviewItem value={todayReceivedCount || 0} title={"TRANSACTIONS TODAY"} />
          <LRTransactionOverviewItem
            value={monthReceivedCount || 0}
            title={"TRANSACTIONS THIS MONTH"}
          />
          <LRTransactionOverviewItem
            value={lifetimeReceivedCount || 0}
            title={"TRANSACTIONS LIFETIME"}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionOverview;
