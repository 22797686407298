import React from "react";

const LRTabsLight = (props: { isActive: boolean; onClick: any; title: string }) => {
  return (
    <button
      className={`pb-2 mt-2 ${props.isActive && "border-b-2 border-b-purple"}`}
      onClick={props.onClick}
    >
      <p
        className={`font-normal text-sm leading-[18px] ${
          props.isActive ? "text-purple" : "text-black-20"
        }`}
      >
        {props.title}
      </p>
    </button>
  );
};

export default LRTabsLight;
