import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchBalanceCrypto } from "app/slices/transaction/thunk";
import LRTable, { ILRTableColumnProps } from "components/LRTable";
import AuthenticatedPagesOnly from "containers/AuthenticatedPagesOnly";
import { BalancesDataType, DataType } from "modules/transactions/types";
import React, { useEffect, useState } from "react";
import { findCoinLogo } from "utility";
import viewHistoryIcon from "assets/icons/viewHistory.svg";
import { useNavigate } from "react-router";
import BalanceWithdrawalForm from "modules/transactions/components/BalanceWithdrawalForm";
import BalanceDepositForm from "modules/transactions/components/BalanceDepositForm";
import { isMerchantAdmin, isMerchantReadWrite } from "utility/storage";
import { checkIfAuthenticated } from "utility/auth";

const Balances = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const balanceTransaction = useAppSelector(({ transaction }) => transaction.balances);

  const { list } = balanceTransaction;

  const data = list?.balances || [];
  const pagination = list?.pagination || [];

  const [tableFilter, setTableFilter] = useState<any>();

  const [selectedTransactionRef, setSelectedTransactionRef] = useState("");
  const [shouldShowTransactionModal, setShouldShowTransactionModal] = useState(false);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [currencyToDeposit, setCurrencyToDeposit] = useState("");
  const [currencyToWithdraw, setCurrencyToWithdraw] = useState("");
  const [shouldShowDepositForm, setShouldShowDepositForm] = useState(false);
  const [shouldShowWithdrawalForm, setShouldShowWithdrawalForm] = useState(false);

  const initiateFetchData = (params = {}) => {
    dispatch(fetchBalanceCrypto(params)).finally(() => {
      setIsDataLoading(false);
    });
  };

  useEffect(() => {
    initiateFetchData();
    return () => {
      setIsDataLoading(true);
    };
  }, []);

  const handleViewHistory = (row: BalancesDataType) => {
    navigate(`/dashboard/balances/${row.currency}`, {
      state: row,
    });
  };

  const handleWithdrawalClick = (currency: string) => {
    setCurrencyToWithdraw(currency);
    setShouldShowWithdrawalForm(true);
  };
  const handleDepositClick = (currency: string) => {
    setShouldShowDepositForm(true);
    setCurrencyToDeposit(currency);
  };

  const [columns, setColumns] = useState<ILRTableColumnProps<BalancesDataType>[]>([
    {
      key: "currency",
      title: "Coin",
      render: (coin, {}) => (
        <div className="container-start space-x-2">
          <div>
            <img src={findCoinLogo({ coin: coin.toUpperCase() })} alt="" className="w-4 h-4" />
          </div>
          <p className="text-xs font-bold grotesk uppercase">{coin}</p>
        </div>
      ),
    },
    {
      key: "balance",
      title: "Available Balance",
      render: (amount, {}) => <p className="text-xs">{amount}</p>,
    },
    {
      key: "pending_balance",
      title: "Pending Balance",
      render: (amount, {}) => <p className="text-xs">{amount}</p>,
    },
    {
      key: "deposit",
      title: "Deposit",
      render: (deposit, { currency }) => (
        <button
          onClick={() => handleDepositClick(currency)}
          className="bg-purple/5 px-3 py-1 rounded-sm hover:bg-purple/10 transform duration-100 transition"
        >
          <div className="container-start space-x-1  text-purple">
            <p className="text-xs grotesk font-semibold">Deposit</p>
            <i className="ri-arrow-left-down-line" />
          </div>
        </button>
      ),
    },
    {
      key: "transactionHistory",
      title: "Transaction History",
      render: (x, row) => (
        <button
          onClick={() => {
            handleViewHistory(row);
          }}
          className="container-end space-x-2 ml-auto disabled:opacity-30"

        >
          <p className="text-xs grotesk font-bold text-black-80">View History</p>
          <div>
            <img src={viewHistoryIcon} alt="" className="w-4 h-4" />
          </div>
        </button>
      ),
    },
  ]);

  useEffect(() => {
    if (columns.length === 6 || !checkIfAuthenticated()) return;
    if (isMerchantAdmin() || isMerchantReadWrite()) {
      const tempColumns = [...columns];
      tempColumns.splice(4, 0, {
        key: "withdraw",
        title: "Withdraw",
        render: (withdraw, { currency }) => (
          <button
            onClick={() => handleWithdrawalClick(currency)}
            disabled={!(isMerchantAdmin() || isMerchantReadWrite())}
            className="bg-orange/5 px-3 py-1 rounded-sm hover:bg-orange/10 transform duration-100 transition"
          >
            <div className="container-start space-x-1  text-orange">
              <p className="text-xs grotesk font-semibold">Withdraw</p>
              <i className="ri-arrow-right-up-line" />
            </div>
          </button>
        ),
      });
      setColumns(tempColumns);
    }
  }, []);

  const handleRowClick = (row: DataType) => {
    setSelectedTransactionRef(row.reference);
    setShouldShowTransactionModal(true);
  };
  const handleTableChange = (paramValue: any) => {
    const value = { ...paramValue };
    delete value.count;
    setIsDataLoading(true);
    if (!value.page || value.query) {
      setTableFilter({ ...value });
      initiateFetchData({ ...value, page: 1 });
    } else {
      initiateFetchData({ ...value });
    }
  };
  return (
    <AuthenticatedPagesOnly pageName="Balances">
      <>
        <div className="px-6 md:px-14 pt-6 space-y-6">
          <LRTable
            hasNoFilters
            key={"balance-table"}
            isLoading={isDataLoading}
            columns={columns}
            data={data}
            onRowClick={handleRowClick}
            pagination={pagination}
            filterFields={[]}
            onChange={handleTableChange}
          />
        </div>
        <BalanceWithdrawalForm
          currency={currencyToWithdraw}
          isVisible={shouldShowWithdrawalForm}
          onClose={setShouldShowWithdrawalForm}
        />
        <BalanceDepositForm
          currency={currencyToDeposit}
          isVisible={shouldShowDepositForm}
          onClose={setShouldShowDepositForm}
        />
      </>
    </AuthenticatedPagesOnly>
  );
};

export default Balances;
