import LRModal from "components/LRModal";
import LRSuccessPopup from "components/LRSuccessPopup";
import React, { useState } from "react";
import { maskWalletAddress } from "utility";
import InitiateBalanceWithdrawal from "./InitiateBalanceWithdrawal";

enum SCREENS {
  INITIATE_WITHDRAWAL,
}

interface IBalanceWithdrawalFormProps {
  isVisible: boolean;
  currency: string;
  onClose: (v: boolean) => void;
}

const BalanceWithdrawalForm = ({ isVisible, onClose, currency }: IBalanceWithdrawalFormProps) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [shouldShowWithdrawalState, setShouldShowWithdrawalState] = useState(false);
  const handleSetAmounts = (walletAddress: any) => {
    setWalletAddress(walletAddress);
    onClose(false);
    setShouldShowWithdrawalState(true);
  };

  return (
    <>
      {isVisible ? (
        <LRModal isSmaller noPadding isVisible={isVisible} onCloseModal={onClose}>
          <div className="pt-6 px-7 pb-10 min-h-[390px]">
            <div className="container-between pb-4">
              <div></div>
              <button
                onClick={() => {
                  onClose(false);
                }}
                className="border-0 outline-none bg-transparent hidden sm:inline-block"
              >
                <i className="ri-close-fill ri-lg text-black" />
              </button>
            </div>
            <div>
              <div className="space-y-6">
                <InitiateBalanceWithdrawal currency={currency} onSubmit={handleSetAmounts} />
              </div>
            </div>
          </div>
        </LRModal>
      ) : null}
      <LRSuccessPopup
        isVisible={shouldShowWithdrawalState}
        title="Withdrawal Successful"
        message={
          <>
            Your coins will be sent to the specified wallet address{" "}
            <span className="font-semibold"> {maskWalletAddress(walletAddress)}</span>.
          </>
        }
        onClose={setShouldShowWithdrawalState}
      />
    </>
  );
};

export default BalanceWithdrawalForm;
