import { useAppDispatch } from "app/hooks";
import { requestPasswordReset } from "app/slices/auth/thunk";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";

interface IFormValues {
  email: string;
  merchantID?: string;
}
const useResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmit = (data: IFormValues) => {
    setIsSubmittingForm(true);
    dispatch(requestPasswordReset(data))
      .then(({ payload }) => {
        if (payload?.error_id) {
          throw { message: payload?.msg };
        } else {
          if (payload.status === "success") {
            navigate("/auth/reset-password-sent", {
              state: { email: data.email },
            });
          }
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setIsSubmittingForm(false);
      });
  };
  return {
    isSubmittingForm,
    onSubmit,
  };
};

export default useResetPassword;
