import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetSwapForm } from "app/slices/transaction";
import {
  fetchSwapCurrencies,
  fetchSwapLimits,
  fetchSwapQuotes,
  sendSwapProcessConvertPairs,
} from "app/slices/transaction/thunk";
import LCQuoteRefreshInfo from "components/LCQuoteRefreshInfo";
import LRButton from "components/LRButton";
import LRInputAmountDropdown from "components/LRInputAmountDropdown";
import { REFRESH_QUOTES_S } from "constants/transactions";
import useRefetch from "hooks/useRefetch";
import React, { useEffect, useState } from "react";
import { before_, calculateAmountToSwap, calculateAmountToSwapTo } from "utility";
import { mul } from "utility/arithmeticFuncs";

const SwapForm = ({
  onSubmit,
  balanceAmount,
  onCoinToSellSelect,
}: {
  onSubmit: (x: any) => void;
  onCoinToSellSelect: (coin: string) => void;
  balanceAmount: string;
}) => {
  const swapTransaction = useAppSelector(({ transaction }) => transaction.swap);
  const { supportedCurrencies, quotes, limits } = swapTransaction;

  const [amountToSwapTo, setAmountToSwapTo] = useState(0);
  const [amountToSwap, setAmountToSwap] = useState(0);
  const [isLoadingSupportedCurrencies, setIsLoadingSupportedCurrencies] = useState(true);
  const [fieldError, setFieldError] = useState({ pay: false, get: false });
  const [hasAttemptedSumbit, setHasAttemptedSumbit] = useState(false);
  const [hasJustChangedCurrency, setHasJustChangedCurrency] = useState(false);

  const [currencyToSwap, setCurrencyToSwap] = useState({
    coin: "LTC",
    max_decimal: 8,
  });
  const [currencyToSwapTo, setCurrencyToSwapTo] = useState({
    coin: "USDT",
    max_decimal: 8,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useAppDispatch();

  const [isRefetchingQuote] = useRefetch(
    () =>
      dispatch(
        fetchSwapQuotes({
          from_currency: before_(currencyToSwap.coin),
          to_currency: before_(currencyToSwapTo.coin),
        })
      ),
    REFRESH_QUOTES_S,
    [currencyToSwapTo, currencyToSwap]
  );

  useEffect(() => {
    dispatch(
      fetchSwapLimits({
        from_currency: before_(currencyToSwap.coin),
        to_currency: before_(currencyToSwapTo.coin),
      })
    );
    return () => {
      dispatch(resetSwapForm());
    };
  }, [currencyToSwap, currencyToSwapTo]);

  useEffect(() => {
    if (!limits.minimum_swap.length) return;
    const refetchRates = setInterval(() => {
      dispatch(
        fetchSwapLimits({
          from_currency: before_(currencyToSwap.coin),
          to_currency: before_(currencyToSwapTo.coin),
        })
      );
    }, REFRESH_QUOTES_S * 1000);
    return () => {
      clearInterval(refetchRates);
    };
  }, [limits, currencyToSwap, currencyToSwapTo]);

  useEffect(() => {
    if (supportedCurrencies?.coins?.length) return;
    dispatch(fetchSwapCurrencies({})).then(() => {
      setIsLoadingSupportedCurrencies(false);
    });
  }, []);

  useEffect(() => {
    if (supportedCurrencies?.coins?.length) {
      setIsLoadingSupportedCurrencies(false);
    }
  }, [supportedCurrencies]);

  useEffect(() => {
    if (!limits?.minimum_swap || !quotes?.rate?.amount || isRefetchingQuote) return;
    setAmountToSwap(Math.max(amountToSwap, Number(limits.minimum_swap)) || 0);
    setAmountToSwapTo(
      calculateAmountToSwapTo({
        amountToSwap: Math.max(amountToSwap, Number(limits.minimum_swap)),
        exchangeRate: Number(quotes?.rate.amount),
        dpGet: currencyToSwapTo.max_decimal,
        dpPay: currencyToSwap.max_decimal,
      }) || 0
    );
    setHasJustChangedCurrency(false);
  }, [limits, quotes, isRefetchingQuote]);

  const handleAmountToSwapToChange = (value: string | undefined, fields?: any) => {
    if (value == `${amountToSwapTo}` || isSubmitting) return;
    setAmountToSwapTo(Number(value) || 0);
    setAmountToSwap(
      calculateAmountToSwap({
        amountToSwapTo: Number(value),
        exchangeRate: Number(quotes?.rate.amount),
        dpGet: currencyToSwapTo.max_decimal,
        dpPay: currencyToSwap.max_decimal,
      }) || 0
    );
  };

  const handleAmountToSwapChange = (value: string | undefined) => {
    if (value == `${amountToSwap}` || isSubmitting) return;
    setAmountToSwap(Number(value) || 0);
    setAmountToSwapTo(
      calculateAmountToSwapTo({
        amountToSwap: Number(value),
        exchangeRate: Number(quotes?.rate.amount),
        dpGet: currencyToSwapTo.max_decimal,
        dpPay: currencyToSwap.max_decimal,
      }) || 0
    );
  };

  const handleCurrencyToSwapToChange = (value: any) => {
    setHasJustChangedCurrency(true);
    setCurrencyToSwapTo(value);
  };

  const handleCurrencyToSwapChange = (value: any) => {
    if (currencyToSwap.coin === value?.coin) return;
    setHasJustChangedCurrency(true);
    setCurrencyToSwap(value);
    onCoinToSellSelect(value?.coin.toLowerCase());
    handleAmountToSwapChange(`${amountToSwap}`);
  };

  const validateMinMax = (callback: (x: boolean) => void) => {
    const hasFormError = { pay: false, get: false };
    if (Number(limits.minimum_swap) > amountToSwap) {
      hasFormError.pay = true;
    }
    setFieldError(hasFormError);
    callback(hasFormError.pay || hasFormError.get);
  };

  useEffect(() => {
    if (limits && hasAttemptedSumbit) {
      validateMinMax(() => {});
    }
  }, [amountToSwap, amountToSwapTo, limits]);

  const handleContinue = () => {
    setHasAttemptedSumbit(true);
    setIsSubmitting(true);

    validateMinMax((hasError: boolean) => {
      if (hasError) {
        setIsSubmitting(false);
        return;
      }
      dispatch(
        sendSwapProcessConvertPairs({
          from_currency: before_(currencyToSwap.coin),
          to_currency: before_(currencyToSwapTo.coin),
          from_amount: `${amountToSwap}`,
        })
      )
        .then(({ payload }) => {
          if (payload?.reference) {
            onSubmit({
              reference: payload?.reference,
              swappedInfo: {
                from_amount: `${amountToSwap}`,
                to_amount: `${amountToSwapTo}`,
                from_currency: currencyToSwap.coin,
                to_currency: currencyToSwapTo.coin,
              },
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    });
  };

  return (
    <div className="space-y-5">
      <div className="space-y-2">
        <div className="space-y-4">
          <LRInputAmountDropdown
            isSearchable
            label="Swap from"
            value={String(Math.max(amountToSwap, 0))}
            onChange={handleAmountToSwapChange}
            onOptionChange={handleCurrencyToSwapChange}
            selectedOption={currencyToSwap}
            defaultOption={currencyToSwap}
            dropDownData={supportedCurrencies?.coins || []}
            placeholder={"0.0"}
            dropdownTitle={"Select Currency"}
            decimalsLimit={currencyToSwap?.max_decimal || 0}
            isCurrencyLoading={isLoadingSupportedCurrencies || isSubmitting}
            minValue={Number(limits?.minimum_swap)}
            hasError={fieldError.pay}
            isLoadingAmount={!quotes?.rate.amount.length}
            hasMaxAmountButton
            handleMaxClick={() => {
              handleAmountToSwapChange(balanceAmount);
            }}
          />
          <LRInputAmountDropdown
            isSearchable
            label="Swap to"
            value={String(Math.max(amountToSwapTo, 0))}
            onChange={handleAmountToSwapToChange}
            onOptionChange={handleCurrencyToSwapToChange}
            selectedOption={currencyToSwapTo}
            defaultOption={currencyToSwapTo}
            dropDownData={supportedCurrencies?.coins || []}
            placeholder={"0.0"}
            dropdownTitle={"Select Currency"}
            decimalsLimit={currencyToSwapTo?.max_decimal || 0}
            isCurrencyLoading={isLoadingSupportedCurrencies || isSubmitting}
            minValue={Number(
              mul(limits?.minimum_swap, quotes?.rate.amount, currencyToSwapTo.max_decimal)
            )}
            isLoadingAmount={hasJustChangedCurrency}
            hasError={fieldError.get}
          />
        </div>
        {quotes?.rate?.amount ? (
          <LCQuoteRefreshInfo shouldRefreshTimer={isRefetchingQuote} />
        ) : null}
      </div>
      <div className="w-full">
        <LRButton
          text="Continue"
          onClick={handleContinue}
          isLoading={isSubmitting}
          isDisabled={
            isRefetchingQuote ||
            isLoadingSupportedCurrencies ||
            !limits?.minimum_swap ||
            !quotes?.rate?.amount?.length
          }
        />
      </div>
    </div>
  );
};

export default SwapForm;
