import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "app/hooks";
import { preRegisterMerchant } from "app/slices/auth/thunk";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import LRInputPhoneNumber from "components/LRInputPhoneNumber";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toastMessage } from "utility/toast";
import { signUpSchema } from "utility/validators";

interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  operatingBusinessName: string;
  password: string;
}

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(signUpSchema),
  });

  const onSubmit = (data: IFormValues) => {
    if (!phoneNumber.trim().length) {
      setError("phoneNumber", { message: "Phone number is required" });
      return;
    }
    setIsSubmittingForm(true);
    dispatch(preRegisterMerchant({ ...data, phoneNumber: `+${phoneNumber}` }))
      .then(({ payload: { status, error_id, msg } }) => {
        if (error_id) {
          throw { message: msg };
        }
        if (status <= 200 || status >= 400 || !data) {
          throw { message: "Something went wrong" };
        }
        navigate("/auth/validate-email", {
          state: { data: { ...data, phoneNumber: `+${phoneNumber}` } },
        });
      })
      .catch((error) => {
        toastMessage.error(error?.message);
      })
      .finally(() => {
        setIsSubmittingForm(false);
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LocalRamp | Signup</title>
        <link rel="canonical" href="http://merchant.localramp.co" />
      </Helmet>
      <div className="px-6 md:px-10 pt-8 pb-5">
        <div>
          <div className="text-center space-y-2 mb-6">
            <h2 className="">Merchant Signup</h2>
            <p className="text-xs text-black-40 font-light w-[203px] mx-auto grotesk">
              Hi there! Let’s have your details. We can’t wait to have you onboard.
            </p>
          </div>
          <div className="space-y-6">
            <form className="space-y-3">
              <div className="flex items-start justify-between space-x-4">
                <LCInput
                  label="First name"
                  placeholder="Joe"
                  type="text"
                  inputRegister={register("firstName")}
                  error={errors.firstName?.message}
                />
                <LCInput
                  label="Last name"
                  placeholder="Smith"
                  type="text"
                  inputRegister={register("lastName")}
                  error={errors.lastName?.message}
                />
              </div>
              <LCInput
                label="Email address"
                placeholder="joesmith@xyz.com"
                type="email"
                inputRegister={register("email")}
                error={errors.email?.message}
              />
              <LRInputPhoneNumber
                label="Phone number"
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                error={errors.phoneNumber?.message}
              />
              <LCInput
                label="Operating business name"
                placeholder="Enter business name"
                type="text"
                inputRegister={register("operatingBusinessName")}
                error={errors.operatingBusinessName?.message}
              />
              <LCInput
                label="Password"
                type="password"
                placeholder="Enter password"
                inputRegister={register("password")}
                error={errors.password?.message}
              />
            </form>
            <div className="space-y-4 text-center grotesk">
              <LRButton
                text="Create Account"
                onClick={handleSubmit(onSubmit)}
                isLoading={isSubmittingForm}
              />
              <div className="space-y-2">
                <p className="font-light text-xs">
                  Already have an account?{" "}
                  {isSubmittingForm ? (
                    <span className="text-purple font-normal inline-block">Login</span>
                  ) : (
                    <Link to={"/auth/login"} className="text-purple-dark font-semibold">
                      <span>Login</span>
                    </Link>
                  )}
                </p>
                <p className="font-light text-xs max-w-xs px-3 mx-auto text-black-40">
                  By signing up, I agree to Localramp
                  <a href="https://localramp.co/terms-of-use/" target={"_blank"}>
                    <span className="text-purple font-normal"> Terms of Service </span>
                  </a>
                  and
                  <a href="https://localramp.co/privacy/" target={"_blank"}>
                    <span className="text-purple font-normal"> Privacy Policy. </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
