import React from "react";
import afternoonIcon from "assets/icons/afternoon.svg";
import morningIcon from "assets/icons/morning.svg";
import { timeOfDay } from "utility";

interface IDashboardGreetingProps {
  name: string;
}
const emojiDayMap = {
  morning: morningIcon,
  afternoon: afternoonIcon,
  evening: afternoonIcon, //TO BE CHANGED
};
const DashboardGreeting = ({ name }: IDashboardGreetingProps) => {
  return (
    <div className="container-start space-x-1 capitalize">
      <h1>
        {timeOfDay()}, {name}
      </h1>
      <div className="inline-block w-8 h-8">
        <img
          src={emojiDayMap[timeOfDay() as keyof typeof emojiDayMap]}
          alt="hello"
          className="w-8 h-8"
        />
      </div>
    </div>
  );
};

export default DashboardGreeting;
