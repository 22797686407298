import { createAsyncThunk } from "@reduxjs/toolkit";
import { processCall } from "services/api";
import {
  deleteInviteTeamMember,
  deleteTeamMember,
  getTeamAccessTypes,
  getTeamMembers,
  postInviteTeam,
  postToGetInviteeTeam,
  putInviteTeam,
} from "./api";

export const sendInviteTeamMember = createAsyncThunk(
  "transaction/postInviteTeam",
  processCall(postInviteTeam)
);
export const sendToFetchInviteeTeam = createAsyncThunk(
  "transaction/postToGetInviteeTeam",
  processCall(postToGetInviteeTeam)
);
export const removeTeamMember = createAsyncThunk(
  "transaction/deleteTeamMember",
  processCall(deleteTeamMember)
);
export const removeInviteTeamMember = createAsyncThunk(
  "transaction/deleteInviteTeamMember",
  processCall(deleteInviteTeamMember)
);
export const updateInviteTeamMember = createAsyncThunk(
  "transaction/putInviteTeam",
  processCall(putInviteTeam)
);
export const fetchTeamMembers = createAsyncThunk(
  "transaction/getTeamMembers",
  processCall(getTeamMembers)
);
export const fetchTeamAccessTypes = createAsyncThunk(
  "transaction/getTeamAccessTypes",
  processCall(getTeamAccessTypes)
);
