import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import React, { useEffect, useState } from "react";

import googleAuthenticator from "assets/images/googleAuthenticator.svg";
import settings2FA from "assets/images/settings2FA.svg";
import LRSuccessPopup from "components/LRSuccessPopup";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchSettings2FADetails,
  removeSettings2FA,
  sendSettingsComplete2FA,
  sendSettingsSetup2FA,
} from "app/slices/settings/thunk";
import { toast } from "react-toastify";

const TwoFactorAuth = (props: { handleNav(v: string): void }) => {
  const dispatch = useAppDispatch();
  const { qr, two_factor_authenticator_key, url } = useAppSelector(
    ({ settings }) => settings.twoFA
  );
  const [code, setCode] = useState("");
  const [isProcessingAuthentication, setIsProcessingAuthentication] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading2FAData, setIsLoading2FAData] = useState(true);
  const [is2FASuccessful, setIs2FASuccessful] = useState(false);
  const [isCheckingFor2FA, setIsCheckingFor2FA] = useState(true);

  useEffect(() => {
    setIsCheckingFor2FA(true);
    dispatch(fetchSettings2FADetails({}))
      .then(({ payload }) => {
        if (payload?.data?.error_id === "no2FA") {
          setIsAuthenticated(false);
        } else if (payload?.data?.two_factor_enabled) {
          setIsAuthenticated(true);
        }
      })
      .finally(() => {
        setIsCheckingFor2FA(false);
      });
  }, []);

  useEffect(() => {
    if (!isCheckingFor2FA && !isAuthenticated) {
      dispatch(sendSettingsSetup2FA({})).then((e) => {
        setIsLoading2FAData(false);
      });
    }
  }, [isCheckingFor2FA, isAuthenticated]);

  const handleAuthentication = () => {
    setIsProcessingAuthentication(true);
    if (isAuthenticated) {
      dispatch(removeSettings2FA({}))
        .then(({ payload }) => {
          if (payload.status === "success") {
            setIsAuthenticated(false);
          }
        })
        .finally(() => {
          setIsProcessingAuthentication(false);
        });
      return;
    }
    dispatch(
      sendSettingsComplete2FA({
        token: code,
      })
    )
      .then(({ payload }) => {
        if (payload.status === "success") {
          setIsAuthenticated(!isAuthenticated);
          setIs2FASuccessful(true);
        }
      })
      .finally(() => {
        setIsProcessingAuthentication(false);
      });
  };

  return (
    <>
      <div className="w-full space-y-8">
        <div className="space-y-4 text-left">
          <p className="text-base font-semibold text-black-80">Two-Factor Authentication</p>
          <p className="grotesk text-sm md:text-xs text-black-20 max-w-3xl pr-4">
            Two-factor authentication provides an extra layer of security that reconfirms your
            identity on LocalRamp. Every time you sign in or initiate a transaction within an amount
            range, you will need to enter the unique single-use security code from your
            authenticator app. This code will be used to confirm your identity.
          </p>
        </div>
        {isCheckingFor2FA ? (
          <div className="w-10 h-10 container-center">
            <div className="animate-spin">
              <i className="ri-loader-5-line text-black" />
            </div>
          </div>
        ) : isAuthenticated ? (
          <TwoFactorAuthEnabled />
        ) : (
          <>
            <div className="space-y-4 pl-6 md:pl-0 md:space-y-0 md:flex items-start justify-start md:space-x-6">
              <div className="w-28 h-28 bg-black-20">
                {isLoading2FAData ? (
                  <div className="w-28 h-28 container-center flex-col">
                    <div className="animate-spin">
                      <i className="ri-loader-5-line text-white ri-xl" />
                    </div>
                  </div>
                ) : (
                  <img src={qr} alt="QR" />
                )}
              </div>
              <div className="w-full max-w-[245px] md:max-w-[273px]">
                <p className="grotesk text-sm md:text-xs text-black-80">
                  Scan this QR code with the authentication app on your trusted device.
                  <br /> Or copy this code into the app: <b> {two_factor_authenticator_key}</b>
                </p>
              </div>
            </div>
            <div className="max-w-sm">
              <LCInput
                label="Verification Code"
                placeholder="Enter code generated by your authenticator app..."
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </div>
          </>
        )}
        <div className="border-t border-t-gray-border pt-6">
          <div
            className={`container-start space-x-2 ${!isAuthenticated ? "w-36" : "w-60"} mr-auto `}
          >
            <div className="flex-grow">
              <LRButton
                isDisabled={!isAuthenticated && code.length !== 6}
                isLoading={isProcessingAuthentication}
                isGreen={!isAuthenticated}
                isRed={isAuthenticated}
                size="sm"
                text={isAuthenticated ? "Disable Authentication" : "Authenticate"}
                onClick={handleAuthentication}
              />
            </div>
            <div className="w-16">
              <LRButton
                size="sm"
                isGray
                text="Cancel"
                onClick={() => {
                  props.handleNav("");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {is2FASuccessful ? (
        <LRSuccessPopup
          title="Two-factor authentication setup successfully"
          message={<>You’re now better secured.</>}
          isVisible={is2FASuccessful}
          onClose={() => {
            setIs2FASuccessful(false);
          }}
        />
      ) : null}
    </>
  );
};

export default TwoFactorAuth;

const TwoFactorAuthEnabled = () => {
  return (
    <div className="container-between py-4 px-6 bg-[#EAF8E9] rounded-lg">
      <div className="container-start space-x-4">
        <div
          className="w-[60px] h-[60px] container-center rounded-2xl"
          style={{ boxShadow: "2px 4px 20px 0px #EAEAEABF" }}
        >
          <img src={googleAuthenticator} alt="icon" />
        </div>
        <div className="grotesk space-y-1 text-left w-full max-w-[301px]">
          <p className="text-sm text-green-accent-2 font-bold">Authentication Enabled</p>
        </div>
      </div>
      <div className="w-6 h-6">
        <img src={settings2FA} alt="icon" />
      </div>
    </div>
  );
};
