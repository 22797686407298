import useMobile from "hooks/useMobile";
import React from "react";

const AuthCardWrapper = ({ children }: { children: JSX.Element }) => {
  const [isMobile] = useMobile();

  return (
    <div
      className={`bg-white rounded-3xl -top-36 bottom-0 left-0 right-0 w-full max-w-[400px] mx-auto ${
        isMobile ? "" : "auth-card-shadow"
      }`}
    >
      {children}
    </div>
  );
};

export default AuthCardWrapper;
