import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { before_, commalize } from "../utility";
import LRDropdown from "./LRDropdown";

const LCAmountRange = (props: { min: number; max: number; currency: string; decimalPoint: number }) => (
	<>
		{!props.max ? "Minimum: " : null}
		{commalize(props.min, props.decimalPoint)}
		{props.max ? `- ${commalize(props.max, props.decimalPoint)}` : null} {props.currency}
	</>
);

const LRInputAmountDropdown = (props: {
	label: string;
	value: string;
	placeholder: string;
	onChange: (x: string | undefined) => void;
	selectedOption?: any;
	onOptionChange?: (x: any) => void;
	dropDownData?: any[];
	defaultOption?: any;
	dropdownTitle?: string;
	isCurrencyLoading?: boolean;
	decimalsLimit: number;
	decimalScale?: number;
	maxValue?: number;
	minValue?: number;
	hasError: boolean;
	isAllDisabled?: boolean;
	isLoadingAmount?: boolean;
	isDropdownDisabled?: boolean;
	isSearchable?: boolean;
	hasNoDropdown?: boolean;
	inputRegister?: any;
	error?: string;
	hasMaxAmountButton?: boolean;
	handleMaxClick?: () => void;
}) => {
	const [inValue, setInValue] = useState<string | number | undefined>(props.value);
	useEffect(() => {
		setInValue(props.value || 0);
	}, [props.value]);

	const [isInputFocus, setIsInputFocus] = useState(false);
	return (
		<div className="w-full">
			<label htmlFor={props.label} className="text-xs font-medium text-black-40">
				{props.label}
			</label>
			<div className="flex items-center w-full pb-1 space-x-3 text-base font-semibold border-b outline-none border-b-black-20">
				{!props.isLoadingAmount ? (
					<>
						<CurrencyInput
							id={props.label}
							name={props.label}
							placeholder={props.placeholder}
							defaultValue={""}
							value={inValue}
							decimalsLimit={props.decimalsLimit}
							{...props.inputRegister}
							// decimalScale={props.decimalScale}
							onValueChange={(value: any) => {
								if (Number(value) < 0) return;
								setInValue(value);
								props.onChange(value);
							}}
							disabled={props.isAllDisabled || props.isCurrencyLoading}
							onFocus={() => {
								setIsInputFocus(true);
							}}
							onBlur={(e) => {
								setIsInputFocus(false);
								e.preventDefault();
							}}
							className="w-full text-sm font-semibold outline-none"
						/>
					</>
				) : (
					<div className="w-full h-4 bg-gray-300 rounded-2xl animate-pulse"></div>
				)}
				{!props.isCurrencyLoading && props.dropDownData && props.dropdownTitle ? (
					<LRDropdown
						onOptionChange={props.onOptionChange ? props.onOptionChange : () => {}}
						defaultOption={props.defaultOption}
						dropdownTitle={props.dropdownTitle}
						options={props.dropDownData}
						isDisabled={props.isAllDisabled || props.isDropdownDisabled || !!props.isCurrencyLoading}
						isSearchable={props.isSearchable}
					/>
				) : null}
			</div>
			{!!props.error?.trim().length && <p className="mt-1 text-xs text-left text-red">{props.error}</p>}
			<p className={`${props.hasError ? "text-red" : "text-gray-400"} text-xs mt-1`}>
				{props.hasError ? `Amount should be ${props.maxValue ? "between" : ""} ` : null}
				<div className="w-full container-between">
					<div>
						{isInputFocus || props.hasError ? (
							<LCAmountRange
								min={props.minValue || 0}
								max={Number(props.maxValue)}
								currency={before_(props.selectedOption?.fiat || props.selectedOption.coin)}
								decimalPoint={props.decimalsLimit}
							/>
						) : null}
					</div>
					{props.hasMaxAmountButton && !props.isLoadingAmount ? (
						<button onClick={props.handleMaxClick} className="text-xs bg-transparent border-none outline-none text-purple">
							Max
						</button>
					) : null}
				</div>
			</p>
		</div>
	);
};

export default LRInputAmountDropdown;
