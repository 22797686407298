import { useContext, useEffect } from "react";
import { REFRESH_QUOTES_S } from "constants/transactions";
import { useCountDown } from "../hooks/useCountdown";

const LCQuoteRefreshInfo = ({ shouldRefreshTimer }: { shouldRefreshTimer: boolean }) => {
  const [counter, start, pause, reset] = useCountDown(REFRESH_QUOTES_S);
  useEffect(() => {
    start();
  }, [start]);

  useEffect(() => {
    if (shouldRefreshTimer) {
      reset();
    }
  }, [reset, shouldRefreshTimer]);

  return (
    <div className="flex items-center justify-end space-x-1 py-1 text-purple">
      <i
        className={`ri-history-fill ri-1x text-purple ${
          shouldRefreshTimer && "animate-reverse-spin"
        }`}
      ></i>
      <p className="text-xs font-medium text-purple">
        {shouldRefreshTimer ? `Fetching Quote...` : `Quote refreshes in ${counter}s`}
      </p>
    </div>
  );
};

export default LCQuoteRefreshInfo;
