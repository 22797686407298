import LRButton from "components/LRButton";
import React from "react";

import settingsPassword from "assets/images/settingsPassword.svg";
import settings2FA from "assets/images/settings2FA.svg";

interface IAccountSecurityProps {
  handleNav: (screen: string) => void;
}
const AccountSecurity = ({ handleNav }: IAccountSecurityProps) => {
  return (
    <div className="w-full space-y-8">
      <div className="space-y-4 text-left">
        <p className="text-base font-semibold text-black-80">Account Security</p>
        <p className="grotesk text-xs text-black-20">
          LocalRamp maintains high standards for account security. That's why we've provided a
          couple of ways for you to protect your account.
        </p>
      </div>
      <div className="">
        <div className="border-t border-b border-gray-border">
          <SecurityOption
            iconSrc={settingsPassword}
            title="Password"
            description="Keep your password safe. We recommend you change your password periodically."
            actionText="Change Password"
            handleClick={handleNav}
            screenKey="password"
          />
        </div>
        <SecurityOption
          isGreen
          iconSrc={settings2FA}
          title="Two-factor Authentication"
          description="Add extra security on your account to authenticate your login and transactions."
          actionText="Get Started"
          handleClick={handleNav}
          screenKey="2fa"
        />
      </div>
    </div>
  );
};

export default AccountSecurity;

export const SecurityOption = (props: {
  title: string;
  screenKey: string;
  actionText: string;
  iconSrc: string;
  description: string;
  isGreen?: boolean;
  isBlack?: boolean;
  handleClick: (v: string) => void;
}) => {
  return (
    <div className="py-8 space-y-6 md:space-y-0 md:flex md:items-center md:justify-between md:py-7">
      <div className="container-start space-x-4">
        <div>
          <div
            className="w-20 md:w-[60px] h-20 md:h-[60px] container-center rounded-2xl"
            style={{ boxShadow: "2px 4px 20px 0px #EAEAEABF" }}
          >
            <img src={props.iconSrc} alt="icon" className="w-10 h-10 md:w-8 md:h-8" />
          </div>
        </div>
        <div className="grotesk space-y-1 text-left w-full max-w-[301px]">
          <p className="text-sm text-black-80 font-bold">{props.title}</p>
          <p className="text-xs text-black-40">{props.description}</p>
        </div>
      </div>
      <div className="text-right">
        <div className="inline-block ">
          <LRButton
            isGreen={!!props.isGreen}
            isBlack={!!props.isBlack}
            text={props.actionText}
            onClick={() => {
              props.handleClick(props.screenKey);
            }}
          />
        </div>
      </div>
    </div>
  );
};
