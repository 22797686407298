import { useClickOutside } from "hooks/useClickOutside";
import React, { useState } from "react";

interface IMemberRowActionsProps {
  onRemove?(x: boolean): void;
  onRevoke?(x: boolean): void;
  onManage?(x: boolean): void;
  isVisible: boolean;
  isRevoke?: boolean;
  onClose(x: boolean): void;
}
const MemberRowActions = ({
  onManage,
  onRemove,
  onClose,
  isVisible,
  isRevoke = false,
  onRevoke,
}: IMemberRowActionsProps) => {
  const [shouldShowOptions, setShouldShowOptions] = useState(isVisible);
  const { popedComponentRef } = useClickOutside(onClose);

  return shouldShowOptions ? (
    <div
      ref={popedComponentRef}
      className="bg-white border border-[#F2F2F2] rounded-[4px] w-[184px] absolute z-20"
    >
      <button
        onClick={() => {
          if (isRevoke) {
            onRevoke && onRevoke(true);
          } else {
            onRemove && onRemove(true);
          }
          onClose(false);
        }}
        className="border-none bg-transparent outline-none"
      >
        <div className="px-4 py-3 container-start space-x-2">
          <i className="ri-delete-bin-4-line text-red ri-lg" />
          <p className="text-xs font-semibold text-black">{isRevoke ? "Revoke" : "Remove"}</p>
        </div>
      </button>
      {!isRevoke ? (
        <>
          <hr className="border-b border-b-gray-border px-4" />
          <button
            onClick={() => {
              onManage && onManage(true);
              onClose(false);
            }}
            className="border-none bg-transparent outline-none"
          >
            <div className="px-4 py-3 container-start space-x-2">
              <i className="ri-delete-bin-4-line text-purple-dark ri-lg" />
              <p className="text-xs font-semibold text-black">Manage Access</p>
            </div>
          </button>
        </>
      ) : null}
    </div>
  ) : null;
};

export default MemberRowActions;
