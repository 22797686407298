import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CLIENT_STORAGE_TOKEN, CLIENT_USER_DATA } from "constants/storage";
import { toast } from "react-toastify";
import { api, apiInstance } from "services/api";
import { setInStorage, setJsonInStorage } from "utility/storage";
import { toastMessage } from "utility/toast";
import { joinTeam, loginMerchant, registerMerchant } from "./thunk";

const initialState = {
  auth: {
    loginStep: "",
    merchantDetails: {},
    isInSession: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthHasSession(state, { payload }) {
      state.auth.isInSession = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase<any>(loginMerchant.fulfilled, (state, { payload }) => {
      state.auth.isInSession = true;
      setInStorage(CLIENT_STORAGE_TOKEN, payload?.auth_token);
      apiInstance.defaults.headers.common["Authorization"] = `Bearer ${payload?.auth_token}`;
      setJsonInStorage(CLIENT_USER_DATA, payload?.data);
    });
    builder.addCase<any>(registerMerchant.fulfilled, (state, { payload }) => {
      setInStorage(CLIENT_STORAGE_TOKEN, payload?.data?.auth_token);
    });
    builder.addCase<any>(loginMerchant.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload?.error_id === "2FARequired") {
        toastMessage.success("Enter your token to login");
        return;
      }
      toastMessage.error(action.payload?.msg);
    });
    builder.addCase<any>(joinTeam.rejected, (state, action: PayloadAction<any>) => {
      toast.error(action.payload?.msg);
    });
    builder.addCase<any>(joinTeam.fulfilled, (state, action: PayloadAction<any>) => {
      toast.error(action.payload?.msg);
    });
  },
});

export const { setAuthHasSession } = authSlice.actions;

export default authSlice.reducer;
