import { AxiosHeaders, AxiosRequestConfig } from "axios";
import { api, apiInstance } from "services/api";
import { ENDPOINTS } from "services/endpoints";
import { getFromStorage } from "utility/storage";

const { auth } = ENDPOINTS;

export const postPreRegisterMerchant = (payload: any) =>
  api.post(auth.PRE_REGISTER_MERCHANT, payload, {});

export const postRegisterMerchant = (payload: { token: string; data: any }) =>
  api.post(auth.REGISTER_MERCHANT, payload, {});

export const postLogin = (payload: any) => api.post(auth.LOGIN, payload, {});

export const postTeamJoin = (payload: any) => {
  apiInstance.interceptors.request.use((config): AxiosRequestConfig<any> => {
    if (config.headers) {
      (config.headers as AxiosHeaders).set("team-join-token", payload.token);
    }
    return config;
  });
  return api.post(auth.REGEISTER_TEAM_MEMBER, { ...payload.data }, {});
};

export const postRequestPasswordReset = (payload: any) =>
  api.post(
    auth.REQUEST_PASSWORD_RESET,
    {
      ...payload,
      password_reset_url: `${process.env.REACT_APP_FRONTEND_HOST}/auth/new-password`,
    },
    {}
  );

export const postResetPassword = (payload: any) =>
  api.post(auth.RESET_PASSWORD, { ...payload }, {});
