import { useAppDispatch } from "app/hooks";
import { sendSellProcessTransaction } from "app/slices/transaction/thunk";
import LRModal from "components/LRModal";
import LRStatusTag from "components/LRStatusTag";
import LRSuccessPopup from "components/LRSuccessPopup";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import InitiateSwap from "./InitiateSwap";

enum SCREENS {
  INITIATE_SWAP,
  LOADING,
  TRANSACTION_STATE,
}

interface ISwapFormProps {
  isVisible: boolean;
  onClose: (v: boolean) => void;
}

const SwapForm = ({ isVisible, onClose }: ISwapFormProps) => {
  const [currentScreen, setCurrentScreen] = useState(SCREENS.INITIATE_SWAP);

  const [shouldShowTransactionState, setShouldShowTransactionState] = useState(false);
  const [swappedInfo, setSwappedInfo] = useState({
    from_amount: "",
    from_currency: "",
    to_amount: "",
    to_currency: "",
  });
  const handleSetAmounts = ({ reference, swappedInfo }: any) => {
    onClose(false);
    setSwappedInfo(swappedInfo);
    setShouldShowTransactionState(true);
  };
  const screenToDisplay = () => {
    switch (currentScreen) {
      case SCREENS.INITIATE_SWAP:
        return <InitiateSwap onSubmit={handleSetAmounts} />;
    }
  };

  return (
    <>
      {isVisible ? (
        <LRModal isShorter isSmaller noPadding isVisible={isVisible} onCloseModal={onClose}>
          <div className="pt-6 px-7 pb-10 min-h-[390px]">
            <div className="container-between pb-4">
              <div>
                {currentScreen !== SCREENS.INITIATE_SWAP ? (
                  <button
                    onClick={() => {
                      setCurrentScreen(SCREENS.INITIATE_SWAP);
                    }}
                    className="container-start space-x-1 border-none outline-none"
                  >
                    <i className="ri-arrow-left-s-line ri-lg"></i>
                    <span className="text-sm font-medium">Sell coins</span>
                  </button>
                ) : null}
              </div>

              <button
                onClick={() => {
                  onClose(false);
                }}
                className="border-0 outline-none bg-transparent hidden sm:inline-block"
              >
                <i className="ri-close-fill ri-lg text-black" />
              </button>
            </div>
            <div>
              <div className="space-y-6">{screenToDisplay()}</div>
            </div>
          </div>
        </LRModal>
      ) : null}
      <LRSuccessPopup
        isVisible={shouldShowTransactionState}
        title="Coin swap initiated"
        message={
          <>
            You successfully initiated a swap of <br />{" "}
            <b>
              {swappedInfo?.from_amount} {swappedInfo?.from_currency}
            </b>{" "}
            for{" "}
            <b>
              {swappedInfo?.to_amount} {swappedInfo?.to_currency}
            </b>
          </>
        }
        onClose={setShouldShowTransactionState}
      />
    </>
  );
};

export default SwapForm;
