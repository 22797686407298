import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  fetchTeamAccessTypes,
  fetchTeamMembers,
  removeInviteTeamMember,
  removeTeamMember,
  sendInviteTeamMember,
  updateInviteTeamMember,
} from "./thunk";

const initialState = {
  list: {
    team: [],
    invites: [],
    isLoading: true,
  },
  accessTypes: [],
};
export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase<any>(sendInviteTeamMember.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(removeTeamMember.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(removeInviteTeamMember.rejected, (state, { payload }) => {
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(fetchTeamAccessTypes.fulfilled, (state, { payload }) => {
      state.accessTypes = payload?.data;
    });
    // TEAM MEMBERS
    builder.addCase<any>(fetchTeamMembers.pending, (state, { payload }) => {
      state.list.isLoading = true;
    });
    builder.addCase<any>(fetchTeamMembers.fulfilled, (state, { payload }) => {
      state.list = payload?.data;
      state.list.isLoading = false;
    });
    builder.addCase<any>(fetchTeamMembers.rejected, (state, { payload }) => {
      state.list.isLoading = false;
      toast.error(payload?.msg as string);
    });
    builder.addCase<any>(updateInviteTeamMember.rejected, (state, { payload }) => {
      toast.error(payload?.message as string);
    });
  },
});

export const {} = teamSlice.actions;

export default teamSlice.reducer;
