import LRTabs from "components/LRTabs";
import { LOCALRAMP_ROLES } from "constants/roles";
import AuthenticatedPagesOnly from "containers/AuthenticatedPagesOnly";
import useMobile from "hooks/useMobile";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import APISettings from "../containers/APISettings";
import Preferences from "../containers/Preferences";
import Profile from "../containers/Profile";
import Security from "../containers/Security";

const tabs = [
  { title: "Profile", key: "profile" },
  { title: "Security", key: "security" },
  { title: "API", key: "api", roles: [LOCALRAMP_ROLES.ADMIN] },
  { title: "Preferences", key: "preferences", roles: [LOCALRAMP_ROLES.ADMIN] },
];
const Settings = () => {
  const [currentTab, setCurrentTab] = useState("profile");
  const location = useLocation();

  const [isMobile] = useMobile();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentTab(tabs.filter((tab) => location.pathname.includes(tab.key))[0]?.key);
  }, [location.pathname]);

  const handleTabChange = (key: string) => {
    navigate(key);
    if (key === currentTab) {
      return;
    }
    setCurrentTab(key);
  };
  return (
    <AuthenticatedPagesOnly pageName="Settings">
      <>
        <div className="px-6 md:px-14 pt-6 space-y-6">
          <LRTabs hasLine tabs={tabs} onChange={handleTabChange} activeTab={currentTab} />
          <div className="w-full">
            <div
              className={`${currentTab !== "api" && "md:rounded-2xl md:p-8 md:bg-white"}`}
              style={{
                boxShadow: currentTab !== "api" && !isMobile ? "0px 0px 8px 0px #EAEAEA8C" : "",
              }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </>
    </AuthenticatedPagesOnly>
  );
};

export default Settings;
