import React from "react";
import sidebarCompanyIcon from "assets/icons/sidebarCompany.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { getMerchantFromStorage, storageMerchantRole } from "utility/storage";
import { LOCALRAMP_ROLES } from "constants/roles";

interface ISidebarItemProps {
  iconName: string;
  text: string;
  link?: string;
  onChange?: () => void;
}

export const menuItemsTransactions = [
  {
    iconName: "ri-arrow-left-down-line",
    text: "Buy transactions",
    link: "/dashboard/buy-transactions",
  },
  {
    iconName: "ri-arrow-right-up-line",
    text: "Sell transactions",
    link: "/dashboard/sell-transactions",
  },
  {
    iconName: "ri-arrow-left-right-line",
    text: "Swap transactions",
    link: "/dashboard/swap-transactions",
  },
  { iconName: "ri-money-dollar-circle-line", text: "Balances", link: "/dashboard/balances" },
];
export const menuItemsAccount = [
  { iconName: "ri-settings-3-line", text: "Settings", link: "/dashboard/settings" },
  {
    iconName: "ri-user-3-line",
    text: "Team",
    link: "/dashboard/team",
    roles: [LOCALRAMP_ROLES.ADMIN],
  },
];
export const menuItemsFooter = [
  {
    iconName: "ri-heart-line",
    text: "Support",
    onChange: () => {
      location.href = "mailto:business@localramp.co";
    },
  },
  {
    iconName: "ri-book-open-line",
    text: "Documentation",
    onChange: () => {
      window.open("https://docs.localramp.co/", "_blank");
    },
  },
];

export const SidebarItem = ({ iconName, text, link, onChange }: ISidebarItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    if (!link) {
      onChange && onChange();
      return;
    }
    if (link?.includes("logout")) {
      localStorage.clear();
      navigate("/auth/login");
    }
    onChange && onChange();
    navigate(link);
  };
  const isActive = () => link && location.pathname.includes(link);
  return (
    <button
      onClick={handleClick}
      className="w-full bg-transparent duration-300 hover:translate-x-2"
    >
      <div
        className={
          "flex items-center justify-start space-x-3 px-6 md:px-4 py-4 lg:py-2 border-b border-b-white/10 lg:border-b-0"
        }
      >
        <i className={`${iconName} ri-lg text-white lg:text-purple`} />
        <p
          className={`text-sm text-white lg:text-black-80 grotesk ${
            isActive() ? "font-medium" : ""
          }`}
        >
          {text}
        </p>
      </div>
    </button>
  );
};

const Sidebar = () => {
  const merchant = getMerchantFromStorage();
  const businessName = merchant?.operating_business_name;
  return (
    <div className="w-[272px] h-screen bg-gray fixed">
      <div className="pt-4 px-6 container-between flex-col w-full h-full pb-28">
        <div>
          <div className="space-y-8">
            <div className="container-start space-x-4 px-3">
              <img src={sidebarCompanyIcon} alt="company" />
              <div className="space-y-1">
                <p className="text-sm text-black-80 font-semibold grotesk">{businessName}</p>
                <p className="text-xs text-black-40 font-medium grotesk">
                  {getMerchantFromStorage()?.external_reference || ""}
                </p>
              </div>
            </div>
            <div className="space-y-6">
              <SidebarItem iconName="ri-home-3-line" text="Home" link="/dashboard" />
              <div className="space-y-3">
                <p className="text-purple grotesk text-xxs px-3 tracking-[0.12em] font-medium">
                  TRANSACTIONS
                </p>
                <div>
                  {menuItemsTransactions.map((item, i) => (
                    <SidebarItem key={i} {...item} />
                  ))}
                </div>
              </div>
              <div className="space-y-3">
                <p className="text-purple grotesk text-xxs px-3 tracking-[0.12em] font-medium">
                  ACCOUNT
                </p>
                <div>
                  {menuItemsAccount.map((item, i) => {
                    if (item.roles) {
                      if (item.roles?.includes(storageMerchantRole())) {
                        return <SidebarItem key={i} {...item} />;
                      } else {
                        return null;
                      }
                    }
                    return <SidebarItem key={i} {...item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {menuItemsFooter.map((item, i) => (
            <SidebarItem key={i} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
