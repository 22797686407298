import { useQuery } from "@tanstack/react-query";
import { useAppDispatch } from "app/hooks";
import { sendSellSetDestination } from "app/slices/transaction/thunk";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import LRInputSelect from "components/LRInputSelect";
import React, { useState } from "react";
import { apiInstance } from "services/api";
import { ENDPOINTS } from "services/endpoints";
import { constructParams, getCountryPhoneCode } from "utility";

type Props = {
	currencyInfo: { currency: string; country_code: string };
	reference: string;
	isProcessingSell: boolean;
	onProcessSell: any;
};
const { transaction } = ENDPOINTS;
const getSupportedMomo = (params: any) => apiInstance.get(`${transaction.sell.SUPPORTED_MOMO}?${constructParams(params)}`);

const EnterMomoInfo = ({ currencyInfo, reference, onProcessSell, isProcessingSell }: Props) => {
	const dispatch = useAppDispatch();

	const [selectedMomo, setSelectedMomo] = useState<any>({});
	const [phoneNumber, setPhoneNumber] = useState("");

	const [isSettingDestination, setIsSettingDestination] = useState(false);

	const { data, isLoading } = useQuery({
		queryKey: ["supported-momo"],
		queryFn: () => getSupportedMomo({ currency: currencyInfo.currency, country_code: currencyInfo.country_code }),
	});

	const supportedMomo = data?.data?.data;

	const handleSelectMomo = (option: any) => setSelectedMomo(option);

	const handleConfirmSell = () => {
		setIsSettingDestination(true);
		dispatch(
			sendSellSetDestination({
				reference,
				destination_type: "mobile_money",
				country_code: currencyInfo?.country_code,
				phone_network: selectedMomo?.id,
				phone_number: getCountryPhoneCode(currencyInfo.country_code)?.concat(phoneNumber),
			})
		)
			.then(({ payload }) => {
				if (!payload?.error_id) {
					onProcessSell();
				}
			})
			.finally(() => {
				setIsSettingDestination(false);
			});
	};

	return (
		<div className="flex-col w-full h-full pt-2 space-y-16 container-between">
			<div className="w-full space-y-3">
				<div className="space-y-4">
					<LRInputSelect
						displayKey="name"
						label="Mobile Network"
						options={supportedMomo}
						isLoadingOptions={isLoading}
						selectedValueText={selectedMomo?.name}
						handleOptionSelect={handleSelectMomo}
						placeholder="Select network"
					/>
					<LCInput
						label="Phone number"
						type="phoneNumber"
						placeholder="0000000000"
						value={phoneNumber}
						isDisabled={isSettingDestination}
						country_code={currencyInfo?.country_code}
						onChange={(e) => {
							setPhoneNumber(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="w-full">
				<LRButton
					text="Confirm sell"
					isLoading={isSettingDestination || isProcessingSell}
					isDisabled={!selectedMomo?.id || !phoneNumber?.length}
					onClick={handleConfirmSell}
				/>
			</div>
		</div>
	);
};

export default EnterMomoInfo;
