import React, { useState } from "react";
import { SecurityOption } from "../Security/AccountSecurity";

import supportedCoins from "assets/images/security/supportedCoins.svg";
import supportedFiat from "assets/images/security/supportedFiat.svg";
import merchantBuyFees from "assets/images/security/merchantBuyFees.svg";
import pathnershipProgram from "assets/images/security/pathnership.svg";
import { EPreferencesScreen } from ".";
import LRSuccessPopup from "components/LRSuccessPopup";
import { useNavigate } from "react-router";
import LRPartnershipPopup from "components/LRPartnershipPopup";
import { apiInstance } from "services/api";
import { ENDPOINTS } from "services/endpoints";
import { useQuery } from "@tanstack/react-query";

interface IPreferenceListProps {
	handleNav(x: string): void;
}

const PreferenceList = () => {
	const navigate = useNavigate();
	const [showBuyFeesModal, setShowBuyFeesModal] = useState(false);
	const [showPathnershipModal, setShowPathnershipModal] = useState(false);

	const PartnershipRequest = ENDPOINTS?.settings?.PREFERENCES?.PARTHNERSHIP_PROGRAM;

	const { data } = useQuery({
		queryKey: ["referral"],
		queryFn: () => apiInstance.get(PartnershipRequest, {}),
	});

	const handleManageSupportedCoins = () => {
		navigate("/dashboard/settings/preferences/supported-coins");
	};
	const handleManageSupportedFiat = () => {
		navigate("/dashboard/settings/preferences/supported-fiats");
	};
	const handleSetBuyFees = () => {
		setShowBuyFeesModal(true);
	};
	const handleParthnershipProgram = (value: boolean) => {
		data?.data?.data?.code && data?.data?.data?.link ? setShowPathnershipModal(value) : setShowBuyFeesModal(value);
	};
	return (
		<>
			<div className="w-full -mb-5 space-y-3">
				<div className="space-y-4 text-left">
					<p className="text-base font-semibold text-black-80">Preferences</p>
				</div>
				<div className="">
					<SecurityOption
						iconSrc={supportedCoins}
						title="Supported Coins"
						description="Accept over 64+ coins as payment for your business. "
						actionText="Manage supported coins"
						handleClick={handleManageSupportedCoins}
						screenKey="supportedCoins"
					/>
					<div className="border-t border-b border-gray-border">
						<SecurityOption
							isGreen
							iconSrc={supportedFiat}
							title="Supported Fiat"
							description="Accept payment from multiple african countries. NGN, KES, GHS, XAF."
							actionText="Manage supported fiat"
							handleClick={handleManageSupportedFiat}
							screenKey="supportedFiat"
						/>
					</div>
					<div className="border-t border-b border-gray-border">
						<SecurityOption
							iconSrc={merchantBuyFees}
							title="Merchant Partner Fees (in %)"
							description="Earn fees through your widget integration."
							actionText="Set partner fees"
							handleClick={handleSetBuyFees}
							screenKey="merchantBuyFees"
						/>
					</div>
					<SecurityOption
						iconSrc={pathnershipProgram}
						isBlack
						title="B2C Partnership Program"
						description="Do you have a large crypto audience? Refer them to any partner exchange and make $$$"
						actionText="Get partner link"
						handleClick={() => handleParthnershipProgram(true)}
						screenKey="pathnershipProgram"
					/>
				</div>
			</div>
			<LRSuccessPopup
				hasSmile
				message={
					<span className="text-sm font-semibold">
						Kindly reach out to{" "}
						<a href="mailto:business@localramp.co">
							<span className="text-purple">support</span>
						</a>{" "}
						to help with this.
					</span>
				}
				isVisible={showBuyFeesModal}
				onClose={setShowBuyFeesModal}
			/>

			<LRPartnershipPopup
				headerTitle="Partnership Program"
				title={data?.data?.data?.link}
				message={<span className="text-sm font-semibold">Copy this link and share to your users for fast checkout</span>}
				isVisible={showPathnershipModal}
				onClose={handleParthnershipProgram}
			/>
		</>
	);
};

export default PreferenceList;
