import React from "react";

const colourMaps: { [x: string]: any } = {
  success: { bg: "#E6F4E7", text: "#44A55E" },
  pending: { bg: "#FAEAD3", text: "#DD8808" },
  //   failed: { bg: "#FAEAD3", text: "#DD8808" },
  stale: { bg: "#ECECEC", text: "#747474" },
};

const statusColourMap: { [x: string]: any } = {
  completed: "success",
  pending: "pending",
  waiting: "stale",
  awaiting_payment: "stale",
};

const LRStatusTag = ({
  text,
  state,
  isWidthFull = false,
}: {
  text: any;
  state?: "success" | "pending" | "stale";
  isWidthFull?: boolean;
}) => {
  const selectedColour = !state?.length
    ? colourMaps[statusColourMap[String(text) || ""]]
    : colourMaps[state || "stale"];
  if (!selectedColour?.bg) return <p></p>;
  return (
    <div className="inline-block">
      <div
        className={`h-6 container-center rounded-[4px] px-2 font-semibold grotesk text-xxs capitalize ${
          !isWidthFull ? "w-auto" : "w-full"
        }`}
        style={{ backgroundColor: selectedColour.bg, color: selectedColour.text }}
      >
        {text?.replaceAll("_", " ")}
      </div>
    </div>
  );
};

export default LRStatusTag;
