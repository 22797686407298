import React, { useEffect, useReducer, useState } from "react";
import LRInput from "components/LRInput";
import LRTableFilterFormSelect from "./LRTableFilterFormSelect";
import LRButton from "components/LRButton";
import LRInputDate from "components/LRInputDate";
import moment from "moment";

export type TFilterField = {
	type: "query" | "coinType" | "paymentMethod" | "startDate" | "endDate";
	label?: string;
	key?: string;
	keyForOption?: string;
	options?: any[];
	isLoading?: boolean;
	hasNetwork?: boolean;
	isSearchable?: boolean;
	placeholder?: string;
	searchPlaceholder?: string;
};
interface ILRTableFilterFormProps {
	onSubmit(x: IFiltersState): void;
	filterFields: TFilterField[];
	appliedFilters?: any;
	currentTab: string;
}
export interface IFiltersState {
	query?: string;
	coinType?: string;
	paymentMethod?: { name: string; id: string };
	startDate?: Date | undefined;
	endDate?: Date | undefined;
	[x: string]: any;
}
interface IAction {
	type: string;
	payload: any;
}

const filterReducer = (state: IFiltersState, action: IAction): IFiltersState => {
	switch (action.type) {
		case "update_query":
			return { ...state, query: action.payload };
		case "set_coin_type":
			return { ...state, ...action.payload };
		case "set_payment_method":
			return { ...state, paymentMethod: action.payload };
		case "set_start_date":
			return { ...state, startDate: action.payload };
		case "set_end_date":
			return { ...state, endDate: action.payload };
		case "reset_filter":
			return { query: undefined };

		default:
			break;
	}
	return state;
};

const LRTableFilterForm = ({ filterFields, onSubmit, appliedFilters, currentTab }: ILRTableFilterFormProps) => {
	const [state, dispatch] = useReducer(filterReducer, {}, (state) => ({
		...state,
		...appliedFilters,
	}));
	const [hasMadeChange, setHasMadeChange] = useState(false);
	useEffect(() => {
		if (Object.keys(state).length) {
			setHasMadeChange(true);
		}
		return () => {
			setHasMadeChange(false);
		};
	}, [state]);

	useEffect(() => {
		dispatch({ type: "reset_filter", payload: "" });
	}, [currentTab]);

	const handleFilterClear = () => {
		dispatch({ type: "reset_filter", payload: "" });
		onSubmit({});
	};
	const handleApplyFilter = () => {
		onSubmit(state);
	};

	const fields = (data: TFilterField) => ({
		query: (
			<LRInput
				key={"query"}
				hasBorder
				label={"Keyword"}
				placeholder="Search anything"
				value={state.query}
				onChange={(e) => {
					dispatch({ type: "update_query", payload: e.target.value });
				}}
			/>
		),
		coinType: (
			<LRTableFilterFormSelect
				hasNetwork={data?.hasNetwork}
				key={data?.key || "coinType"}
				hasSearch={data.isSearchable}
				label={data.label || "Coin type"}
				keyForOption={data.keyForOption}
				placeholder={data.placeholder || ""}
				searchPlaceholder={data.searchPlaceholder}
				options={data.options || []}
				isLoading={data.isLoading || false}
				handleOptionSelect={(option) => {
					let payload: { coin?: string; fiat?: string; name?: string };
					if (option?.fiat) {
						payload = {
							[data?.key || ""]: { fiat: option.fiat, name: option.name },
						};
					} else {
						payload = {
							[data?.key || ""]: { coin: option.coin, name: option.name },
						};
					}
					dispatch({
						type: "set_coin_type",
						payload,
					});
				}}
				selectedValueText={data?.key ? state[data.key as keyof typeof state]?.[data.keyForOption ? data.keyForOption : "name"] : ""}
			/>
		),
		paymentMethod: (
			<LRTableFilterFormSelect
				key={"paymentMethod"}
				placeholder="All"
				keyForOption="name"
				label="Payment method"
				isLoading={data.isLoading}
				hasLogo={false}
				options={data.options || []}
				handleOptionSelect={(option) => {
					dispatch({ type: "set_payment_method", payload: option });
				}}
				selectedValueText={state.paymentMethod?.name}
			/>
		),
		startDate: (
			<LRInputDate
				key={"startDate"}
				label={
					<p className="font-medium text-xxs grotesk text-black-40">
						From <span className="text-light text-black-10">(Start date)</span>
					</p>
				}
				selected={state.startDate}
				onSelect={(date) => {
					dispatch({ type: "set_start_date", payload: moment(date).format("YYYY-MM-DD") });
				}}
				placeholder="Enter start date"
			/>
		),
		endDate: (
			<LRInputDate
				key={"endDate"}
				label={
					<p className="font-medium text-xxs grotesk text-black-40">
						To <span className="text-light text-black-10">(End date)</span>
					</p>
				}
				selected={state.endDate}
				onSelect={(date) => {
					dispatch({ type: "set_end_date", payload: moment(date).format("YYYY-MM-DD") });
				}}
				placeholder="Select an end date"
			/>
		),
	});
	return (
		<div className="pb-6 border-b border-b-gray-border">
			<div className="w-full grid grid-cols-3 xl:grid-cols-4 gap-3 max-w-[836px] items-end">
				{filterFields.map((field) => fields(field)[field?.type])}
				<div className="container-start space-x-1 w-full max-w-[180px]">
					<LRButton isGray text="Clear" onClick={handleFilterClear} />
					<LRButton text="Apply Filter" onClick={handleApplyFilter} isDisabled={!hasMadeChange} />
				</div>
			</div>
		</div>
	);
};

export default LRTableFilterForm;
