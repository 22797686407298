import { useWalletBalance } from "modules/transactions/hooks/useWalletBalance";
import React from "react";
import WalletBalance from "../../WalletBalance";
import BalanceWithdrawalForm from "./BalanceWithdrawalForm";

const InitiateBalanceWithdrawal = ({
  onSubmit,
  currency,
}: {
  onSubmit: (x: any) => void;
  currency: string;
}) => {
  const { balanceCoin, balanceAmount } = useWalletBalance(currency);

  return (
    <div>
      <div className="container-between w-full mb-5">
        <p className="text-base font-medium">Withdrawal</p>
        <WalletBalance
          balance={balanceCoin() ? `${balanceAmount()} ${balanceCoin()?.toUpperCase()}` : "--"}
        />
      </div>
      <BalanceWithdrawalForm
        currency={currency}
        onSubmit={onSubmit}
        balanceAmount={balanceAmount()}
      />
    </div>
  );
};

export default InitiateBalanceWithdrawal;
