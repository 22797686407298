import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchTeamMembers } from "app/slices/team/thunk";
import LRTable, { ILRTableColumnProps } from "components/LRTable";
import { ITeamMembers } from "modules/transactions/types";
import React, { useEffect, useState } from "react";
import { friendlyDateFormatter } from "utility";
import ManageTeamMember from "./ManageTeamMember";
import MemberRowActions from "./MemberRowActions";
import RemoveTeamMember from "./RemoveTeamMember";
import RevokeInvite from "./RevokeInvite";
import TeamMemberStatus from "./TeamMemberStatus";

const TeamMembersTable = () => {
  const columns: ILRTableColumnProps<ITeamMembers>[] = [
    {
      key: "first_name",
      title: "Team Member",
      render: (name, { last_name }) => (
        <p className="text-xs grotesk text-black-80 capitalize">
          {name ? `${name} ${last_name}` : "--"}
        </p>
      ),
    },
    {
      key: "email",
      title: "Email Address",
      render: (email) => <p className="text-xs grotesk text-black-40">{email}</p>,
    },
    {
      key: "access",
      title: "Access",
      render: (access) => <p className="text-xs grotesk text-black-80">{access}</p>,
    },
    {
      key: "state",
      title: "Status",
      render: (status, { first_name }) => (
        <TeamMemberStatus state={first_name ? "active" : "invite"} />
      ),
    },
    {
      key: "created_at",
      title: "Date Joined",
      render: (created_at) => (
        <p className="text-xs grotesk text-black-80">{friendlyDateFormatter(created_at) || "--"}</p>
      ),
    },
    {
      key: "email", //using email to define uniqueness of row item
      title: "",
      render: (email, row) => (
        <div className="relative">
          <button
            onClick={() => {
              handleRowClick(row);
            }}
            className="outline-none border-none"
          >
            <i className="ri-more-line ri-lg text-purple mt-1" />
          </button>
          {shouldShowRowOptions && selectedMember?.email === email ? (
            row?.external_reference ? (
              <MemberRowActions
                isVisible={shouldShowRowOptions}
                onClose={setShouldShowRowOptions}
                onManage={setShouldShowManageMemberModal}
                onRemove={setShouldShowDeleteMemberModal}
              />
            ) : (
              <MemberRowActions
                isRevoke
                isVisible={shouldShowRowOptions}
                onClose={setShouldShowRowOptions}
                onRevoke={setShouldShowRevokeMemberInviteModal}
              />
            )
          ) : null}
        </div>
      ),
    },
  ];
  const [shouldShowRowOptions, setShouldShowRowOptions] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState<ITeamMembers>();
  const [shouldShowManageMemberModal, setShouldShowManageMemberModal] = useState(false);
  const [shouldShowDeleteMemberModal, setShouldShowDeleteMemberModal] = useState(false);
  const [shouldShowRevokeMemberInviteModal, setShouldShowRevokeMemberInviteModal] = useState(false);
  const dispatch = useAppDispatch();
  const { team, invites, isLoading } = useAppSelector(({ team }) => team.list);
  useEffect(() => {
    dispatch(fetchTeamMembers({})).then(() => {
      setIsDataLoading(false);
    });
  }, []);

  const handleRowClick = (data: ITeamMembers) => {
    setSelectedMember(data);
    setShouldShowRowOptions(true);
  };
  return (
    <>
      <p className="text-base font-semibold text-black-80 pb-6">
        Team Members ({[...team, ...invites].length})
      </p>
      <div>
        <LRTable
          isTeamTable
          columns={columns}
          data={[...team, ...invites]}
          isLoading={isLoading || isDataLoading}
          onRowClick={() => {
            setShouldShowRowOptions(true);
          }}
          pagination={{}}
        />
      </div>
      {shouldShowManageMemberModal ? (
        <ManageTeamMember
          isVisible={shouldShowManageMemberModal}
          onClose={setShouldShowManageMemberModal}
          memberEmail={selectedMember?.email || ""}
          reference={selectedMember?.external_reference || ""}
          role={selectedMember?.access || ""}
        />
      ) : null}
      {shouldShowDeleteMemberModal ? (
        <RemoveTeamMember
          isVisible={shouldShowDeleteMemberModal}
          onClose={setShouldShowDeleteMemberModal}
          memberEmail={selectedMember?.email || ""}
          reference={selectedMember?.external_reference || ""}
          role={selectedMember?.access || ""}
        />
      ) : null}
      {shouldShowRevokeMemberInviteModal ? (
        <RevokeInvite
          isVisible={shouldShowRevokeMemberInviteModal}
          onClose={setShouldShowRevokeMemberInviteModal}
          memberEmail={selectedMember?.email || ""}
        />
      ) : null}
    </>
  );
};

export default TeamMembersTable;
