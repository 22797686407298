import { useAppDispatch } from "app/hooks";
import { sendSellProcessTransaction } from "app/slices/transaction/thunk";
import LRModal from "components/LRModal";
import LRSuccessPopup from "components/LRSuccessPopup";
import React, { useState } from "react";
import { useEffect } from "react";
import EnterPaymentInfo from "./EnterPaymentInfo";
import InitiateSell from "./InitiateSell";
import PaymentLoading from "./PaymentLoading";
import EnterMomoInfo from "./EnterMomoInfo";

export enum SCREENS {
	INITIATE_SELL,
	ENTER_PAYMENT_INFO,
	ENTER_MOMO_INFO,
	LOADING,
	TRANSACTION_STATE,
}

interface ISellFormProps {
	isVisible: boolean;
	onClose: (v: boolean) => void;
}

const SellForm = ({ isVisible, onClose }: ISellFormProps) => {
	const dispatch = useAppDispatch();
	const [currentScreen, setCurrentScreen] = useState(SCREENS.INITIATE_SELL);
	const [transactionRef, setTransactionRef] = useState("");
	const [shouldShowTransactionState, setShouldShowTransactionState] = useState(false);
	const [isLoadingSell, setIsLoadingSell] = useState(false);
	const [isProcessingSell, setIsProcessingSell] = useState(false);
	const [amountAndCurrency, setAmountAndCurrency] = useState("");
	const [currencyInfo, setCurrencyInfo] = useState<any>({});

	const handleSetAmounts = (value: {
		screen: string;
		reference: string;
		amount: string;
		currencyInfo: { currency: string; country_code: string };
	}) => {
		setTransactionRef(value.reference);
		setAmountAndCurrency(value.amount);
		setCurrentScreen(value.screen === "bank_account" ? SCREENS.ENTER_PAYMENT_INFO : SCREENS.ENTER_MOMO_INFO);
		setCurrencyInfo(value.currencyInfo);
	};
	const processSell = () => {
		dispatch(
			sendSellProcessTransaction({
				reference: transactionRef,
			})
		)
			.then(({ payload }) => {
				if (payload?.status === "success") {
					onClose(false);
					setShouldShowTransactionState(true);
					return;
				}
			})
			.finally(() => {
				setIsProcessingSell(true);
			});
	};
	const screenToDisplay = () => {
		switch (currentScreen) {
			case SCREENS.INITIATE_SELL:
				return <InitiateSell onSubmit={handleSetAmounts} setCurrentScreen={setCurrentScreen} />;
			case SCREENS.ENTER_PAYMENT_INFO:
				return <EnterPaymentInfo onProcessSell={processSell} isProcessingSell={isProcessingSell} reference={transactionRef} />;
			case SCREENS.ENTER_MOMO_INFO:
				return (
					<EnterMomoInfo
						onProcessSell={processSell}
						isProcessingSell={isProcessingSell}
						reference={transactionRef}
						currencyInfo={currencyInfo}
					/>
				);
			default:
				return <InitiateSell onSubmit={handleSetAmounts} setCurrentScreen={setCurrentScreen} />;
		}
	};

	useEffect(() => {
		setCurrentScreen(SCREENS.INITIATE_SELL);
	}, [isVisible]);

	return (
		<>
			{!isLoadingSell ? (
				isVisible ? (
					<LRModal isShorter isSmaller noPadding isVisible={isVisible} onCloseModal={onClose}>
						<div className="pt-6 px-7 pb-10 min-h-[390px]">
							<div className="pb-4 container-between">
								<div>
									{currentScreen !== SCREENS.INITIATE_SELL ? (
										<button
											onClick={() => {
												setCurrentScreen(SCREENS.INITIATE_SELL);
											}}
											className="space-x-1 border-none outline-none container-start"
										>
											<i className="ri-arrow-left-s-line ri-lg"></i>
											<span className="text-sm font-medium">Sell coins</span>
										</button>
									) : null}
								</div>

								<button
									onClick={() => {
										onClose(false);
									}}
									className="hidden bg-transparent border-0 outline-none sm:inline-block"
								>
									<i className="text-black ri-close-fill ri-lg" />
								</button>
							</div>
							<div className="space-y-6">{screenToDisplay()}</div>
						</div>
					</LRModal>
				) : null
			) : isLoadingSell ? (
				<PaymentLoading isVisible={isLoadingSell} onClose={setIsLoadingSell} />
			) : null}
			<LRSuccessPopup
				isVisible={shouldShowTransactionState}
				title="Transaction Successful"
				message={
					<>
						You successfully sold <span className="font-semibold">{amountAndCurrency}</span>.{" "}
					</>
				}
				onClose={setShouldShowTransactionState}
			/>
		</>
	);
};

export default SellForm;
