import { useAppDispatch } from "app/hooks";
import { fetchTeamAccessTypes, fetchTeamMembers } from "app/slices/team/thunk";
import LRButton from "components/LRButton";
import AuthenticatedPagesOnly from "containers/AuthenticatedPagesOnly";
import useMobile from "hooks/useMobile";
import React, { useEffect, useState } from "react";
import { checkIfAuthenticated } from "utility/auth";
import InviteTeamMember from "../components/InviteTeamMember";
import TeamMembersTable from "../components/TeamMembersTable";

const Team = () => {
  const dispatch = useAppDispatch();
  const [isMobile] = useMobile();
  const [shouldShowInviteMemberModal, setShouldShowInviteMemberModal] = useState(false);
  useEffect(() => {
    if (!checkIfAuthenticated()) return;

    dispatch(fetchTeamAccessTypes({}));
  }, []);

  const handleInviteModalClose = () => {
    dispatch(fetchTeamMembers({}));
    setShouldShowInviteMemberModal(false);
  };

  return (
    <AuthenticatedPagesOnly pageName="Team">
      <>
        <div className="md:px-14 pt-6 space-y-4">
          <div
            className={"md:rounded-2xl p-6 md:p-8 md:bg-white"}
            style={{ boxShadow: isMobile ? "" : "0px 0px 8px 0px #EAEAEA8C" }}
          >
            <div className="space-y-6 md:space-y-1 pb-8 border-b border-b-gray-border">
              <div className="w-full flex justify-between items-start space-x-10">
                <p className="text-base font-semibold text-black-80">Team</p>
                <div>
                  <LRButton
                    size="sm"
                    prefixIconName="ri-user-add-line"
                    text=" Add team member"
                    onClick={() => {
                      setShouldShowInviteMemberModal(true);
                    }}
                  />
                </div>
              </div>
              <div className="text-left max-w-lg pr-2">
                <p className="grotesk text-xs text-black-20">
                  Here’s your team. Account owners and admins can manage your team, assign roles and
                  invite new team members with email.
                </p>
              </div>
            </div>
            <div className="pt-8 md:pt-4">
              <TeamMembersTable />
            </div>
          </div>
        </div>
        {shouldShowInviteMemberModal ? (
          <InviteTeamMember
            isVisible={shouldShowInviteMemberModal}
            onClose={handleInviteModalClose}
          />
        ) : null}
      </>
    </AuthenticatedPagesOnly>
  );
};

export default Team;
