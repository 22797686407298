import React from "react";
import { copyToClipboard, findCoinLogo } from "utility";

interface IDetailItemProps {
  title: string;
  copy?: string;
  hasCoin?: boolean;
  isLoading?: boolean;
  coin?: any;
  value: string | number | undefined | JSX.Element;
}
const LRDetailItem = ({ title, value = "", copy, hasCoin, coin, isLoading }: IDetailItemProps) => {
  const handleValueClick = () => {
    if (copy) {
      copyToClipboard(`${copy}`);
    }
  };
  return (
    <li className="container-between text-xs grotesk">
      <p className="font-bold text-black-80">{title}:</p>

      {isLoading ? (
        <div className="w-36 h-3 rounded-md bg-black-10 animate-pulse"></div>
      ) : (
        <div
          role={!!copy ? "button" : ""}
          onClick={handleValueClick}
          className="text-black-40 text-right font-semibold"
        >
          {hasCoin ? (
            <div className="container-end space-x-1">
              <div className="w-4 h-4 rounded-full bg-black-40">
                <img src={findCoinLogo(coin)} alt="" />
              </div>
              <p>{value}</p>
            </div>
          ) : copy ? (
            <div className="container-end space-x-1">
              <p>{value}</p>
              <i className="ri-file-copy-fill text-purple text-base"></i>
            </div>
          ) : (
            value
          )}
        </div>
      )}
    </li>
  );
};

export default LRDetailItem;
