import React, { useState } from "react";
import profileIcon from "assets/icons/profile.svg";
import ProfileIcon from "components/CustomIcons/ProfileIcon";
import { useNavigate } from "react-router";
import { useClickOutside } from "hooks/useClickOutside";
import { getMerchantFromStorage } from "utility/storage";

interface ITopBarProps {
  name: string;
}
const TopBar = ({ name }: ITopBarProps) => {
  const navigate = useNavigate();
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const { popedComponentRef } = useClickOutside(setIsProfilePopupOpen);

  const handleProfileClick = () => {
    setIsProfilePopupOpen(!isProfilePopupOpen);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/login");
  };

  const merchant = getMerchantFromStorage();
  return (
    <div className="bg-off-white px-14 py-4 border-b-[1.5px] border-b-gray-border">
      <div className="flex items-center justify-between w-full lg:max-w-7xl">
        <h2 className="text-black-20">{name}</h2>
        <div className="relative" ref={popedComponentRef}>
          <button
            onClick={handleProfileClick}
            className="w-10 h-10 bg-gray-border rounded-full flex items-center justify-center"
          >
            <ProfileIcon />
          </button>

          {isProfilePopupOpen ? (
            <div className="w-[272px] rounded-lg p-4 bg-purple absolute top-[50px] right-0 z-20">
              <div className="container-start space-x-3 border-b-2 border-b-[#6363FF] pb-3 mb-1">
                <div
                  onClick={handleProfileClick}
                  className="w-10 h-10 bg-gray-border rounded-full flex items-center justify-center"
                >
                  <ProfileIcon fill="#5551FF" />
                </div>
                <div className="font-semibold">
                  <p className="text-white text-sm capitalize">{merchant?.first_name}</p>
                  <p className="text-[#B5B5FB] text-xs">{getMerchantFromStorage()?.access || ""}</p>
                </div>
              </div>
              <div>
                <button onClick={handleLogout} className="py-2 container-start space-x-3 w-full">
                  <i className="ri-logout-box-r-line text-white"></i>
                  <p className="text-white font-bold text-sm">Logout</p>
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
