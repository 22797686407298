import { useWalletBalance } from "modules/transactions/hooks/useWalletBalance";
import React from "react";
import WalletBalance from "../../WalletBalance";
import BalanceDepositForm from "./BalanceDepositForm";

const InitiateBalanceDeposit = ({
  onSubmit,
  currency,
}: {
  onSubmit: (x: any) => void;
  currency: string;
}) => {
  const { balanceCoin, balanceAmount } = useWalletBalance(currency);

  return (
    <div>
      <div className="container-between w-full mb-4 px-7">
        <p className="text-base font-medium">Deposit</p>
        <WalletBalance
          balance={balanceCoin() ? `${balanceAmount()} ${balanceCoin()?.toUpperCase()}` : "--"}
        />
      </div>

      <BalanceDepositForm currency={currency} onSubmit={onSubmit} />
    </div>
  );
};

export default InitiateBalanceDeposit;
