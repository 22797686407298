import { api } from "services/api";
import { ENDPOINTS } from "services/endpoints";

const { settings } = ENDPOINTS;

// API
export const getAPIKeys = () => api.get(`${settings.API.KEYS}`, {});
export const postResetAPIKeys = () => api.post(`${settings.API.KEYS}`, {}, {});
export const getWebhookInfo = () => api.get(`${settings.API.WEBHOOK_INFO}`, {});
export const postWebhookInfo = (body: { webhook_url: string; webhook_key: string }) =>
  api.post(`${settings.API.WEBHOOK_INFO}`, body, {});

// PREFERENCES
export const postResetCurrencyList = () =>
  api.post(`${settings.PREFERENCES.RESET_CURRENCY_LIST}`, {}, {});
export const postEnableCurrency = (body: { coins?: any; fiats?: any }) =>
  api.post(`${settings.PREFERENCES.ENABLE_CURRENCY}`, body, {});
export const getMerchantCurrency = () =>
  api.get(`${settings.PREFERENCES.GET_MERCHANT_CURRENCY}`, {});
export const postSetBuyFee = (body: { coins: string[]; fiat: string }) =>
  api.post(`${settings.PREFERENCES.SET_BUY_FEE}`, body, {});

// SECURITY
export const get2FADetails = () => api.get(`${settings.SECURITY.TWO_FA}`, {});
export const postSetup2FA = () => api.post(`${settings.SECURITY.TWO_FA}`, {}, {});
export const postComplete2FA = (body: { token: string }) =>
  api.post(`${settings.SECURITY.TWO_FA_VERIFY}`, body, {});
export const delete2FA = () => api.delete(`${settings.SECURITY.TWO_FA}`, {}, {});
export const putChangePassword = (body: { old_password: string; new_password: string }) =>
  api.put(`${settings.SECURITY.CHANGE_PASSWORD}`, body, {});
