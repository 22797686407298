import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "app/hooks";
import { updateSettingsPasswordChange } from "app/slices/settings/thunk";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import LRSuccessPopup from "components/LRSuccessPopup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { settingsChangePasswordSchema } from "utility/validators";

interface IFormValues {
  password: string;
  newPassword: string;
  confirmPassword?: string;
}
const ChangePassword = (props: { handleNav(v: string): void }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(settingsChangePasswordSchema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPasswordChangeSuccessModal, setShowPasswordChangeSuccessModal] = useState(false);
  const handleClose = () => {
    localStorage.clear();
    navigate("/auth/login");
  };

  const handleSave = (data: IFormValues) => {
    setIsSubmitting(true);
    dispatch(
      updateSettingsPasswordChange({
        old_password: data.password,
        new_password: data.newPassword,
      })
    )
      .then(({ payload }) => {
        if (payload.status === "success") {
          setShowPasswordChangeSuccessModal(true);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div className="w-full space-y-8">
        <div className="space-y-4 text-left">
          <p className="text-base font-semibold text-black-80">Account Security</p>
          <p className="grotesk text-xs text-black-20">
            LocalRamp maintains high standards for account security. That's why we've provided a
            couple of ways for you to protect your account.
          </p>
        </div>
        <form className="max-w-[400px] space-y-4">
          <LCInput
            label="Current password"
            placeholder="Enter your current password"
            type="password"
            inputRegister={register("password")}
            error={errors?.password?.message}
          />
          <LCInput
            label="New password"
            placeholder="Enter a new password"
            type="password"
            inputRegister={register("newPassword")}
            error={errors?.newPassword?.message}
          />
          <LCInput
            label="Repeat new password"
            placeholder="Enter new password again"
            type="password"
            inputRegister={register("confirmPassword")}
            error={errors?.confirmPassword?.message}
          />
        </form>
        <div className="border-t border-t-gray-border pt-6">
          <div className="container-start space-x-2 w-36 mr-auto ">
            <LRButton
              size="sm"
              text="Submit"
              onClick={handleSubmit(handleSave)}
              isLoading={isSubmitting}
            />
            <LRButton
              size="sm"
              isGray
              text="Cancel"
              onClick={() => {
                props.handleNav("");
              }}
            />
          </div>
        </div>
      </div>
      {showPasswordChangeSuccessModal ? (
        <LRSuccessPopup
          hasCloseTimer
          closeIn={2500}
          isVisible={showPasswordChangeSuccessModal}
          onClose={handleClose}
          title="Password Updated"
          message={<>You’ll be redirected to sign in again</>}
        />
      ) : null}
    </>
  );
};

export default ChangePassword;
