import React from "react";

interface ICustomIconProps {
  fill?: string;
  width?: string;
  height?: string;
}
const ProfileIcon = ({ fill, height, width }: ICustomIconProps) => {
  return (
    <svg
      width={width || "28"}
      height={height || "28"}
      viewBox={`0 0 ${width || 28} ${height || 28}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3719_36356)">
        <path
          d="M22.8872 25.1112H5.10938V22.8889C5.10938 21.4155 5.69469 20.0024 6.73656 18.9606C7.77843 17.9187 9.19151 17.3334 10.6649 17.3334H17.3316C18.805 17.3334 20.2181 17.9187 21.26 18.9606C22.3018 20.0024 22.8872 21.4155 22.8872 22.8889V25.1112ZM13.9983 15.1112C13.1228 15.1112 12.2559 14.9387 11.447 14.6037C10.6382 14.2687 9.90328 13.7776 9.28422 13.1585C8.66516 12.5395 8.1741 11.8046 7.83907 10.9957C7.50404 10.1869 7.3316 9.31998 7.3316 8.4445C7.3316 7.56902 7.50404 6.70211 7.83907 5.89328C8.1741 5.08444 8.66516 4.34951 9.28422 3.73045C9.90328 3.1114 10.6382 2.62033 11.447 2.2853C12.2559 1.95027 13.1228 1.77783 13.9983 1.77783C15.7664 1.77783 17.4621 2.48021 18.7123 3.73045C19.9626 4.9807 20.6649 6.67639 20.6649 8.4445C20.6649 10.2126 19.9626 11.9083 18.7123 13.1585C17.4621 14.4088 15.7664 15.1112 13.9983 15.1112Z"
          fill={fill || "#FAFAFA"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3719_36356">
          <rect
            width="26.6667"
            height="26.6667"
            fill="white"
            transform="translate(0.667969 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProfileIcon;
