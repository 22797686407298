import React, { useEffect } from "react";

import AuthenticatedPagesOnly from "containers/AuthenticatedPagesOnly";
import DashboardGreeting from "./components/DashboardGreeting";
import TopRecommendations from "./components/TopRecommendations";
import TransactionOverview from "./components/TransactionOverview";
import { getMerchantFromStorage } from "utility/storage";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchDashboardStats } from "app/slices/dashboard/thunk";
import useMobile from "hooks/useMobile";
import MerchantNotVerified from "containers/MerchantNotVerified";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { stats } = useAppSelector((state) => state.dashboard);
  const isInSession = useAppSelector((state) => state.auth.auth.isInSession);
  const [isMobile] = useMobile();

  const isMerchantVerified = () => getMerchantFromStorage()?.is_verified;

  useEffect(() => {
    if (isMerchantVerified()) {
      dispatch(fetchDashboardStats({}));
    }
  }, []);

  return (
    <>
      <AuthenticatedPagesOnly pageName="Dashboard">
        {isMerchantVerified() ? (
          <>
            <div className="px-6 md:px-14 w-full">
              <div className="flex items-stretch justify-start my-8 w-full max-w-4xl pb-10">
                <div className="space-y-2 md:space-y-4 pr-10">
                  <DashboardGreeting name={getMerchantFromStorage()?.first_name} />
                  <p className="text-xs md:text-sm text-black-40 w-full max-w-[344px] font-light grotesk">
                    Welcome to your dashboard. See a quick summary of your transactions below.
                  </p>
                </div>
                {isMobile ? null : (
                  <div className="border-l-2 border-l-gray-border pl-10 py-2 flex-grow">
                    <TopRecommendations />
                  </div>
                )}
              </div>
              <TransactionOverview
                todayReceivedAmount={stats?.today_received_amount}
                monthReceivedAmount={stats?.month_received_amount}
                lifetimeReceivedAmount={stats?.lifetime_received_amount}
                todayReceivedCount={stats?.today_received_count}
                monthReceivedCount={stats?.month_received_count}
                lifetimeReceivedCount={stats?.lifetime_received_count}
              />
            </div>
          </>
        ) : (
          <MerchantNotVerified />
        )}
      </AuthenticatedPagesOnly>
    </>
  );
};

export default Dashboard;
