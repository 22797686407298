import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "app/hooks";
import { joinTeam, loginMerchant } from "app/slices/auth/thunk";
import { sendToFetchInviteeTeam } from "app/slices/team/thunk";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toastMessage } from "utility/toast";
import { joinTeamSchema } from "utility/validators";

interface IFormValues {
  firstName: string;
  lastName: string;
  password: string;
}

const JoinTeam = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<{
    email: string;
    operating_business_name: string;
  }>();
  const [errorMessage, setErrorMessage] = useState("");

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(joinTeamSchema),
  });

  useEffect(() => {
    if (!token) {
      navigate("/auth/login");
      return;
    }
    dispatch(
      sendToFetchInviteeTeam({
        token,
      })
    ).then(({ payload }) => {
      if (payload?.data?.email) {
        setCompanyDetails(payload?.data);
      } else {
        setErrorMessage(payload?.msg);
      }
    });
  }, []);

  const onSubmit = (data: IFormValues) => {
    setIsSubmittingForm(true);
    if (!data.firstName.trim().length) {
      setIsSubmittingForm(false);
      setError("firstName", { message: "First name is required" });
      return;
    }
    if (!data.lastName.trim().length) {
      setIsSubmittingForm(false);
      setError("lastName", { message: "Last name is required" });
      return;
    }
    dispatch(joinTeam({ token, data }))
      .then(({ payload: { data: teamData, error_id, msg } }) => {
        if (error_id) {
          throw { message: msg };
        }
        dispatch(
          loginMerchant({
            merchantId: teamData?.merchant_external_reference,
            email: teamData?.email,
            password: data.password,
          })
        ).then(({ payload }) => {
          if (!payload.error_id) {
            navigate("/dashboard");
          }
        });
      })
      .catch((error) => {
        toastMessage.error(error?.message);
      })
      .finally(() => {
        setIsSubmittingForm(false);
      });
  };

  return (
    <div className="px-6 md:px-10 pt-8 pb-5">
      {companyDetails?.email ? (
        <div>
          <div className="text-center space-y-2 mb-6">
            <h2 className="">Join your team</h2>
            <p className="text-xs text-black-40 font-light w-[257px] mx-auto grotesk">
              Kindly complete this form to create your profile and join{" "}
              <span className="text-purple font-medium">
                {companyDetails.operating_business_name}
              </span>
            </p>
          </div>
          <div className="space-y-6">
            <form className="space-y-3">
              <LCInput
                hasAutoComplete={false}
                label="First name"
                placeholder="Joe"
                type="text"
                inputRegister={register("firstName")}
                error={errors.firstName?.message}
              />
              <LCInput
                hasAutoComplete={false}
                label="Last name"
                placeholder="Smith"
                type="text"
                inputRegister={register("lastName")}
                error={errors.lastName?.message}
              />
              <LCInput
                hasAutoComplete={false}
                label="Password"
                type="password"
                placeholder="Enter password"
                inputRegister={register("password")}
                error={errors.password?.message}
              />
            </form>
            <div className="space-y-4 text-center grotesk">
              <LRButton
                text="Create Account"
                onClick={handleSubmit(onSubmit)}
                isLoading={isSubmittingForm}
              />
              <div className="space-y-2">
                <p className="font-light text-xs">
                  Already have an account?{" "}
                  {isSubmittingForm ? (
                    <span className="text-purple font-normal inline-block">Login</span>
                  ) : (
                    <Link to={"/auth/login"} className="text-purple-dark font-semibold">
                      <span>Login</span>
                    </Link>
                  )}
                </p>
                <p className="font-light text-xs max-w-xs px-3 mx-auto text-black-40">
                  By signing up, I agree to Localramp
                  <a href="https://localramp.co/terms-of-use/" target={"_blank"}>
                    <span className="text-purple font-normal"> Terms of Service </span>
                  </a>
                  and
                  <a href="https://localramp.co/privacy/" target={"_blank"}>
                    <span className="text-purple font-normal"> Privacy Policy. </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : errorMessage?.length ? (
        <div className="text-center pb-3">
          <div className="grotesk text-red bg-rose-200 px-4 py-2 border border-rose-300 inline-block rounded-md">
            <p className="text-xs">{errorMessage || "Something went wrong"}</p>
          </div>
        </div>
      ) : (
        <div className="container-center flex-col pb-3">
          <span className="mx-auto text-center">
            <div className="w-6 h-[15.98px] animate-spin">
              <i className="ri-loader-line ri-xl text-purple" />
            </div>
          </span>
        </div>
      )}
    </div>
  );
};

export default JoinTeam;
