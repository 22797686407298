import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import verificationEnvelope from "assets/images/verificationEnvelope.svg";
import { Helmet } from "react-helmet";

const ResetPasswordSent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.email) {
      navigate("/auth/reset");
    }
  }, [location, navigate]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LocalRamp | Reset Password</title>
        <link rel="canonical" href="http://merchant.localramp.co" />
      </Helmet>
      <div className="px-6 md:px-10 pt-12 pb-16">
        <div className="space-y-6">
          <div className="text-center space-y-5 mb-6">
            <h2 className="">Forgot Password</h2>
            <img src={verificationEnvelope} alt="verification" className="mx-auto" />
          </div>
          <div className="space-y-6 grotesk">
            <div className="text-sm text-center text-black-40 w-60 mx-auto">
              <p>We sent an email to</p>
              <p className="font-bold text-black-80">{location?.state?.email}</p>
            </div>
            <div className="text-sm text-center text-black-40 font-light w-full max-w-xs px-2 mx-auto">
              <p>Kindly check the link in the email to reset your password</p>
            </div>
            <div className="text-center pt-4">
              <Link to={"/auth/login"} className="text-purple font-semibold text-xs">
                <span>Back to Login</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordSent;
