import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastCaseErrorMSG } from "utility";
import {
  fetchBalanceCrypto,
  fetchBalanceCurrencyDepositAddress,
  fetchBalanceCurrencyDeposits,
  fetchBalanceCurrencyWithdrawals,
  fetchBalanceDepositNetworks,
  fetchBalanceWithdrawalNetworks,
  fetchBuyPaymentMethods,
  fetchBuySupportedCurrencies,
  fetchBuyTranasactions,
  fetchBuyTransactionDetials,
  fetchSellLimits,
  fetchSellRates,
  fetchSellSupportedBanks,
  fetchSellSupportedCurrencies,
  fetchSellTranasactionDetails,
  fetchSellTranasactions,
  fetchSwapCurrencies,
  fetchSwapLimits,
  fetchSwapQuotes,
  fetchSwapTransactionDetails,
  fetchSwapTransactions,
  sendBalanceWithdrawCrypto,
  sendSellProcessTransaction,
  sendSellSetAmounts,
  sendSellSetDestination,
  sendSellVerifyBank,
  sendSwapProcessConvertPairs,
} from "./thunk";

const initialState = {
  buy: {
    supportedCurrencies: {
      receiver_currency: [],
      sender_currency: [],
    },
    single: {
      reference: "",
      created_at: "",
      updated_at: "",
      sender_currency: "",
      receiver_currency: "",
      sent_amount: "",
      received_amount: "",
      txid: "",
      destination_address: "",
      email: "",
      state: "",
      payment_method: "",
      destination_tag: "",
    },
    list: {
      data: [],
      pagination: {
        count: 0,
        limit: null,
        page: null,
      },
    },
    paymentMethods: [],
  },
  sell: {
    supportedCurrencies: {
      to_currency: [],
      from_currency: [],
    },
    single: {
      reference: "",
      created_at: "",
      updated_at: "",
      from_currency: "",
      to_currency: "",
      from_amount: "",
      to_amount: "",

      destination_type: "",
      account_name: "",
      account_number: "",
      bank_name: "",
      initiated_from: "",
      state: "",
    },
    limits: {
      from_minimum: "",
      from_maximum: "",
      from_currency: "",
    },
    banks: [],
    list: {
      data: [],
      pagination: {
        count: 0,
        limit: null,
        page: null,
      },
    },
    rates: {
      rate: {
        info: "",
        amount: "",
      },
      fee: {
        info: "",
        amount: "",
      },
    },
  },
  swap: {
    supportedCurrencies: {
      coins: [],
    },
    limits: {
      minimum_swap: "",
      currency: "",
    },
    single: {
      reference: "",
      created_at: "",
      from_currency: "",
      to_currency: "",
      from_amount: "",
      to_amount: "",
      initiated_from: "",
      state: "",
    },
    quotes: {
      rate: {
        info: "",
        amount: "",
      },
    },
    list: {
      data: [],
      pagination: {
        count: 0,
        limit: null,
        page: null,
      },
    },
  },
  balances: {
    withdrawalNetworks: [],
    depositsNetworks: [],
    history: {
      data: [],
      pagination: {
        count: 0,
        limit: null,
        page: null,
      },
    },
    limits: [],
    single: {},
    quotes: {},
    list: {
      balances: [],
      pagination: {
        count: 0,
        limit: null,
        page: null,
      },
    },
  },
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    unloadBuyTransactionDetails(state) {
      state.buy.single = initialState.buy.single;
    },
    unloadSellTransactionDetails(state) {
      state.sell.single = initialState.sell.single;
    },
    unloadSwapTransactionDetails(state) {
      state.swap.single = initialState.swap.single;
    },
    resetSellForm(state) {
      state.sell.limits = initialState.sell.limits;
      state.sell.rates = initialState.sell.rates;
    },
    resetSwapForm(state) {
      state.swap.limits = initialState.swap.limits;
      state.swap.quotes = initialState.swap.quotes;
    },
    resetDepositNetworks(state) {
      state.balances.depositsNetworks = [];
    },
    resetWithdrawalNetworks(state) {
      state.balances.withdrawalNetworks = [];
    },
    unloadBuyTransactions(state) {
      state.buy.list = initialState.buy.list;
    },
    unloadSellTransactions(state) {
      state.sell.list = initialState.sell.list;
    },
  },
  extraReducers: (builder) => {
    ///////////////////////////////////////////
    //////////////// BUY //////////////////////
    // List
    builder.addCase<any>(fetchBuyTranasactions.fulfilled, (state, { payload }) => {
      state.buy.list = payload?.data;
    });
    builder.addCase<any>(fetchBuyTranasactions.rejected, toastCaseErrorMSG);

    // Details
    builder.addCase<any>(fetchBuyTransactionDetials.fulfilled, (state, { payload }) => {
      state.buy.single = payload?.data;
    });
    builder.addCase<any>(fetchBuyTransactionDetials.rejected, toastCaseErrorMSG);

    // Currencies
    builder.addCase<any>(fetchBuySupportedCurrencies.fulfilled, (state, { payload }) => {
      state.buy.supportedCurrencies = payload?.data;
    });
    builder.addCase<any>(fetchBuySupportedCurrencies.rejected, toastCaseErrorMSG);

    // Payment methods
    builder.addCase<any>(fetchBuyPaymentMethods.fulfilled, (state, { payload }) => {
      state.buy.paymentMethods = payload?.data;
    });
    builder.addCase<any>(fetchBuyPaymentMethods.rejected, toastCaseErrorMSG);

    ////////////////////////////////////////////
    //////////////// SELL //////////////////////
    // Transactions
    builder.addCase<any>(fetchSellTranasactions.fulfilled, (state, { payload }) => {
      state.sell.list = payload?.data;
    });
    builder.addCase<any>(fetchSellTranasactions.rejected, toastCaseErrorMSG);

    // Transaction Details
    builder.addCase<any>(fetchSellTranasactionDetails.fulfilled, (state, { payload }) => {
      state.sell.single = payload?.data;
    });
    builder.addCase<any>(fetchSellTranasactionDetails.rejected, toastCaseErrorMSG);

    // Supported Currencies
    builder.addCase<any>(fetchSellSupportedCurrencies.fulfilled, (state, { payload }) => {
      state.sell.supportedCurrencies = payload?.data;
    });
    builder.addCase<any>(fetchSellSupportedCurrencies.rejected, toastCaseErrorMSG);

    // Limits
    builder.addCase<any>(fetchSellLimits.fulfilled, (state, { payload }) => {
      state.sell.limits = payload?.data;
    });
    builder.addCase<any>(fetchSellLimits.rejected, toastCaseErrorMSG);

    // Rates
    builder.addCase<any>(fetchSellRates.fulfilled, (state, { payload }) => {
      state.sell.rates = payload?.data;
    });
    builder.addCase<any>(fetchSellRates.rejected, toastCaseErrorMSG);

    // Banks
    builder.addCase<any>(fetchSellSupportedBanks.fulfilled, (state, { payload }) => {
      state.sell.banks = payload?.data;
    });
    builder.addCase<any>(fetchSellSupportedBanks.rejected, toastCaseErrorMSG);

    // Verify bank
    builder.addCase<any>(sendSellVerifyBank.rejected, toastCaseErrorMSG);

    // Destination
    builder.addCase<any>(sendSellSetDestination.rejected, toastCaseErrorMSG);

    // Amounts
    builder.addCase<any>(sendSellSetAmounts.rejected, toastCaseErrorMSG);

    // Process sell
    builder.addCase<any>(sendSellProcessTransaction.rejected, toastCaseErrorMSG);

    ////////////////////////////////////////////
    //////////////// SWAP /////////////////////
    builder.addCase<any>(fetchSwapTransactions.fulfilled, (state, { payload }) => {
      state.swap.list = payload?.data;
    });
    builder.addCase<any>(fetchSwapTransactions.rejected, toastCaseErrorMSG);

    // Details
    builder.addCase<any>(fetchSwapTransactionDetails.fulfilled, (state, { payload }) => {
      state.swap.single = payload?.data;
    });
    builder.addCase<any>(fetchSwapTransactionDetails.rejected, toastCaseErrorMSG);

    // Currencies
    builder.addCase<any>(fetchSwapCurrencies.fulfilled, (state, { payload }) => {
      state.swap.supportedCurrencies = payload?.data;
    });
    builder.addCase<any>(fetchSwapCurrencies.rejected, toastCaseErrorMSG);

    // Limits
    builder.addCase<any>(fetchSwapLimits.fulfilled, (state, { payload }) => {
      state.swap.limits = payload?.data;
    });
    builder.addCase<any>(fetchSwapLimits.rejected, toastCaseErrorMSG);

    // Quotes
    builder.addCase<any>(fetchSwapQuotes.fulfilled, (state, { payload }) => {
      state.swap.quotes = payload?.data;
    });
    builder.addCase<any>(fetchSwapQuotes.rejected, toastCaseErrorMSG);

    // Pairs
    builder.addCase<any>(sendSwapProcessConvertPairs.rejected, toastCaseErrorMSG);

    ////////////////////////////////////////////
    //////////////// BALANCES /////////////////
    // List
    builder.addCase<any>(fetchBalanceCrypto.fulfilled, (state, { payload }) => {
      state.balances.list = payload?.data;
    });
    builder.addCase<any>(fetchBalanceCrypto.rejected, toastCaseErrorMSG);

    // Withdrawal
    builder.addCase<any>(fetchBalanceWithdrawalNetworks.fulfilled, (state, { payload }) => {
      state.balances.withdrawalNetworks = payload?.data;
    });
    builder.addCase<any>(fetchBalanceWithdrawalNetworks.rejected, toastCaseErrorMSG);

    // Deposit Networks
    builder.addCase<any>(fetchBalanceDepositNetworks.fulfilled, (state, { payload }) => {
      state.balances.depositsNetworks = payload?.data;
    });
    builder.addCase<any>(fetchBalanceDepositNetworks.rejected, toastCaseErrorMSG);

    // Balance Withdraw
    builder.addCase<any>(sendBalanceWithdrawCrypto.rejected, toastCaseErrorMSG);

    // Deposit Address
    builder.addCase<any>(fetchBalanceCurrencyDepositAddress.rejected, toastCaseErrorMSG);

    // Balance Deposits
    builder.addCase<any>(fetchBalanceCurrencyDeposits.fulfilled, (state, { payload }) => {
      state.balances.history = {
        data: payload?.data?.data,
        pagination: payload?.data?.pagination,
      };
    });
    builder.addCase<any>(fetchBalanceCurrencyDeposits.rejected, toastCaseErrorMSG);

    // Balance Withdrawals
    builder.addCase<any>(fetchBalanceCurrencyWithdrawals.fulfilled, (state, { payload }) => {
      state.balances.history = {
        data: payload?.data?.data,
        pagination: payload?.data?.pagination,
      };
    });
    builder.addCase<any>(fetchBalanceCurrencyWithdrawals.rejected, toastCaseErrorMSG);
  },
});

export const {
  unloadBuyTransactionDetails,
  unloadSellTransactionDetails,
  unloadSwapTransactionDetails,
  resetSellForm,
  resetSwapForm,
  resetDepositNetworks,
  resetWithdrawalNetworks,
  unloadBuyTransactions,
  unloadSellTransactions,
} = transactionSlice.actions;

export default transactionSlice.reducer;
