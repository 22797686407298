import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "remixicon/fonts/remixicon.css";
import "react-toastify/dist/ReactToastify.css";
import "react-day-picker/dist/style.css";

import reportWebVitals from "./reportWebVitals";

import { RouterProvider } from "react-router-dom";
import APP_ROUTES from "./routes";

import { store } from "app/store";
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";
import LRSessionExpiredModal from "components/LRSessionExpiredModal";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';


const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        },
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ToastContainer
        pauseOnFocusLoss={false}
        limit={2}
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnHover
        theme="light"
      />
      {/* <LRSessionExpiredModal
        isVisible={!store.getState().auth.auth.isInSession}
        onClose={() => {}}
      /> */}
      <RouterProvider router={APP_ROUTES} />
    </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
