import { useAppDispatch, useAppSelector } from "app/hooks";
import { postEnableCurrency } from "app/slices/settings/api";
import { fetchSettingsMerchantCurrency } from "app/slices/settings/thunk";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isMerchantAdmin, isMerchantReadWrite } from "utility/storage";
import { LRCheckbox } from ".";

interface IManageSupportedCoinsProps {
  handleNav(x: string): void;
  list: { [x: string]: boolean };
  isLoadingData: boolean;
}

const ManageSupportedCoins = () => {
  const [query, setQuery] = useState("");
  const { coin_list: list } = useAppSelector(({ settings }) => settings.preferences);

  const [isSubmittingCoins, setIsSubmittingCoins] = useState(false);
  const [enabledCoins, setEnabledCoins] = useState<{ [x: string]: boolean }>(list);
  const [optionsToDisplay, setOptionsToDisplay] = useState<any>({ ...list });
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSettingsMerchantCurrency({})).finally(() => {
      setIsLoadingData(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(enabledCoins).length === Object.keys(list).length) return;
    setOptionsToDisplay({ ...list });
    setEnabledCoins({ ...list });
  }, [list]);

  const handleSearchParamChange = (e: any) => {
    const searchParam = e?.target?.value;
    setQuery(searchParam);
    if (!searchParam.trim().length) {
      setOptionsToDisplay({ ...list });
      return;
    }
    const queryToLowercase = searchParam.toUpperCase();
    const rankedIndex = Object.entries({ ...list }).filter(([coin]) =>
      coin.toUpperCase().includes(queryToLowercase)
    );
    setOptionsToDisplay(Object.fromEntries(rankedIndex));
  };

  const handleCoinClick = (coin: any) => {
    let payload = { ...optionsToDisplay };
    payload[coin] = !enabledCoins[coin];
    setEnabledCoins(payload);
    setOptionsToDisplay(payload);
  };

  const handleProcessDone = (updatedCoins?: any) => {
    setIsSubmittingCoins(true);
    postEnableCurrency({ coins: updatedCoins || enabledCoins })
      .then((res) => {
        toast.success(res?.data?.msg);
      })
      .finally(() => {
        setIsSubmittingCoins(false);
      });
  };
  const handleProcessDeselect = () => {
    let updatedCoins: any = { ...list };
    Object.keys(list).forEach((key) => {
      updatedCoins[key] = false;
    });
    setEnabledCoins(updatedCoins);
    setOptionsToDisplay(updatedCoins);
  };
  const handleProcessSelectAll = () => {
    let updatedCoins: any = { ...list };
    Object.keys(list).forEach((key) => {
      updatedCoins[key] = true;
    });
    setEnabledCoins(updatedCoins);
    setOptionsToDisplay(updatedCoins);
  };
  return (
    <div className="space-y-4">
      <div className="container-between">
        <p className="text-base font-semibold text-black-80 flex-grow">Supported Coins</p>
        <div className="w-16">
          <LRButton
            text="Done"
            size="sm"
            onClick={() => {
              handleProcessDone();
            }}
            isDisabled={
              (!isMerchantAdmin() && !isMerchantReadWrite()) ||
              !Object.values(enabledCoins).some((coin) => coin)
            }
            isLoading={isSubmittingCoins}
          />
        </div>
      </div>
      <div className="space-y-6">
        <div className="space-y-3">
          <LCInput
            label="Coin search"
            placeholder="Enter coin name"
            value={query}
            onChange={handleSearchParamChange}
          />
          <p className="grotesk text-xs text-black-40 font-medium">
            Check the cryptocurrencies you wish to enable on this account.
          </p>
        </div>
        {!isLoadingData ? (
          <LRCheckbox
            name={<span className="font-medium">All Coins</span>}
            coin={`${isAllSelected}`}
            isChecked={isAllSelected}
            onCheck={() => {
              if (!isAllSelected) {
                handleProcessSelectAll();
              } else {
                handleProcessDeselect();
              }
              setIsAllSelected(!isAllSelected);
            }}
          />
        ) : null}
        <ul className="space-y-4 max-h-[calc(100vh-400px)] overflow-y-scroll">
          {isLoadingData
            ? [1, 2, 3, 4, 5, 6].map((e, i) => (
                <div key={i} className="container-start space-x-2 py-1">
                  <div className="w-4 h-4 rounded-sm bg-black-20 animate-pulse"></div>
                  <div className="w-20 h-4 rounded-[4px] bg-black-20 animate-pulse"></div>
                </div>
              ))
            : Object.entries(optionsToDisplay).map(([coin, isChecked], i) => (
                <LRCheckbox
                  key={i}
                  name={coin}
                  coin={coin}
                  isChecked={isChecked as boolean}
                  onCheck={() => {
                    handleCoinClick(coin);
                  }}
                />
              ))}
        </ul>
      </div>
    </div>
  );
};

export default ManageSupportedCoins;
