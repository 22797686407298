import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const emailValidator = {
  required: true,
  pattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

// LOGIN
export const loginSchema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();
export const login2FASchema = yup
  .object({
    twoFactorToken: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export const teamLoginSchema = yup
  .object({
    merchantID: yup.string().required("Merchant ID is a required field"),
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export const team2FALoginSchema = yup
  .object({
    twoFactorToken: yup.string().required(),
    merchantID: yup.string().required("Merchant ID is a required field"),
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

// JOIN TEAM
export const joinTeamSchema = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .min(1, "At least 1 character is required"),
  lastName: yup
    .string()
    .required("Last name is required")
    .min(1, "At least 1 character is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be a minimum of 8 characters")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
      `Password must have at least 8 characters with at least one
      Number, one Symbol, one Uppercase, and one Lowercase character`
    ),
});

// SIGNUP
export const signUpSchema = yup
  .object({
    firstName: yup
      .string()
      .required("First name is required")
      .max(20, "First name must be a maximum of 20 characters"),
    lastName: yup
      .string()
      .required("Last name is required")
      .max(20, "Last name must be a maximum of 20 characters"),
    email: yup.string().email().required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be a minimum of 8 characters")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
        `Password must have at least 8 characters with at least one
      Number, one Symbol, one Uppercase, and one Lowercase character`
      ),
    phoneNumber: yup.string(),
    operatingBusinessName: yup
      .string()
      .required("Operating business name is required")
      .max(20, "Operating business name must be a maximum of 20 characters"),
  })
  .required();

export const emailVerificationOTPSchema = yup.object({
  otp: yup.string().required("Verification code is required"),
});

// RESET PASSWORD
export const teamRequestPasswordResetSchema = yup
  .object({
    merchantID: yup.string().required("Merchant ID is a required field"),
    email: yup.string().email().required("Email is required"),
  })
  .required();
export const requestPasswordResetSchema = yup.object({
  email: yup.string().email().required("Email is required"),
});
export const newPasswordSchema = yup.object({
  newPassword: yup
    .string()
    .min(8, "Password must be a minimum of 8 characters")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
      `Password must have at least 8 characters with at least one
  Number, one Symbol, one Uppercase, and one Lowercase character`
    )
    .required("Password is required"),
  confirmPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

// SETTINGS CHANGE PASSWORD -- DRY up
export const settingsChangePasswordSchema = yup.object({
  password: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .min(8, "Password must be a minimum of 8 characters")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
      `Password must have at least 8 characters with at least one
  Number, one Symbol, one Uppercase, and one Lowercase character`
    )
    .required("Password is required"),
  confirmPassword: yup.string().oneOf([yup.ref("newPassword"), null], "New passwords must match"),
});

// WEBHOOK URL
export const webhookChangeSchema = yup.object({
  webhookKey: yup
    .string()
    // .required("Webhook Token is required")
    .min(8, "Webhook Token should be more than 8 characters")
    .max(29, "Webhook Token should be less than 29 characters"),
  webhookURL: yup.string()
  // .required("Webhook URL is required"),
});

export const inviteMemberSchema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

// BALANCE - WITHDRAWAL

export const balanceWithdrawalFormWTag = yup.object({
  amount: yup.string(),
  address: yup.string(),
  destination_tag: yup.string(),
  network: yup.string(),
});
export const balanceWithdrawalFormWOTag = yup.object({
  amount: yup.string(),
  address: yup.string(),
  network: yup.string(),
});
