import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetSellForm } from "app/slices/transaction";
import { fetchSellLimits, fetchSellRates, fetchSellSupportedCurrencies, sendSellSetAmounts } from "app/slices/transaction/thunk";
import LCQuoteRefreshInfo from "components/LCQuoteRefreshInfo";
import LRButton from "components/LRButton";
import LRInputAmountDropdown from "components/LRInputAmountDropdown";
import { REFRESH_QUOTES_S } from "constants/transactions";
import useRefetch from "hooks/useRefetch";
import React, { useEffect, useState } from "react";
import { before_, calculateReceiverAmount, calculateSenderAmount, commalize } from "utility";
import { mul } from "utility/arithmeticFuncs";
import { SCREENS } from "..";

const SellForm = ({
	onSubmit,
	onCoinToSellSelect,
	balanceAmount,
	setCurrentScreen,
}: {
	onSubmit: (x: any) => void;
	onCoinToSellSelect: (coin: string) => void;
	balanceAmount: string;
	setCurrentScreen: React.Dispatch<React.SetStateAction<SCREENS>>;
}) => {
	const sellTransaction = useAppSelector(({ transaction }) => transaction.sell);
	const { supportedCurrencies, rates, limits } = sellTransaction;

	const [amountToReceive, setAmountToReceive] = useState(0);
	const [amountToSell, setAmountToSell] = useState(0);
	const [isLoadingSupportedCurrencies, setIsLoadingSupportedCurrencies] = useState(true);
	const [fieldError, setFieldError] = useState({ pay: false, get: false });
	const [hasAttemptedSumbit, setHasAttemptedSumbit] = useState(false);
	const [hasJustChangedCurrency, setHasJustChangedCurrency] = useState(false);
	const [isFetchingLimits, setIsFetchingLimits] = useState(true);

	const [currencyToSell, setCurrencyToSell] = useState({
		coin: "LTC",
		max_decimal: 8,
	});
	const [currencyToReceive, setCurrencyToReceive] = useState({
		fiat: "NGN",
		name: "Naira",
		country_code: "NG",
		max_decimal: 2,
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const dispatch = useAppDispatch();

	const [isRefetchingQuote] = useRefetch(
		() =>
			dispatch(
				fetchSellRates({
					from_currency: before_(currencyToSell.coin),
					to_currency: before_(currencyToReceive.fiat),
				})
			),
		REFRESH_QUOTES_S,
		[currencyToReceive, currencyToSell]
	);

	useEffect(() => {
		dispatch(
			fetchSellLimits({
				from_currency: before_(currencyToSell.coin),
				to_currency: currencyToReceive.fiat,
			})
		).finally(() => {
			setIsFetchingLimits(false);
		});
		return () => {
			dispatch(resetSellForm());
		};
	}, [currencyToReceive, currencyToSell]);

	useEffect(() => {
		if (!limits.from_currency) return;
		const refetchRates = setInterval(() => {
			dispatch(
				fetchSellLimits({
					from_currency: before_(currencyToSell.coin),
					to_currency: currencyToReceive.fiat,
				})
			).finally(() => {
				setIsFetchingLimits(false);
			});
		}, REFRESH_QUOTES_S * 1000);
		return () => {
			clearInterval(refetchRates);
		};
	}, [limits, currencyToReceive, currencyToSell]);

	useEffect(() => {
		if (supportedCurrencies?.from_currency?.length) return;
		dispatch(fetchSellSupportedCurrencies({})).then(() => {
			setIsLoadingSupportedCurrencies(false);
		});
	}, []);

	useEffect(() => {
		if (supportedCurrencies?.from_currency?.length) {
			setIsLoadingSupportedCurrencies(false);
		}
	}, [supportedCurrencies]);

	useEffect(() => {
		if (!rates?.rate?.amount || !limits.from_currency || isRefetchingQuote) return;
		setAmountToSell(Math.max(Number(limits.from_minimum) || 0, Number(amountToSell)));
		setAmountToReceive(
			calculateReceiverAmount({
				senderAmount: Math.max(Number(limits.from_minimum) || 0, Number(amountToSell)),
				exchangeRate: Number(rates.rate.amount),
				fee: rates.fee?.amount,
				dpGet: currencyToSell.max_decimal,
				dpPay: currencyToReceive.max_decimal,
			}) || 0
		);
		setIsFetchingLimits(false);
		setHasJustChangedCurrency(false);
	}, [rates, limits, isRefetchingQuote]);

	const handleAmountToReceiveChange = (value: string | undefined, fields?: any) => {
		if (value == `${amountToReceive}` || isSubmitting) return;
		setAmountToReceive(Number(value) || 0);
		setAmountToSell(
			calculateSenderAmount({
				receiverAmount: Number(value),
				exchangeRate: Number(rates?.rate.amount),
				fee: rates?.fee?.amount,
				dpGet: currencyToReceive.max_decimal,
				dpPay: currencyToSell.max_decimal,
			}) || 0
		);
	};

	const handleAmountToSellChange = (value: string | undefined) => {
		if (value == `${amountToSell}` || isSubmitting) return;
		setAmountToSell(Number(value) || 0);
		setAmountToReceive(
			calculateReceiverAmount({
				senderAmount: Number(value),
				exchangeRate: Number(rates.rate.amount),
				fee: rates.fee?.amount,
				dpGet: currencyToReceive.max_decimal,
				dpPay: currencyToSell.max_decimal,
			}) || 0
		);
	};

	const handleCurrencyToReceiveChange = (value: any) => {
		setHasJustChangedCurrency(true);
		setCurrencyToReceive(value);
	};

	const handleCurrencyToSellChange = (value: any) => {
		if (currencyToSell.coin === value?.coin) return;
		setHasJustChangedCurrency(true);
		setCurrencyToSell(value);
		onCoinToSellSelect(value?.coin.toLowerCase());
		handleAmountToSellChange(`${amountToSell}`);
	};

	const validateMinMax = (callback: (x: boolean) => void) => {
		const hasFormError = { pay: false, get: false };
		if ((Number(limits.from_minimum) || 0) > amountToSell || Number(limits.from_maximum) < amountToSell) {
			hasFormError.pay = true;
		}
		setFieldError(hasFormError);
		callback(hasFormError.pay || hasFormError.get);
	};

	useEffect(() => {
		if (limits && hasAttemptedSumbit) {
			validateMinMax(() => {});
		}
	}, [amountToSell, amountToReceive, limits]);

	const handleContinue = () => {
		setHasAttemptedSumbit(true);
		setIsSubmitting(true);

		validateMinMax((hasError: boolean) => {
			if (hasError) {
				setIsSubmitting(false);
				return;
			}
			dispatch(
				sendSellSetAmounts({
					from_currency: before_(currencyToSell.coin),
					to_currency: currencyToReceive.fiat,
					from_amount: `${amountToSell}`,
				})
			)
				.then(({ payload }: any) => {
					if (payload?.error_id) return;
					onSubmit({
						screen: payload?.data?.destination_type,
						reference: payload?.data?.reference,
						amount: `${amountToSell} ${before_(currencyToSell.coin)}`,
						currencyInfo: { currency: currencyToReceive.fiat, country_code: currencyToReceive.country_code },
					});
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		});
	};

	return (
		<div className="space-y-5">
			<div className="space-y-2">
				<div className="space-y-4">
					<LRInputAmountDropdown
						label="I want to sell"
						isSearchable
						value={String(Math.max(amountToSell, 0))}
						onChange={handleAmountToSellChange}
						onOptionChange={handleCurrencyToSellChange}
						selectedOption={currencyToSell}
						defaultOption={currencyToSell}
						dropDownData={supportedCurrencies?.from_currency || []}
						placeholder={"0.0"}
						dropdownTitle={"Select Currency"}
						decimalsLimit={currencyToSell?.max_decimal || 0}
						isCurrencyLoading={isLoadingSupportedCurrencies || isSubmitting}
						minValue={Number(limits?.from_minimum) || 0}
						maxValue={Number(limits?.from_maximum)}
						hasError={fieldError.pay}
						hasMaxAmountButton
						handleMaxClick={() => {
							handleAmountToSellChange(balanceAmount);
						}}
					/>
					<LRInputAmountDropdown
						label="Recipient gets"
						value={String(Math.max(amountToReceive, 0))}
						onChange={handleAmountToReceiveChange}
						onOptionChange={handleCurrencyToReceiveChange}
						selectedOption={currencyToReceive}
						defaultOption={currencyToReceive}
						dropDownData={supportedCurrencies?.to_currency || []}
						placeholder={"0.0"}
						dropdownTitle={"Select Currency"}
						decimalsLimit={currencyToReceive?.max_decimal || 0}
						isCurrencyLoading={isLoadingSupportedCurrencies || isSubmitting}
						isLoadingAmount={hasJustChangedCurrency}
						minValue={Number(mul(limits?.from_minimum, rates.rate.amount, currencyToReceive.max_decimal))}
						maxValue={Number(mul(limits?.from_maximum, rates.rate.amount, currencyToReceive.max_decimal))}
						hasError={fieldError.get}
					/>
				</div>
				<div className="w-full space-y-4">
					{rates?.rate?.amount ? <LCQuoteRefreshInfo shouldRefreshTimer={isRefetchingQuote} /> : null}
					{rates?.fee?.amount ? (
						<div className="text-xs container-between text-black-40">
							<p>Processing Fee</p>
							<p>
								{" "}
								<span className="font-medium text-black-80">
									{currencyToReceive.fiat.toUpperCase()} {commalize(Number(rates?.fee?.amount))}
								</span>
							</p>
						</div>
					) : null}
				</div>
			</div>
			<div className="w-full pt-3">
				<LRButton text="Continue" onClick={handleContinue} isLoading={isSubmitting} isDisabled={!rates?.rate?.amount} />
			</div>
		</div>
	);
};

export default SellForm;
