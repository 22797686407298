import React from "react";
import LRTableRow from "./LRTableRow";
import happySmile from "assets/images/happySmile.svg";

const LRTableBody = ({
  data,
  isEmpty,
  columns,
  isLoading,
  isLastChildRight,
  onRowClick = () => {},
}: {
  data: any[];
  columns: any[];
  isEmpty?: boolean;
  isLoading?: boolean;
  onRowClick?(row: any): void;
  isLastChildRight?: boolean;
}) => {
  return (
    <div className="lg:grid lg:gap-y-2 lg:shadow-none cursor-pointer">
      {isLoading ? (
        Array(5)
          .fill(0)
          .map((row, i) => (
            <LRTableRow
              key={i}
              isLoading={isLoading}
              isLastChildRight={isLastChildRight}
              columns={columns}
              rowData={{}}
              onRowClick={() => {}}
            />
          ))
      ) : isEmpty ? (
        <div className="flex-col container-center min-h-[315px] text-center w-full px-4">
          <div className="space-y-6">
            <div className="w-20 h-20 mx-auto">
              <img src={happySmile} alt="success" className="w-full h-full" />
            </div>
            <p className="max-w-xs w-full mx-auto text-sm font-medium text-black-40">
              You have no data yet. When you do, you’ll find them here.
            </p>
          </div>
        </div>
      ) : (
        data?.map((row: any, i: number) => (
          <LRTableRow
            key={i}
            isLastChildRight={isLastChildRight}
            columns={columns}
            rowData={row}
            onRowClick={() => {
              onRowClick(row);
            }}
          />
        ))
      )}
    </div>
  );
};

export default LRTableBody;
