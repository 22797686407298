export const REFRESH_QUOTES_S = 60;
export const POPULAR_COINS_LIST = [
  {
    coin: "BTC_BTC",
    name: "Bitcoin - Bitcoin",
    address_regex: "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$",
    memo_regex: "",
    withdraw_min: "0.001",
    withdraw_max: "7500",
    withdraw_fee: "0.0002",
    requires_tag: false,
    balance: null,
    max_decimal: 8,
    integer_multiple: "0.00000001",
    is_stable: false,
    estimated_arrival_time: "60",
  },
  {
    coin: "LTC_LTC",
    name: "Litecoin - Litecoin",
    address_regex: "^(L|M)[A-Za-z0-9]{33}$|^(ltc1)[0-9A-Za-z]{39}$",
    memo_regex: "",
    withdraw_min: "0.002",
    withdraw_max: "10000000000",
    withdraw_fee: "0.001",
    requires_tag: false,
    balance: null,
    max_decimal: 8,
    integer_multiple: "0.00000001",
    is_stable: false,
    estimated_arrival_time: "10",
  },
  {
    coin: "USDT_BSC",
    name: "TetherUS - BNB Smart Chain (BEP20)",
    address_regex: "^(0x)[0-9A-Fa-f]{40}$",
    memo_regex: "",
    withdraw_min: "10",
    withdraw_max: "10000000000",
    withdraw_fee: "0.29",
    requires_tag: false,
    balance: null,
    max_decimal: 8,
    integer_multiple: "0.00000001",
    is_stable: false,
    estimated_arrival_time: "5",
  },
  {
    coin: "ETH_ETH",
    name: "Ethereum - Ethereum (ERC20)",
    address_regex: "^(0x)[0-9A-Fa-f]{40}$",
    memo_regex: "",
    withdraw_min: "0.0098",
    withdraw_max: "10000000000",
    withdraw_fee: "0.000768",
    requires_tag: false,
    balance: null,
    max_decimal: 8,
    integer_multiple: "0.00000001",
    is_stable: false,
    estimated_arrival_time: "5",
  },
];
