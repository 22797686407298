import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "app/hooks";
import { resetPassword } from "app/slices/auth/thunk";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import LRSuccessPopup from "components/LRSuccessPopup";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toastMessage } from "utility/toast";
import { newPasswordSchema } from "utility/validators";

const tabs = [{ title: "Merchant" }, { title: "Team Member" }];
interface IFormValues {
  newPassword: string;
  confirmPassword?: string;
}

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(newPasswordSchema),
  });

  useEffect(() => {
    if (!searchParams.get("token")) {
      navigate("/auth/reset");
    }
  }, []);

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
    navigate("/auth/login");
  };

  const onSubmit = (data: IFormValues) => {
    setIsSubmittingForm(true);
    let dataPayload = {
      newPassword: data.newPassword,
      token: searchParams.get("token"),
    };
    dispatch(resetPassword(dataPayload))
      .then(({ payload }) => {
        if (payload?.error_id) {
          throw { message: payload?.msg };
        } else {
          if (payload.status === "success") {
            setIsSuccessModalVisible(true);
          } else {
            throw { message: "Something went wrong" };
          }
        }
      })
      .catch((error) => {
        toastMessage.error(error?.message);
      })
      .finally(() => {
        setIsSubmittingForm(false);
      });
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LocalRamp | New Password</title>
        <link rel="canonical" href="http://merchant.localramp.co" />
      </Helmet>
      <div className="px-6 md:px-10 pt-10 pb-14">
        <div>
          <div className="text-center space-y-2 mb-8">
            <h2 className="">Create New Password</h2>
            <p className="text-xs text-black-40 w-60 mx-auto grotesk">
              Kindly create your new password
            </p>
          </div>
          <div className="space-y-6">
            <form className="space-y-3">
              <LCInput
                label="New password"
                placeholder="Enter password"
                type="password"
                inputRegister={register("newPassword")}
                error={errors?.newPassword?.message}
              />
              <LCInput
                label="Confirm password"
                placeholder="Enter password again"
                type="password"
                inputRegister={register("confirmPassword")}
                error={errors?.confirmPassword?.message}
              />
            </form>
            <div className="space-y-6 text-center grotesk">
              <LRButton
                text="Create Password"
                onClick={handleSubmit(onSubmit)}
                isLoading={isSubmittingForm}
                isDisabled={!!errors?.newPassword?.message || !!errors?.confirmPassword?.message}
              />
              <div className="">
                <p className="font-light text-xs">
                  Do you have an account?{" "}
                  <Link to={"/auth/signup"} className="text-purple font-normal">
                    <span>Sign Up</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LRSuccessPopup
        title="New Password Created"
        message={<>You'll be directed to sign in again.</>}
        isVisible={isSuccessModalVisible}
        onClose={handleSuccessModalClose}
      />
    </>
  );
};

export default CreateNewPassword;
