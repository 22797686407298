import LRInputSelect from "components/LRInputSelect";
import LRInput from "components/LRInput";
import React, { useEffect, useState } from "react";
import LRButton from "components/LRButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchSellSupportedBanks, sendSellSetDestination, sendSellVerifyBank } from "app/slices/transaction/thunk";

const EnterPaymentInfo = ({ onProcessSell, isProcessingSell, reference }: { reference: string; isProcessingSell: boolean; onProcessSell(): any }) => {
	const dispatch = useAppDispatch();
	const supportedBanks = useAppSelector(({ transaction }) => transaction.sell.banks);

	const [isLoadingSupportedBanks, setIsLoadingSupportedBanks] = useState(true);
	const [hasLoadedDisplayName, setHasLoadedDisplayName] = useState(false);
	const [selectedBank, setSelectedBank] = useState({ name: "", code: "" });
	const [accountNumber, setAccountNumber] = useState("");
	const [isVerifyingNumber, setIsVerifyingNumber] = useState(false);
	const [displayName, setDisplayName] = useState("");

	const [isSettingDestination, setIsSettingDestination] = useState(false);

	useEffect(() => {
		dispatch(fetchSellSupportedBanks({})).then(() => {
			setIsLoadingSupportedBanks(false);
		});
	}, []);

	const handleSelectBank = (bank: { name: string; code: string }) => {
		setSelectedBank(bank);
		setIsVerifyingNumber(false);
		setHasLoadedDisplayName(false);
		setAccountNumber("");
		setDisplayName("");
	};

	useEffect(() => {
		if (accountNumber.length === 10) {
			setIsVerifyingNumber(true);
			dispatch(
				sendSellVerifyBank({
					account_number: accountNumber,
					bank_code: selectedBank.code,
					currency: "NGN",
				})
			)
				.then(({ payload }) => {
					if (payload?.data?.account_name) {
						setHasLoadedDisplayName(true);
						setIsVerifyingNumber(false);
						setDisplayName(payload?.data?.account_name);
					}
				})
				.finally(() => {
					setIsVerifyingNumber(false);
				});
		}
	}, [accountNumber, selectedBank]);

	const handleAccountNumberChange = (value: string) => {
		if (value.length > 10) {
			setHasLoadedDisplayName(false);
			setIsVerifyingNumber(false);
			setDisplayName("");
		}
		setAccountNumber(value);
	};

	const handleConfirmSell = () => {
		setIsSettingDestination(true);
		dispatch(
			sendSellSetDestination({
				reference,
				bank_code: selectedBank.code,
				destination_type: "bank_account",
				account_number: accountNumber,
			})
		)
			.then(({ payload }) => {
				if (!payload?.error_id) {
					onProcessSell();
				}
			})
			.finally(() => {
				setIsSettingDestination(false);
			});
	};

	return (
		<div className="flex-col w-full h-full pt-2 space-y-16 container-between">
			<div className="w-full space-y-3">
				<div className="space-y-4">
					<LRInputSelect
						hasSearch
						displayKey="name"
						label="Recepient bank"
						options={supportedBanks}
						isLoadingOptions={isLoadingSupportedBanks || isSettingDestination}
						selectedValueText={selectedBank.name}
						handleOptionSelect={handleSelectBank}
						placeholder="Select bank"
					/>
					<LRInput
						label="Recepient account number"
						type="number"
						placeholder="0000000000"
						value={accountNumber}
						isDisabled={isVerifyingNumber || isSettingDestination}
						onChange={(e) => {
							handleAccountNumberChange(e.target.value);
						}}
					/>
				</div>
				<div className="bg-gray py-[10px] px-4 rounded-md">
					<p className="text-xs font-medium">
						{isVerifyingNumber ? "Verifying bank..." : hasLoadedDisplayName ? displayName : "Display name"}
					</p>
				</div>
			</div>
			<div className="w-full">
				<LRButton
					text="Confirm sell"
					isLoading={isSettingDestination || isProcessingSell}
					isDisabled={!hasLoadedDisplayName || accountNumber.length !== 10 || isVerifyingNumber}
					onClick={handleConfirmSell}
				/>
			</div>
		</div>
	);
};

export default EnterPaymentInfo;
