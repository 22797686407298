import React, { useState } from "react";
import iconClose from "assets/icons/icon-close.svg";
import burgerMenu from "assets/icons/burgerMenu.svg";
import { useNavigate } from "react-router";
import { useClickOutside } from "hooks/useClickOutside";
import { getMerchantFromStorage } from "utility/storage";
import TopBarMobileNavList from "./TopBarMobileNavList";

interface ITopBarMobileNavProps {
  name: string;
}
const TopBarMobileNav = ({ name }: ITopBarMobileNavProps) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { popedComponentRef } = useClickOutside(setIsMenuOpen);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/login");
  };

  return (
    <div ref={popedComponentRef} className="bg-purple">
      <div className="p-6 border-b-[1.5px] border-b-purple-dark lg:border-b-gray-border">
        <div className="container-between w-full">
          <h2 className="text-white">{name}</h2>
          <div className="relative">
            <button onClick={handleMenuClick} className="w-6 h-6">
              {isMenuOpen ? (
                <i className="ri-close-fill text-white ri-xl" />
              ) : (
                <i className="ri-menu-fill text-white ri-xl" />
              )}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen ? <TopBarMobileNavList onChange={setIsMenuOpen} /> : null}
    </div>
  );
};

export default TopBarMobileNav;
