import { useAppDispatch, useAppSelector } from "app/hooks";
import { postEnableCurrency } from "app/slices/settings/api";
import { fetchSettingsMerchantCurrency } from "app/slices/settings/thunk";
import LRButton from "components/LRButton";
import React, { useEffect, useState } from "react";
import { isMerchantAdmin, isMerchantReadWrite } from "utility/storage";
import { LRCheckbox } from ".";

interface IManageSupportedFiatProps {
  handleNav(x: string): void;
  list: { [x: string]: boolean };
  isLoadingData: boolean;
}

const ManageSupportedFiat = () => {
  const dispatch = useAppDispatch();
  const { fiat_list: list } = useAppSelector(({ settings }) => settings.preferences);
  const [enabledFiats, setEnabledFiats] = useState<{ [x: string]: boolean }>(list);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    dispatch(fetchSettingsMerchantCurrency({})).finally(() => {
      setIsLoadingData(false);
    });
  }, []);

  useEffect(() => {
    setEnabledFiats(list);
  }, [list]);

  const handleProcessDone = () => {
    postEnableCurrency({ fiats: enabledFiats });
  };

  return (
    <div className="space-y-8">
      <div className="space-y-1">
        <div className="container-between">
          <p className="text-base font-semibold text-black-80 flex-grow">Supported Fiat</p>
          <div className="w-16">
            <LRButton
              isGreen
              size="sm"
              text="Done"
              isDisabled={(!isMerchantAdmin() && !isMerchantReadWrite()) || true}
              onClick={handleProcessDone}
            />
          </div>
        </div>
        <p className="grotesk text-xs text-black-40 font-medium">
          Check the currencies you wish to enable on this account
        </p>
      </div>
      {isLoadingData ? (
        <ul className="space-y-4">
          {[1, 2, 3, 4, 5, 6].map(() => (
            <div className="container-start space-x-2 py-1">
              <div className="w-4 h-4 rounded-sm bg-black-20 animate-pulse"></div>
              <div className="w-20 h-4 rounded-[4px] bg-black-20 animate-pulse"></div>
            </div>
          ))}
        </ul>
      ) : (
        <ul className="space-y-4">
          {Object.entries(list).map(([currency, isChecked], i) => (
            <LRCheckbox
              key={i}
              name={currency}
              coin={currency}
              isChecked={isChecked as boolean}
              onCheck={() => {}}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default ManageSupportedFiat;
