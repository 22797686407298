import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchBalanceCurrencyDepositAddress,
  fetchBalanceDepositNetworks,
} from "app/slices/transaction/thunk";
import LRButton from "components/LRButton";
import React, { useEffect, useState } from "react";
import LRInputSelect from "components/LRInputSelect";
import { copyToClipboard, maskWalletAddress } from "utility";
import { resetDepositNetworks } from "app/slices/transaction";

interface IFormValues {
  amount: string;
  address: string;
  destination_tag?: string;
  network: string;
}
const BalanceWithdrawalForm = ({
  currency,
  onSubmit,
}: {
  currency: string;
  onSubmit: (x: any) => void;
}) => {
  const dispatch = useAppDispatch();
  const networks = useAppSelector(({ transaction }) => transaction.balances.depositsNetworks);
  const [isFetchingDepositAddress, setIsFetchingDepositAddress] = useState(true);
  const [depositQR, setDepositQR] = useState("");
  const [depositData, setDepositData] = useState<any>();
  const [selectedNetwork, setSelectedNetwork] = useState<{
    name: string;
    id: string;
    requires_tag: boolean;
    address_regex: string;
    memo_regex: string;
  }>();
  const [isProcessingWithdraw, setIsProcessingWithdraw] = useState(false);
  const [isFetchingNetworks, setIsFetchingNetworks] = useState(true);

  useEffect(() => {
    if (!selectedNetwork?.id) return;
    dispatch(
      fetchBalanceCurrencyDepositAddress({
        currency: currency.toUpperCase(),
        network: selectedNetwork?.id,
      })
    )
      .then(({ payload }) => {
        setDepositData(payload?.data);
        setDepositQR(payload?.data?.qr);
      })
      .finally(() => {
        setIsFetchingDepositAddress(false);
      });
  }, [selectedNetwork]);

  useEffect(() => {
    setIsFetchingNetworks(true);
    setSelectedNetwork(undefined);
    dispatch(fetchBalanceDepositNetworks({ currency })).finally(() => {
      setIsFetchingNetworks(false);
    });
    return () => {
      dispatch(resetDepositNetworks());
    };
  }, []);
  useEffect(() => {
    setSelectedNetwork(networks[0]);
    return () => {
      setSelectedNetwork(undefined);
    };
  }, [networks]);

  const onContinue = () => {
    setIsProcessingWithdraw(true);
    setTimeout(() => {
      onSubmit(selectedNetwork?.id);
    }, 2000);
  };

  return (
    <div className="space-y-5">
      <div className="space-y-2 bg-[#F9F9FF] px-3 py-4 rounded-lg mx-4">
        <div className="space-y-4">
          <LRInputSelect
            label="Select network"
            options={networks}
            displayKey={"name"}
            isLoadingOptions={isFetchingNetworks}
            selectedValueText={isFetchingNetworks ? "" : selectedNetwork?.name}
            handleOptionSelect={(option) => {
              setSelectedNetwork(option);
            }}
            placeholder="Network"
          />
          <div className="space-y-8">
            <p className="text-xs grotesk px-6 text-black-40 text-center">
              Scan the QR code below or copy the wallet address.
            </p>
            <div className="w-24 h-24 mx-auto">
              {!isFetchingDepositAddress ? (
                <img src={depositQR} alt="" />
              ) : (
                <span className="mx-auto">
                  <div className="animate-spin text-center">
                    <i className="ri-loader-5-line text-black-40" />
                  </div>
                </span>
              )}
            </div>
          </div>
          <div className="space-y-2">
            <button
              onClick={() => {
                if (isFetchingDepositAddress) return;
                copyToClipboard(depositData?.address);
              }}
              className="rounded-[4px] py-2 px-3 border border-gray-border bg-white cursor-pointer w-full"
            >
              <p className="text-xxs grotesk font-medium text-black-40 text-left">Wallet address</p>
              <div className="container-between">
                <p className="text-xxs grotesk font-bold text-purple">
                  {maskWalletAddress(depositData?.address) || "---"}
                </p>
                {isFetchingDepositAddress ? (
                  <span className="ml-auto">
                    <div className="animate-spin text-center">
                      <i className="ri-loader-5-line text-black-40" />
                    </div>
                  </span>
                ) : (
                  <i className="ri-file-copy-fill text-black-80 text-base"></i>
                )}
              </div>
            </button>

            {depositData?.destination_tag ? (
              <button
                onClick={() => {
                  if (isFetchingDepositAddress) return;
                  copyToClipboard(depositData?.destination_tag);
                }}
                className="rounded-[4px] py-2 px-3 border border-gray-border bg-white cursor-pointer w-full"
              >
                <p className="text-xxs grotesk font-medium text-black-40 text-left">Memo tag</p>
                <div className="container-between">
                  <p className="text-xxs grotesk font-bold text-purple">
                    {depositData?.destination_tag || "---"}
                  </p>
                  {isFetchingDepositAddress ? (
                    <span className="ml-auto">
                      <div className="animate-spin text-center">
                        <i className="ri-loader-5-line text-black-40" />
                      </div>
                    </span>
                  ) : (
                    <i className="ri-file-copy-fill text-black-80 text-base"></i>
                  )}
                </div>
              </button>
            ) : null}
          </div>
          <div>
            <p className="text-black-80 text-xs grotesk font-medium text-center">
              Having trouble?{" "}
              <a href="mailto:support@localramp.co">
                <span className="text-purple font-normal">Contact support</span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full px-7">
        <LRButton
          text="I have made payment"
          onClick={() => {
            onContinue();
          }}
          isLoading={isProcessingWithdraw}
          isDisabled={isFetchingDepositAddress}
        />
      </div>
    </div>
  );
};

export default BalanceWithdrawalForm;
