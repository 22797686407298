import React, { ChangeEventHandler, ReactNode, useState } from "react";
import LRDropdown from "./LRDropdown";
import { getCountryPhoneCode } from "utility";

export interface ILCInputPropType {
	type?: "numbertel" | "text" | "email" | "url" | "amount" | "number" | "password" | "phoneNumber";
	placeholder?: string;
	label: string;
	value?: string;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	extension?: ReactNode;
	error?: string;
	isDisabled?: boolean;
	hasBorder?: boolean;
	hasAutoComplete?: boolean;
	inputRegister?: any;
	country_code?: string;
	setPhoneNumberExtension?: (extension: string) => void;
}
const LCInput = ({
	label,
	type = "text",
	value,
	onChange,
	placeholder,
	isDisabled,
	error,
	extension,
	inputRegister,
	hasBorder,
	hasAutoComplete = true,
	setPhoneNumberExtension,
	country_code,
}: ILCInputPropType) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const toggleIsPasswordVisible = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};
	const handlePhoneNumberExtensionChange = (e: any) => {
		setPhoneNumberExtension && setPhoneNumberExtension(e?.target?.value || "+44");
	};
	return (
		<div className="w-full">
			<div className={`${hasBorder ? "space-y-2" : "space-y-1"} flex flex-col items-start`}>
				<p className={`${hasBorder ? "text-xxs" : "text-xs"} font-medium text-black-40`}>{label}</p>
				<div
					className={`relative ${
						error?.trim().length
							? hasBorder
								? "border-2 border-red rounded-[4px]"
								: "border-b border-b-red pb-2"
							: hasBorder
							? "border-2 border-gray-border rounded-[4px]"
							: "border-b border-b-black-20 pb-2"
					} w-full text-sm font-medium flex  ${
						type === "numbertel" || type === "amount" || type === "phoneNumber" ? "flex space-x-3" : ""
					}`}
				>
					{type === "phoneNumber" && <p className="text-black">{getCountryPhoneCode(country_code)}</p>}
					{type === "numbertel" && (
						<div>
							<select name="extension" className="outline-none" onChange={handlePhoneNumberExtensionChange}>
								<option value="+44">+44</option>
								<option value="+234">+234</option>
							</select>
						</div>
					)}
					<input
						disabled={isDisabled}
						type={isPasswordVisible ? "text" : type === "numbertel" || type === "phoneNumber" ? "number" : type}
						value={value}
						onKeyDown={(evt) => {
							if (type === "number" || type === "numbertel") ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
						}}
						onChange={onChange}
						placeholder={placeholder}
						autoComplete={!hasAutoComplete ? "off" : undefined}
						className={`outline-none w-full flex-grow bg-white ${
							hasBorder ? "rounded-[4px] py-2 px-3 text-xs grotesk font-normal" : "font-medium"
						}`}
						onWheel={(e) => {
							e.currentTarget.blur();
						}}
						{...inputRegister}
					/>
					{type === "password" ? (
						<button type="button" className="-mb-2 text-base text-black-40" onClick={toggleIsPasswordVisible}>
							{isPasswordVisible ? <i className="ri-eye-off-fill" /> : <i className="ri-eye-fill" />}
						</button>
					) : null}
					<div className="">{extension}</div>
				</div>
			</div>
			{!!error?.trim().length && <p className="mt-1 text-xs text-left text-red">{error}</p>}
		</div>
	);
};

export default LCInput;
