import { useAppDispatch, useAppSelector } from "app/hooks";
import { unloadSellTransactionDetails } from "app/slices/transaction";
import { fetchSellTranasactionDetails } from "app/slices/transaction/thunk";
import LRDetailItem from "components/LRDetailItem";
import LRModal from "components/LRModal";
import LRStatusTag from "components/LRStatusTag";
import React, { useEffect, useState } from "react";
import { commalize, friendlyDateFormatter } from "utility";

interface ISellTransactionModalProps {
  isVisible: boolean;
  transactionRef: string;
  onClose: (v: boolean) => void;
}

const SellTransactionModal = ({
  transactionRef,
  isVisible,
  onClose,
}: ISellTransactionModalProps) => {
  const dispatch = useAppDispatch();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isMobileMoney, setIsMobileMoney] = useState(false);

  const transactionDetail = useAppSelector(({ transaction }) => transaction.sell.single) as any;
  useEffect(() => {
    if (transactionDetail?.reference.length) {
      if (transactionDetail?.destination_type == "bank_account") {
        setIsMobileMoney(false)
      }else{
        setIsMobileMoney(true)
      }
      setIsLoadingData(false);
    }
  }, [transactionDetail]);

  useEffect(() => {
    if (transactionRef.length) {
      dispatch(fetchSellTranasactionDetails({ reference: transactionRef }));
    }
    return () => {
      dispatch(unloadSellTransactionDetails());
      setIsLoadingData(true);
    };
  }, [transactionRef]);

  return (
    <LRModal
      hasMobileClose={false}
      isVisible={isVisible}
      onCloseModal={onClose}
      key={transactionDetail?.reference}
    >
      <>
        <div className="container-end">
          <button
            onClick={() => {
              onClose(false);
            }}
            className="border-0 outline-none bg-transparent hidden sm:inline-block"
          >
            <i className="ri-close-fill ri-lg text-black" />
          </button>
        </div>
        <div className="space-y-6">
          <div className="container-start space-x-2 pt-5 md:pt-0">
            <h3>Transaction Details</h3>
            {isLoadingData ? null : <LRStatusTag text={transactionDetail?.state} />}
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-10 md:gap-y-0">
            <ul className="bordered-list">
              <LRDetailItem
                isLoading={isLoadingData}
                copy={transactionDetail?.reference}
                title="Reference"
                value={`${transactionDetail?.reference}`}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Created At"
                value={`${friendlyDateFormatter(transactionDetail?.created_at)}`}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Updated At"
                value={`${friendlyDateFormatter(transactionDetail?.updated_at)}`}
              />
              <LRDetailItem
                isLoading={isLoadingData}
                hasCoin={!!transactionDetail?.from_amount}
                title="From"
                coin={{ coin: transactionDetail?.from_currency }}
                value={
                  transactionDetail?.from_amount
                    ? `${transactionDetail?.from_amount} ${transactionDetail?.from_currency}`
                    : "..."
                }
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="To"
                value={
                  transactionDetail?.to_amount
                    ? `${transactionDetail?.to_currency} ${commalize(
                        Number(transactionDetail?.to_amount)
                      )}`
                    : "..."
                }
              />
              <LRDetailItem
                isLoading={isLoadingData}
                title="Email"
                value={
                  transactionDetail?.email
                    ? `${transactionDetail?.email}`
                    : "..."
                }
              />
            </ul>
            <div className="space-y-5">
              <p className="text-base font-medium">Recepient's Information</p>
              <ul className="space-y-4">
                <LRDetailItem
                  isLoading={isLoadingData}
                  title="Destination Type"
                  value={`${transactionDetail?.destination_type?.replaceAll("_", " ")}`}
                />

                <LRDetailItem
                  isLoading={isLoadingData}
                  title="Initiated From"
                  value={`${transactionDetail?.initiated_from}`}
                />
                    <LRDetailItem
                      isLoading={isLoadingData}
                      title="Currency"
                      value={`${transactionDetail?.to_currency}`}
                      />
                {isMobileMoney ? (

                  <>
                    <LRDetailItem
                      isLoading={isLoadingData}
                      title="Recepient Number"
                      value={`${transactionDetail?.phone_number}`}
                      />
                    <LRDetailItem
                      isLoading={isLoadingData}
                      title="Network Provider"
                      value={`${transactionDetail?.phone_network}`}
                    />
                  </>
                ) : (
                  <>
                  <LRDetailItem
                    isLoading={isLoadingData}
                    title="Recipient Bank"
                    value={`${transactionDetail?.bank_name}`}
                  />
                <LRDetailItem
                  isLoading={isLoadingData}
                  title="Account Name"
                  value={`${transactionDetail?.account_name}`}
                />
                <LRDetailItem
                  isLoading={isLoadingData}
                  title="Account Number"
                  value={`${transactionDetail?.account_number}`}
                />
              </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    </LRModal>
  );
};

export default SellTransactionModal;
