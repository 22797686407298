import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchSettingsMerchantCurrency } from "app/slices/settings/thunk";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import ManageSupportedCoins from "./ManageSupportedCoins";
import ManageSupportedFiat from "./ManageSupportedFiat";
import PreferenceList from "./PreferenceList";

export enum EPreferencesScreen {
  ManageSupportedCoins = "manageSupportedCoins",
  ManageSupportedFiat = "manageSupportedFiat",
}
const Preferences = () => {
  const dispatch = useAppDispatch();
  const { fiat_list, coin_list } = useAppSelector(({ settings }) => settings.preferences);
  const [currentScreen, setCurrentScreen] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    dispatch(fetchSettingsMerchantCurrency({})).finally(() => {
      setIsLoadingData(false);
    });
  }, []);

  return <Outlet />;
};

export default Preferences;

interface ILRCheckboxProps {
  name: string | JSX.Element;
  coin: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onCheck: () => void;
}
export const LRCheckbox = ({ name, coin, isChecked, onCheck }: ILRCheckboxProps) => {
  return (
    <div className="space-x-2">
      <input
        type="checkbox"
        id={coin}
        name={coin}
        value={coin}
        checked={isChecked}
        onChange={onCheck}
      />
      <label htmlFor={""} className="grotesk text-sm">
        {name}
      </label>
    </div>
  );
};
