import { useWalletBalance } from "modules/transactions/hooks/useWalletBalance";
import React from "react";
import WalletBalance from "../../WalletBalance";
import SellForm from "./SellForm";
import { SCREENS } from "..";

const InitiateSell = ({
	onSubmit,
	setCurrentScreen,
}: {
	onSubmit: (x: any) => void;
	setCurrentScreen: React.Dispatch<React.SetStateAction<SCREENS>>;
}) => {
	const { balanceCoin, balanceAmount, setSelectedCoinToSell } = useWalletBalance();
	return (
		<div>
			<div className="w-full mb-5 container-between">
				<p className="text-base font-medium">Sell Coins</p>
				<WalletBalance balance={balanceCoin() ? `${balanceAmount()} ${balanceCoin()?.toUpperCase()}` : "--"} />
			</div>
			<SellForm
				onSubmit={onSubmit}
				onCoinToSellSelect={setSelectedCoinToSell}
				balanceAmount={balanceAmount()}
				setCurrentScreen={setCurrentScreen}
			/>
		</div>
	);
};

export default InitiateSell;
