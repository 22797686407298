import React from "react";

import { format } from "date-fns";
import { DayPicker, DateFormatter } from "react-day-picker";

const formatWeekdayName: DateFormatter = (day, options) => {
  return <>{format(day, "EEEEE", { locale: options?.locale })}</>;
};

interface ILRDatePickerProps {
  selected: Date | undefined;
  setSelected: (date: Date | undefined) => void;
}
const LRDatePicker = ({ selected, setSelected }: ILRDatePickerProps) => {
  return (
    <DayPicker
      mode="single"
      selected={selected}
      onSelect={setSelected}
      formatters={{ formatWeekdayName }}
    />
  );
};

export default LRDatePicker;
