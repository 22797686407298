import LRButton from "components/LRButton";
import LRInputSelect from "components/LRInputSelect";
import LRModal from "components/LRModal";
import React, { useState } from "react";

interface IExportRecordProps {
  isLoading?: boolean;
  isVisible: boolean;
  onClose: (v: boolean) => void;
  onExport: (exportType: string) => void;
}
const ExportRecord = ({ isVisible, onClose, onExport, isLoading }: IExportRecordProps) => {
  const [exportType, setExportType] = useState("");
  return (
    <LRModal isSmall isVisible={isVisible} onCloseModal={onClose} noPadding>
      <>
        <div className="space-y-2 py-6 pl-10 pr-6 border-b border-gray-border">
          <div className="container-between">
            <div>
              <p className="text-lg font-medium text-black">Export transactions</p>
              <p className="text-xs text-black-80">Export record in CSV format.</p>
            </div>
            <button
              onClick={() => {
                onClose(false);
              }}
              className="border-0 outline-none bg-transparent hidden sm:inline-block"
            >
              <i className="ri-close-fill ri-lg text-black" />
            </button>
          </div>
        </div>
        <div className="px-10 py-6 space-y-6">
          <div className="container-end space-x-2 w-full ml-auto">
            <div className="inline-block">
              <LRButton
                size="sm"
                isGray
                text="Cancel"
                onClick={() => {
                  onClose(false);
                }}
              />
            </div>
            <div className="inline-block">
              <LRButton
                size="sm"
                text="Export"
                onClick={() => onExport(exportType)}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </>
    </LRModal>
  );
};

export default ExportRecord;
