import LRButton from "components/LRButton";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import AuthCardWrapper from "./components/AuthCardWrapper";
import logo from "assets/images/logo.svg";
import iconClose from "assets/icons/icon-close.svg";
import burgerMenu from "assets/icons/burgerMenu.svg";
import { checkIfAuthenticated } from "utility/auth";
import useMobile from "hooks/useMobile";
import { useAppSelector } from "app/hooks";

const AuthLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isInSession = useAppSelector(({ auth }) => auth.auth.isInSession);

  useEffect(() => {
    if (checkIfAuthenticated()) {
      navigate("/dashboard");
    }
  }, []);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const [isMobile] = useMobile();

  const openDocumentation = () => {
    window.open("https://docs.localramp.co/", "_blank");
  };

  return (
    <div className="min-h-screen w-full bg-white md:bg-slate-50">
      <div className="auth-bg md:mx-14 lg:mx-[72px] relative">
        <div className="bg-white md:bg-slate-50 absolute w-full z-50">
          <div className="p-6 2xl:pb-5 2xl:pt-11 flex items-center justify-between ">
            <a href="http://localramp.co" target="_blank" rel="noopener noreferrer">
              <div className="xl:w-[168px] 2xl:w-[238px] h-auto">
                <img src={logo} alt="logo" className="w-full" />
              </div>
            </a>
            <div className="inline-block sm:hidden">
              <button onClick={toggleBurgerMenu} className="w-6 h-6">
                {isMobileMenuOpen ? (
                  <img src={iconClose} alt="open" className="w-full h-full" />
                ) : (
                  <img src={burgerMenu} alt="menu" className="w-full h-full" />
                )}
              </button>
            </div>
            <div className="hidden sm:flex items-center justify-end space-x-2">
              <button
                onClick={openDocumentation}
                className="flex sm:min-w-[175px] items-center justify-center space-x-1 xl:py-3 2xl:py-4 xl:px-4 2xl:px-8 font-medium text-purple"
              >
                <p className="text-xs 2xl:text-base">Open Documentation</p>
                <i className="ri-arrow-right-up-line 2xl:ri-lg"></i>
              </button>
              <div className="w-[160px]">
                {location.pathname.includes("login") ? (
                  <LRButton
                    isBlack
                    text={"Merchant Sign up"}
                    onClick={() => {
                      navigate("/auth/signup");
                    }}
                  />
                ) : (
                  <LRButton
                    isBlack
                    text={"Merchant Login"}
                    onClick={() => {
                      navigate("/auth/login");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {isMobileMenuOpen && isMobile ? (
            <>
              <div className="absolute w-full bg-white rounded-b-3xl top-0 left-0 right-0 shadow-lg z-50 ">
                <div className="container-between p-6 pb-0">
                  <div className="xl:w-[168px] 2xl:w-[238px] h-auto">
                    <img src={logo} alt="logo" className="w-full" />
                  </div>
                  <button onClick={toggleBurgerMenu} className="w-6 h-6">
                    <img src={iconClose} alt="open" className="w-full h-full" />
                  </button>
                </div>
                <div className="">
                  <ul className="space-y-5 py-6 pt-4">
                    <li className="">
                      <button
                        onClick={openDocumentation}
                        className="flex sm:min-w-[172px] px-6 items-center justify-center space-x-1 xl:py-3 2xl:py-4 xl:px-4 2xl:px-8 font-semibold text-purple"
                      >
                        <p className="text-base">Open Documentation</p>
                        <i className="ri-arrow-right-up-line 2xl:ri-lg"></i>
                      </button>
                    </li>
                    <button
                      onClick={() => {
                        navigate("/auth/signup");
                        setIsMobileMenuOpen(false);
                      }}
                      className="flex sm:min-w-[172px] px-6 items-center justify-center space-x-1 xl:py-3 2xl:py-4 xl:px-4 2xl:px-8 font-semibold text-black"
                    >
                      <p className="text-base">Merchant Sign up</p>
                    </button>
                    <button
                      onClick={() => {
                        navigate("/auth/login");
                        setIsMobileMenuOpen(false);
                      }}
                      className="flex sm:min-w-[172px] px-6 items-center justify-center space-x-1 xl:py-3 2xl:py-4 xl:px-4 2xl:px-8 font-semibold text-black"
                    >
                      <p className="text-base">Merchant Login</p>
                    </button>
                  </ul>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="h-24"></div>
        <div className="flex flex-col items-start sm:items-center justify-start sm:justify-center w-full min-h-screen relative pt-[88px] sm:-mt-24">
          <AuthCardWrapper>
            <Outlet />
          </AuthCardWrapper>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
