import useMobile from "hooks/useMobile";
import React from "react";
import TopBar from "./TopBar";
import TopBarMobileNav from "./TopBarMobileNav";

import { Helmet } from "react-helmet";

interface IAuthenticatedPagesOnlyProps {
  pageName: string;
  children: JSX.Element | JSX.Element[];
}
const AuthenticatedPagesOnly = ({ pageName, children }: IAuthenticatedPagesOnlyProps) => {
  const [isMobile] = useMobile();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LocalRamp | {pageName}</title>
        <link rel="canonical" href="http://merchant.localramp.co" />
      </Helmet>
      <div className="flex flex-col w-full">
        {isMobile ? <TopBarMobileNav name={pageName} /> : <TopBar name={pageName} />}
        <div className="lg:max-w-7xl pb-10">{children}</div>
      </div>
    </>
  );
};

export default AuthenticatedPagesOnly;
