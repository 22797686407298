import { yupResolver } from "@hookform/resolvers/yup";
import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { webhookChangeSchema } from "utility/validators";
import DetailItem from "./components/DetailItem";

import happySmile from "assets/images/happySmile.svg";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchSettingsAPIKeys,
  fetchSettingsWebhookInfo,
  sendSettingsResetAPIKeys,
  sendSettingsWebhookInfo,
} from "app/slices/settings/thunk";
import { maskWalletAddress } from "utility";
import useMobile from "hooks/useMobile";

interface IFormValues {
  webhookKey: string;
  webhookURL: string;
}
const APISettings = () => {
  const dispatch = useAppDispatch();
  const { public_key, secret_key } = useAppSelector(({ settings }) => settings.apiKeys);
  const { webhook_key, webhook_url } = useAppSelector(({ settings }) => settings.webhook);
  const [shouldShowWebhookForm, setShouldShowWebhookForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdatingWebhook, setIsUpdatingWebhook] = useState(false);
  const [isGeneratingAPIKeys, setIsGeneratingAPIKeys] = useState(false);

  const [isMobile] = useMobile();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(webhookChangeSchema),
    defaultValues: {
      webhookKey: webhook_key,
      webhookURL: webhook_url,
    },
  });

  useEffect(() => {
    dispatch(fetchSettingsAPIKeys({}));
    dispatch(fetchSettingsWebhookInfo({})).then(({ payload }) => {
      reset({ webhookKey: payload?.data?.webhook_key, webhookURL: payload?.data?.webhook_url });
    });
  }, []);

  const handleWebhookEditShow = () => {
    setShouldShowWebhookForm(true);
  };
  const handleSaveWebhook = (data: IFormValues) => {
    setIsSubmitting(true);
    dispatch(sendSettingsWebhookInfo(data))
      .then(({ payload }) => {
        if (payload?.status === "success") {
          setShouldShowWebhookForm(false);
          setIsUpdatingWebhook(true);
          dispatch(fetchSettingsWebhookInfo({})).then(() => {
            setIsUpdatingWebhook(false);
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleGenerateNewKeys = () => {
    setIsGeneratingAPIKeys(true);
    dispatch(sendSettingsResetAPIKeys({})).finally(() => {
      dispatch(fetchSettingsAPIKeys({})).then(() => {
        setIsGeneratingAPIKeys(false);
      });
    });
  };

  const hasRequiredAuth = () => true;

  return !hasRequiredAuth() ? (
    <div className="w-full mt-[202px] flex flex-col container-center">
      <div className="space-y-6">
        <img src={happySmile} alt="icon" className="mx-auto" />
        <div className="text-sm text-black-40 font-medium text-center max-w-xs mx-auto">
          <p>This tab is only visible to admins.</p>
          <p>Kindly update your permission to access.</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="space-y-8 md:space-y-2">
      <div
        className={`md:rounded-2xl  md:p-8 md:pb-4 md:bg-white md:border-b border-gray-border md:border-0`}
        style={{ boxShadow: isMobile ? "" : "0px 0px 8px 0px #EAEAEA8C" }}
      >
        <div className="container-between pb-2 md:pb-6 md:border-b-[1.5px] border-gray-border">
          <p className="text-xl md:text-base">API Keys</p>
          <div className="hidden md:block">
            {isGeneratingAPIKeys ? (
              <>
                <span className="ml-2">
                  <div className="animate-spin">
                    <i className="ri-loader-5-line text-purple" />
                  </div>
                </span>
              </>
            ) : (
              <button
                disabled={isGeneratingAPIKeys}
                onClick={handleGenerateNewKeys}
                className="border-none bg-transparent text-purple text-sm"
              >
                Generate new API keys
              </button>
            )}
          </div>
        </div>
        <div className="md:border-b border-gray-border">
          <DetailItem
            title={"Public Key"}
            hasCopy
            copy={public_key}
            value={maskWalletAddress(public_key)}
          />
        </div>
        <DetailItem
          title={"Secret Key"}
          hasCopy
          copy={secret_key}
          value={maskWalletAddress(secret_key)}
        />
        <div className="block ml-auto text-right md:hidden">
          {isGeneratingAPIKeys ? (
            <>
              <span className="ml-2">
                <div className="animate-spin">
                  <i className="ri-loader-5-line text-purple" />
                </div>
              </span>
            </>
          ) : (
            <button
              disabled={isGeneratingAPIKeys}
              onClick={handleGenerateNewKeys}
              className="border-none bg-transparent text-purple text-sm"
            >
              Generate new API keys
            </button>
          )}
        </div>
      </div>
      <hr className="md:hidden" />
      <div
        className={`md:rounded-2xl md:p-8 md:pb-4 md:bg-white`}
        style={{ boxShadow: isMobile ? "" : "0px 0px 8px 0px #EAEAEA8C" }}
      >
        <div className="space-y-4 text-left">
          <p className="text-xl md:text-base font-semibold text-black-80">Webhook Config</p>
          <p className="grotesk text-xs text-black-20 max-w-[707px]">
            {shouldShowWebhookForm ? (
              <span>
              Set up your Webhook URLs and tokens. Learn more about how they work in{" "}
              <a href="https://docs.localramp.co/webhooks/introduction" target="_blank" rel="noopener noreferrer">
                <span className="text-purple"> our documentation here</span>
              </a>{" "}.
              </span>
            ) : (
              <span>
                LocalRamp uses webhooks to notify your application when certain events occur in your
                account.{" "}
                <a href="https://docs.localramp.co/webhooks/introduction" target="_blank" rel="noopener noreferrer">
                  <span className="text-purple"> See our documentation</span>
                </a>{" "}
                to learn more about how to use webhooks.
              </span>
            )}
          </p>
        </div>
        <>
          {shouldShowWebhookForm ? (
            <div className="mt-8 space-y-6">
              <form className="max-w-[400px] space-y-4">
                <LCInput
                  label="Webhook Token"
                  placeholder="Enter webhook token"
                  inputRegister={register("webhookKey")}
                  error={errors?.webhookKey?.message}
                />
                <LCInput
                  label="Webhook URL"
                  placeholder="Enter webhook URL"
                  inputRegister={register("webhookURL")}
                  error={errors?.webhookURL?.message}
                />
              </form>
              <div className="border-t border-t-gray-border pt-4">
                <div className="container-start space-x-2 w-36 mr-auto ">
                  <LRButton
                    isGreen
                    size="sm"
                    text="Save"
                    isLoading={isSubmitting}
                    onClick={handleSubmit(handleSaveWebhook)}
                  />
                  <LRButton
                    size="sm"
                    isGray
                    isDisabled={isSubmitting}
                    text="Cancel"
                    onClick={() => {
                      setShouldShowWebhookForm(false);
                      reset();
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="container-end md:mt-6 md:pb-4 md:border-b-[1.5px] border-gray-border order-last">
                <button
                  onClick={handleWebhookEditShow}
                  className="border-none bg-transparent text-purple text-sm hidden md:inline-block"
                >
                  Update Token, and URLs
                </button>
              </div>
              <div className="md:border-b border-gray-border">
                <DetailItem
                  hasCopy
                  title={"Webhook Token"}
                  copy={isUpdatingWebhook ? "" : webhook_key}
                  value={maskWalletAddress(isUpdatingWebhook ? "" : webhook_key)}
                />
              </div>
              <DetailItem
                title={"Webhook URL"}
                value={webhook_url}
                hasCopy={false}
                hasMask={false}
              />
              <button
                onClick={handleWebhookEditShow}
                className="border-none bg-transparent text-purple text-sm block ml-auto text-right md:hidden"
              >
                Update Token, and URLs
              </button>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default APISettings;
