import { createSlice } from "@reduxjs/toolkit";
import { fetchDashboardStats } from "./thunk";

interface IInitialState {
  stats: {
    today_received_amount: number;
    month_received_amount: number;
    lifetime_received_amount: number;
    today_received_count: number;
    month_received_count: number;
    lifetime_received_count: number;
  };
}
const initialState: IInitialState = {
  stats: {
    today_received_amount: 0,
    month_received_amount: 0,
    lifetime_received_amount: 0,
    today_received_count: 0,
    month_received_count: 0,
    lifetime_received_count: 0,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase<any>(fetchDashboardStats.fulfilled, (state, { payload }) => {
      state.stats = payload?.data;
    });
    builder.addCase<any>(fetchDashboardStats.rejected, (state, { payload }) => {});
  },
});

export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
