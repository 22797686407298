import React, { useState } from "react";
import { storageMerchantRole } from "utility/storage";

interface ITab {
  key: string;
  title: string;
  roles?: string[];
}
interface IProps {
  activeTab: string;
  hasLine?: boolean;
  tabs: ITab[];
  children?: JSX.Element;
  onChange: (v: string) => void;
}

const LRTabTitle = ({
  isActive,
  title,
  onClick,
}: {
  isActive: boolean;
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <button className={`${isActive && "border-b-2 border-b-purple"} pb-2`} onClick={onClick}>
      <p
        className={`${
          isActive ? "text-purple" : "text-black-40"
        } text-base md:text-sm font-normal w-full`}
      >
        {title}
      </p>
    </button>
  );
};

const LRTabs = ({ tabs, hasLine, children, onChange, activeTab }: IProps) => {
  return (
    <div>
      <div
        className={`flex flex-wrap md:flex-nowrap items-end justify-between ${
          hasLine && "border-b border-b-[#E5E5E5]"
        }`}
      >
        <div className="grid grid-cols-2 gap-y-2 md:flex md:items-center md:justify-start md:space-x-4 w-full">
          {/* Refactor */}
          {tabs.map((tab, i) =>
            !tab.roles ? (
              <LRTabTitle
                key={i}
                isActive={activeTab === tab.key}
                title={tab.title}
                onClick={() => {
                  onChange(tab.key);
                }}
              />
            ) : tab.roles?.includes(storageMerchantRole()) ? (
              <LRTabTitle
                key={i}
                isActive={activeTab === tab.key}
                title={tab.title}
                onClick={() => {
                  onChange(tab.key);
                }}
              />
            ) : null
          )}
        </div>
        <div className="hidden md:inline-block">{children}</div>
      </div>
      <div></div>
    </div>
  );
};

export default LRTabs;
