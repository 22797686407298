import { createAsyncThunk } from "@reduxjs/toolkit";
import { processCall } from "services/api";
import {
  delete2FA,
  get2FADetails,
  getAPIKeys,
  getMerchantCurrency,
  getWebhookInfo,
  putChangePassword,
  postComplete2FA,
  postEnableCurrency,
  postResetAPIKeys,
  postResetCurrencyList,
  postSetBuyFee,
  postSetup2FA,
  postWebhookInfo,
} from "./api";

export const removeSettings2FA = createAsyncThunk("transaction/delete2FA", processCall(delete2FA));
export const fetchSettingsWebhookInfo = createAsyncThunk(
  "transaction/getWebhookInfo",
  processCall(getWebhookInfo)
);
export const fetchSettingsMerchantCurrency = createAsyncThunk(
  "transaction/getMerchantCurrency",
  processCall(getMerchantCurrency)
);
export const fetchSettingsAPIKeys = createAsyncThunk(
  "transaction/getAPIKeys",
  processCall(getAPIKeys)
);
export const fetchSettings2FADetails = createAsyncThunk(
  "transaction/get2FADetails",
  processCall(get2FADetails)
);
export const sendSettingsComplete2FA = createAsyncThunk(
  "transaction/postComplete2FA",
  processCall(postComplete2FA)
);
export const updateSettingsPasswordChange = createAsyncThunk(
  "transaction/putChangePassword",
  processCall(putChangePassword)
);
export const sendSettingsEnableCurrency = createAsyncThunk(
  "transaction/postEnableCurrency",
  processCall(postEnableCurrency)
);
export const sendSettingsResetAPIKeys = createAsyncThunk(
  "transaction/postResetAPIKeys",
  processCall(postResetAPIKeys)
);
export const sendSettingsResetCurrencyList = createAsyncThunk(
  "transaction/postResetCurrencyList",
  processCall(postResetCurrencyList)
);
export const sendSettingsSetBuyFee = createAsyncThunk(
  "transaction/postSetBuyFee",
  processCall(postSetBuyFee)
);
export const sendSettingsSetup2FA = createAsyncThunk(
  "transaction/postSetup2FA",
  processCall(postSetup2FA)
);
export const sendSettingsWebhookInfo = createAsyncThunk(
  "transaction/postWebhookInfo",
  processCall(postWebhookInfo)
);
