import { createAsyncThunk } from "@reduxjs/toolkit";
import { processCall } from "services/api";
import {
  postLogin,
  postPreRegisterMerchant,
  postRegisterMerchant,
  postRequestPasswordReset,
  postResetPassword,
  postTeamJoin,
} from "./api";

// THE
// THUNKS

export const preRegisterMerchant = createAsyncThunk(
  "auth/postPreRegisterMerchant",
  processCall(postPreRegisterMerchant)
);

export const registerMerchant = createAsyncThunk(
  "auth/postRegisterMerchant",
  processCall(postRegisterMerchant)
);

export const loginMerchant = createAsyncThunk("auth/postLogin", processCall(postLogin));

export const joinTeam = createAsyncThunk("auth/postTeamJoin", processCall(postTeamJoin));

export const requestPasswordReset = createAsyncThunk(
  "auth/postRequestPasswordReset",
  processCall(postRequestPasswordReset)
);

export const resetPassword = createAsyncThunk(
  "auth/postResetPassword",
  processCall(postResetPassword)
);
