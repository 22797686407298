import LRButton from "components/LRButton";
import LCInput from "components/LRInput";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import verificationEnvelope from "assets/images/verificationEnvelope.svg";
import { loginMerchant, preRegisterMerchant, registerMerchant } from "app/slices/auth/thunk";
import { useAppDispatch } from "app/hooks";
import { toastMessage } from "utility/toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailVerificationOTPSchema } from "utility/validators";

interface IFormValues {
  otp: string;
}

const EmailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeSending, setIsCodeSending] = useState(false);

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(emailVerificationOTPSchema),
  });

  useEffect(() => {
    if (!location?.state?.data?.email) {
      navigate("/auth/signup");
    }
  }, [location, navigate]);

  const onSubmit = (data: IFormValues) => {
    setIsSubmittingForm(true);
    dispatch(registerMerchant({ ...location?.state?.data, ...data }))
      .then(({ payload }) => {
        if (payload?.error_id) {
          throw { message: payload?.msg };
        } else {
          if (payload.merchant) {
            dispatch(
              loginMerchant({
                email: payload?.merchant?.email,
                password: location?.state?.data?.password,
              })
            )
              .then(({ payload }) => {
                if (!payload.error_id) {
                  navigate("/dashboard");
                }
              })
              .catch((error) => {
                toastMessage.error(error?.message);
              })
              .finally(() => {
                setIsSubmittingForm(false);
              });
          } else {
            throw { message: "Something went wrong" };
          }
        }
      })
      .catch((error) => {
        toastMessage.error(error?.message);
      })
      .finally(() => {
        setIsSubmittingForm(false);
      });
  };
  const handlResendCode = () => {
    setIsCodeSending(true);
    resetField("otp");
    dispatch(preRegisterMerchant(location?.state?.data))
      .then(({ payload: { status, error_id, msg } }) => {
        if (error_id) {
          throw { message: msg };
        }
        if (status <= 200 || status >= 400 || !location?.state?.data) {
          throw { message: "Something went wrong" };
        }
        setIsCodeSent(true);
      })
      .catch((error) => {
        toastMessage.error(error?.message);
      })
      .finally(() => {
        setIsSubmittingForm(false);
        setIsCodeSending(false);
      });
  };

  useEffect(() => {
    if (!isCodeSent) return;
    const sto = setTimeout(() => {
      setIsCodeSent(false);
    }, 2500);

    return () => {
      clearTimeout(sto);
    };
  }, [isCodeSent]);

  return (
    <div className="px-6 md:px-10 py-14">
      <div className="space-y-12">
        <div className="text-center space-y-2 mb-6">
          <img src={verificationEnvelope} alt="verification" className="mx-auto" />
          <div className="text-sm text-black-40 font-light w-60 mx-auto">
            <p>We sent a code to</p>
            <p className="font-semibold text-black-80">{location?.state?.data?.email}</p>
          </div>
        </div>
        <div className="space-y-4">
          <form className="">
            <LCInput
              label="Enter verification code"
              placeholder="Enter code here"
              type="text"
              inputRegister={register("otp")}
              error={errors?.otp?.message}
              extension={
                <ResendOTP
                  handleResendClick={handlResendCode}
                  isSending={isCodeSending}
                  isSent={isCodeSent}
                />
              }
            />
          </form>
          <div className="space-y-4 text-center">
            <LRButton text="Submit" isLoading={isSubmittingForm} onClick={handleSubmit(onSubmit)} />

            <p className="font-light text-xs max-w-xs px-3 mx-auto text-black-40">
              By signing up, I agree to Localramp
              <a href="https://localramp.co/terms-of-use/" target={"_blank"}>
                <span className="text-purple font-normal"> Terms of Service </span>
              </a>
              and
              <a href="https://localramp.co/privacy/" target={"_blank"}>
                <span className="text-purple font-normal"> Privacy Policy. </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;

interface IResendOTPProps {
  handleResendClick: () => void;
  isSending: boolean;
  isSent: boolean;
}
const ResendOTP = ({ handleResendClick, isSending, isSent }: IResendOTPProps) => {
  return isSending ? (
    <div className="animate-spin ">
      <div className="w-4 h-4 ml-auto">
        <i className="ri-loader-5-line text-purple ri-lg" />
      </div>
    </div>
  ) : isSent ? (
    <p className="text-purple text-xs font-medium text-right w-16 py-1">Code sent!</p>
  ) : (
    <button
      onClick={handleResendClick}
      className="text-purple text-xs font-medium bg-purple/10 rounded-full border-0 p-1 px-2 w-24"
    >
      Resend code
    </button>
  );
};
