import { useAppDispatch } from "app/hooks";
import { loginMerchant } from "app/slices/auth/thunk";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { toastMessage } from "utility/toast";

export interface IFormValues {
  email: string;
  password: string;
  merchantID?: string;
  twoFactorToken?: string;
}

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldAccept2FA, setShouldAccept2FA] = useState(false);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const onSubmit = (data: IFormValues) => {
    setIsSubmittingForm(true);
    dispatch(loginMerchant(data))
      .then((res) => {
        if (res?.payload?.error_id) {
          if (res?.payload?.error_id === "2FARequired") {
            setShouldAccept2FA(true);
          } else {
            throw { message: res?.payload?.msg };
          }
        } else {
          if (res?.payload.data) {
            if (location?.state?.data) {
              navigate(location?.state?.data);
            } else {
              navigate("/dashboard");
            }
          }
        }
      })
      .catch((error) => {
        toastMessage.error(error?.message);
      })
      .finally(() => {
        setIsSubmittingForm(false);
      });
  };

  return {
    isSubmittingForm,
    onSubmit,
    shouldAccept2FA,
  };
};

export default useLogin;
