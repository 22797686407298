import React from "react";

const WalletBalance = (props: { balance: string }) => {
  return (
    <div className="max-w-[170px]">
      <div className="py-1 px-3 bg-[#ECECEC] rounded-md">
        <p className="text-xxs text-[#747474] grotesk">
          <span className="text-black-80">Available: {props.balance}</span>
        </p>
      </div>
    </div>
  );
};

export default WalletBalance;
