import { useWalletBalance } from "modules/transactions/hooks/useWalletBalance";
import React from "react";
import SwapForm from "./SwapForm";

const InitiateSwap = ({ onSubmit }: { onSubmit: (x: any) => void }) => {
  const { balanceCoin, balanceAmount, setSelectedCoinToSell } = useWalletBalance();

  return (
    <div className="space-y-5">
      <div className="container-between w-full">
        <p className="text-base font-medium">Swap Coins</p>
        <div className="max-w-[170px]">
          <div className="py-1 px-3 bg-[#ECECEC] rounded-md">
            <p className="text-xxs text-[#747474] grotesk">
              <span className="text-black-80">
                Available:{" "}
                {balanceCoin() ? `${balanceAmount()} ${balanceCoin()?.toUpperCase()}` : "--"}
              </span>
            </p>
          </div>
        </div>
      </div>
      <SwapForm
        onSubmit={onSubmit}
        onCoinToSellSelect={setSelectedCoinToSell}
        balanceAmount={balanceAmount()}
      />
    </div>
  );
};

export default InitiateSwap;
