import { api } from "services/api";
import { ENDPOINTS } from "services/endpoints";
import { constructParams } from "utility";

const { transaction } = ENDPOINTS;

export const getBuyTransactions = (params: any) => {
  return api.get(`${transaction.BUY_TRANSACTIONS}?${constructParams(params)}`, {});
};

export const getBuyTransactionDetials = ({ reference }: { reference: string }) =>
  api.get(`${transaction.BUY_TRANSACTION_DETAILS}/${reference}`, {});

export const getBuyPaymentMethods = () => api.get(`${transaction.BUY_PAYMENT_METHODS}`, {});

export const getBuySupportedCurrencies = (params: any) =>
  api.get(`${transaction.BUY_SUPPORTED_CURRENCY}`, {});

// SELL
export const getSellTransactions = (params: any) =>
  api.get(`${transaction.SELL_TRANSACTIONS}?${constructParams(params)}`, {});

export const getSellTransactionDetials = ({ reference }: { reference: string }) =>
  api.get(`${transaction.SELL_TRANSACTION_DETAILS}/${reference}`, {});

export const getSellSupportedCurrencies = () =>
  api.get(`${transaction.sell.SUPPORTED_CURRENCIES}`, {});

export const getSellLimits = (params: any) =>
  api.get(`${transaction.sell.LIMITS}?${constructParams(params)}`, {});

export const getSellTransactionStatus = (reference: any) =>
  api.get(`${transaction.sell.TRANSACTION_STATUS}/${reference}`, {});

export const getSellSupportedBanks = () =>
  api.get(`${transaction.sell.SUPPORTED_BANKS}?country=NG`, {});

export const getSellRates = (params: any) =>
  api.get(`${transaction.sell.RATE}?${constructParams(params)}`, {});

export const postSellVerifyBank = (body: {
  account_number: string;
  bank_code: string;
  currency: string;
}) => api.post(`${transaction.sell.VERIFY_BANK_ACCOUNT}`, body, {});

export const postSellProcessTransaction = (body: { reference: string }) =>
  api.post(`${transaction.sell.PROCESS_TRANSACTION}`, body, {});

export const postSellSetDestination = (body: {
  account_number: string;
  bank_code: string;
  reference: string;
  destination_type: string;
}) => api.post(`${transaction.sell.SET_DESTINATION}`, body, {});

export const postSellSetAmounts = (body: {
  from_currency: string;
  to_currency: string;
  from_amount: string;
}) => api.post(`${transaction.sell.SET_AMOUNTS}`, body, {});

//SWAP
export const getSwapTransactions = (params: any) =>
  api.get(`${transaction.SWAP_TRANSACTIONS}?${constructParams(params)}`, {});

export const getSwapTransactionDetails = ({ reference }: { reference: string }) =>
  api.get(`${transaction.SWAP_TRANSACTION_DETAILS}/${reference}`, {});

export const getSwapCurrencies = () => api.get(`${transaction.swap.AVAILABLE_CURRENCIES}`, {});

export const getSwapQuotes = (params: any) =>
  api.get(`${transaction.swap.GET_QUOTES}?${constructParams(params)}`, {});

export const getSwapLimits = (params: any) =>
  api.get(`${transaction.swap.LIMITS}?${constructParams(params)}`, {});

export const postSwapProcessConvertPairs = (body: {
  from_currency: string;
  to_currency: string;
  from_amount: string;
}) => api.post(`${transaction.swap.PROCESS_CONVERT_PAIRS}`, body, {});

export const getSwapVerifyTransaction = (reference: any) =>
  api.get(`${transaction.swap.VERIFY_TRANSACTION}/${reference}`, {});

// BALANCES
export const getBalanceCrypto = (params: any) =>
  api.get(`${transaction.BALANCE_CRYPRO}?${constructParams(params)}`, {});

export const getBalanceCurrencyDeposits = (data: { currency: string; params: any }) =>
  api.get(
    `${transaction.BALANCE_CRYPRO}/${data.currency.toUpperCase()}/deposits?${constructParams(
      data.params
    )}`,
    {}
  );

export const getBalanceCurrencyDepositAddress = (p: { currency: any; network: any }) =>
  api.get(`${transaction.BALANCE_CRYPRO}/${p.currency}/deposit-address?network=${p.network}`, {});

export const getBalanceCurrencyWithdrawals = (data: { currency: string; params: any }) =>
  api.get(
    `${transaction.BALANCE_CRYPRO}/${data.currency.toUpperCase()}/withdrawals?${constructParams(
      data.params
    )}`,
    {}
  );

export const getBalanceCurrencyWithdrawalLimit = (data: { currency: string }) =>
  api.get(`${transaction.BALANCE_CRYPRO}/${data.currency}/withdraw/limits`, {});

export const getBalanceCurrencyWithdrawalsFee = (data: { currency: string }) =>
  api.get(`${transaction.BALANCE_CRYPRO}/${data.currency}/withdrawal-fee`, {});

export const getBalanceDepositNetworks = (data: { currency: string }) =>
  api.get(`${transaction.BALANCE_CRYPRO}/${data.currency}/deposit/networks`, {});

export const getBalanceWithdrawalNetworks = (data: { currency: string }) =>
  api.get(`${transaction.BALANCE_CRYPRO}/${data?.currency}/withdrawal/networks`, {});

export const postBalanceWithdrawCrypto = (p: {
  currency: string;
  body: {
    amount: string;
    address: string;
    destination_tag: string;
    network: string;
  };
}) => api.post(`${transaction.BALANCE_CRYPRO}/${p.currency}/withdraw`, p.body, {});
