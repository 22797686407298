import React from "react";
import { menuItemsAccount, menuItemsTransactions, SidebarItem } from "./Sidebar";

const TopBarMobileNavList = (props: { onChange: (x: boolean) => void }) => {
  return (
    <div className="absolute top-[73.5px] w-full bg-purple h-[calc(100vh-73.5px)] z-40">
      <div className="space-y-0">
        <SidebarItem iconName="ri-home-3-line" text="Home" link="/dashboard" />
        {menuItemsTransactions.map((item, i) => (
          <SidebarItem
            key={i}
            {...item}
            onChange={() => {
              props.onChange(false);
            }}
          />
        ))}
        {menuItemsAccount.map((item, i) => (
          <SidebarItem
            key={i}
            {...item}
            onChange={() => {
              props.onChange(false);
            }}
          />
        ))}
        <SidebarItem
          iconName="ri-logout-box-r-line"
          text="Logout"
          link="/auth/logout"
          onChange={() => {
            props.onChange(false);
          }}
        />
      </div>
    </div>
  );
};

export default TopBarMobileNavList;
