import React from "react";

export const AuthErrorPage = () => {
  return (
    <div className="px-6 md:px-10 pt-8 pb-5">
      <div className="text-center pb-3">
        <div className="grotesk text-red bg-rose-200 px-4 py-2 border border-rose-300 inline-block rounded-md">
          <p className="text-xs">{"Something went wrong"}</p>
        </div>
      </div>
    </div>
  );
};
