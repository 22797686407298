import React, { useState } from "react";
import AccountSecurity from "./AccountSecurity";
import ChangePassword from "./ChangePassword";
import TwoFactorAuth from "./TwoFactorAuth";

const Security = () => {
  const [currentScreen, setCurrentScreen] = useState("");
  switch (currentScreen) {
    case "password":
      return <ChangePassword handleNav={setCurrentScreen} />;
    case "2fa":
      return <TwoFactorAuth handleNav={setCurrentScreen} />;
    default:
      return <AccountSecurity handleNav={setCurrentScreen} />;
  }
};

export default Security;
