import { useAppDispatch, useAppSelector } from "app/hooks";
import { unloadSellTransactions } from "app/slices/transaction";
import { getSellTransactions } from "app/slices/transaction/api";
import { fetchSellTranasactions, fetchSellSupportedCurrencies } from "app/slices/transaction/thunk";
import LRButton from "components/LRButton";
import LRDateFormatter from "components/LRDateFormatter";
import LRStatusTag from "components/LRStatusTag";
import LRTable, { ILRTableColumnProps } from "components/LRTable";
import LRTabs from "components/LRTabs";
import AuthenticatedPagesOnly from "containers/AuthenticatedPagesOnly";
import React, { useEffect, useState } from "react";
import { commalize } from "utility";
import { checkIfAuthenticated } from "utility/auth";
import { isMerchantAdmin, isMerchantReadWrite } from "utility/storage";
import SellForm from "../components/SellForm";
import SellTransactionModal from "../components/SellTransactionModal";
import { DataType, ISwapData } from "../types";

const tabs = [
	{ title: "Completed", key: "completed" },
	{ title: "Pending", key: "pending" },
];
const SellTransactions = () => {
	const [currentTab, setCurrentTab] = useState("completed");
	const dispatch = useAppDispatch();

	const sellTransaction = useAppSelector(({ transaction }) => transaction.sell);

	const { supportedCurrencies } = sellTransaction;

	const [selectedTransactionRef, setSelectedTransactionRef] = useState("");
	const [shouldShowTransactionModal, setShouldShowTransactionModal] = useState(false);
	const [shouldShowSellForm, setShouldShowSellForm] = useState(false);

	const [isDataLoading, setIsDataLoading] = useState(true);
	const [tableFilter, setTableFilter] = useState({});
	const [allData, setAllData] = useState<{ [x: string]: { data: any[]; pagination: any } }>({});

	const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(true);

	const initiateFetchData = (params: any) => {
		dispatch(fetchSellTranasactions({ state: params?.state || currentTab, ...params }))
			.then(({ payload }) => {
				if (payload?.data) setAllData({ ...allData, [params?.state || currentTab]: payload?.data });
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};
	const initiateFetchSupportedCurrencies = () => {
		dispatch(fetchSellSupportedCurrencies({})).finally(() => {
			setIsLoadingCurrencies(false);
		});
	};
	useEffect(() => {
		if (!checkIfAuthenticated()) return;
		initiateFetchData({ state: "completed" });
		initiateFetchSupportedCurrencies();
		return () => {
			dispatch(unloadSellTransactions());
		};
	}, []);

	const columns: ILRTableColumnProps<ISwapData>[] = [
		{
			key: "state",
			title: "Status",
			render: (state, {}) => <LRStatusTag text={currentTab} />,
		},
		{
			key: "to_amount",
			title: "Amount",
			render: (amount, { to_currency }) =>
				amount ? (
					<p className="text-xs font-bold text-black-80 grotesk">
						<span className="text-black-20">{to_currency}</span> {commalize(Number(amount)) || "..."}
					</p>
				) : (
					<p className="text-xs text-black-20 grotesk">...</p>
				),
		},
		{
			key: "from_amount",
			title: "Description",
			render: (amount, { from_currency }) => (
				<p className="text-xs font-medium text-black-40 grotesk">
					Sold <span className={`text-black-80 font-bold ${amount ? "" : "italic"}`}>{amount}</span> {from_currency}
				</p>
			),
		},
		{
			key: "created_at",
			title: "Date Created",
			render: (date) => <LRDateFormatter date={`${date}`} />,
		},
		{
			key: "updated_at",
			title: "Last Activity",
			render: (date) => <LRDateFormatter date={`${date}`} />,
		},
	];

	const handleRowClick = (row: DataType) => {
		if (!row?.reference) return;
		setSelectedTransactionRef(row.reference);
		setShouldShowTransactionModal(true);
	};
	const handleTableChange = (paramValue: any) => {
		setIsDataLoading(true);
		const value = { ...paramValue };
		if (value.hasOwnProperty("toCurrency")) {
			value.toCurrency = value.toCurrency?.fiat;
		}
		if (value.hasOwnProperty("fromCurrency")) {
			value.fromCurrency = value.fromCurrency?.coin;
		}
		delete value.count;
		if (!value.query) {
			delete value.query;
		}
		if (!value.page || value.query) {
			setTableFilter({ ...value });
			initiateFetchData({ ...value, page: 1 });
		} else {
			initiateFetchData({ ...tableFilter, ...value });
		}
	};
	const handleTabChange = (key: string) => {
		if (key === currentTab) {
			return;
		}
		dispatch(unloadSellTransactions());
		setIsDataLoading(true);
		initiateFetchData({ state: key });
		setCurrentTab(key);
	};

	return (
		<AuthenticatedPagesOnly pageName="Sell transactions">
			<>
				<div className="px-6 pt-6 space-y-6 md:px-14">
					<LRTabs hasLine tabs={tabs} onChange={handleTabChange} activeTab={currentTab}>
						<div className="w-[110px] pb-2">
							{isMerchantAdmin() || isMerchantReadWrite() ? (
								<LRButton
									prefixIconName="ri-arrow-right-up-line"
									text="Sell coins"
									onClick={() => {
										setShouldShowSellForm(true);
									}}
								/>
							) : null}
						</div>
					</LRTabs>
					<LRTable
						data={allData[currentTab]?.data || []}
						pagination={allData[currentTab]?.pagination || {}}
						dataState={currentTab}
						fetchDataFunc={getSellTransactions}
						mobileOnlyChild={
							isMerchantAdmin() || isMerchantReadWrite() ? (
								<>
									<LRButton
										prefixIconName="ri-arrow-right-up-line"
										text="Sell coins"
										onClick={() => {
											setShouldShowSellForm(true);
										}}
									/>
								</>
							) : (
								<></>
							)
						}
						columns={columns}
						isLoading={isDataLoading}
						onRowClick={handleRowClick}
						filterFields={[
							{
								type: "coinType",
								hasNetwork: false,
								isSearchable: true,
								key: "fromCurrency",
								keyForOption: "coin",
								placeholder: "Select coin",
								searchPlaceholder: "Search coin",
								label: "Coin Type",
								isLoading: isLoadingCurrencies,
								options: supportedCurrencies?.from_currency,
							},
							{
								type: "coinType",
								hasNetwork: false,
								key: "toCurrency",
								isSearchable: true,
								keyForOption: "fiat",
								placeholder: "Select currency",
								searchPlaceholder: "Search currency",
								label: "Currency",
								isLoading: isLoadingCurrencies,
								options: supportedCurrencies?.to_currency,
							},
							{ type: "startDate" },
							{ type: "endDate" },
							{ type: "query" },
						]}
						onChange={handleTableChange}
					/>
				</div>
				{shouldShowTransactionModal ? (
					<SellTransactionModal
						transactionRef={selectedTransactionRef}
						isVisible={shouldShowTransactionModal}
						onClose={setShouldShowTransactionModal}
					/>
				) : null}

				<SellForm isVisible={shouldShowSellForm} onClose={setShouldShowSellForm} />
			</>
		</AuthenticatedPagesOnly>
	);
};

export default SellTransactions;
