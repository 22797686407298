import React, { useEffect, useRef } from "react";

export const useClickOutside = (onClosePopedComponent: (x: boolean) => void) => {
  const popedComponentRef = useRef<any>(null);

  const handleClick = (e: any) => {
    if (!popedComponentRef || !popedComponentRef.current) return;
    if (popedComponentRef?.current?.contains(e?.target)) {
      return;
    }
    onClosePopedComponent(false);
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return { popedComponentRef };
};
