import React from "react";
import { copyToClipboard } from "utility";

interface IDetailItemProps {
  value: string;
  title: string;
  copy?: string;
  hasCopy?: boolean;
  hasMask?: boolean;
}
const DetailItem = ({ title, value, copy, hasCopy = true, hasMask = true }: IDetailItemProps) => {
  return (
    <div className="space-y-1 md:space-y-0 md:flex md:items-center md:justify-between py-4">
      <p className="grotesk text-sm font-bold text-black-40">{title}</p>
      <div
        role={hasCopy ? "button" : ""}
        onClick={() => {
          if (!hasCopy) return;
          copyToClipboard(`${copy}`);
        }}
        className="text-black-20 md:text-black-40 text-left md:text-right text-sm grotesk font-semibold"
      >
        {hasCopy && copy?.length ? (
          <div className="flex items-center md:container-end space-x-1">
            <p>{value}</p>
            <i className="ri-file-copy-fill text-black-20 text-base"></i>
          </div>
        ) : (
          value
        )}
      </div>
    </div>
  );
};

export default DetailItem;
