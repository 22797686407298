import useMobile from "hooks/useMobile";
import React, { useEffect, useState } from "react";
import { ILRTableColumnProps } from ".";
import useColumnWidth from "./useColumnWidth";

const LRTableRow = ({
  columns,
  rowData,
  onRowClick = () => {},
  isLastChildRight,
  isLoading,
}: {
  columns: ILRTableColumnProps<any>[];
  rowData: any;
  onRowClick?(): void;
  isLastChildRight?: boolean;
  isLoading?: boolean;
}) => {
  const [isMobile] = useMobile();
  const { customWidthColsNumber, customWidthSum } = useColumnWidth(columns);

  return (
    <div
      onClick={onRowClick}
      className={`space-y-4 lg:flex lg:space-y-0 w-full p-5 lg:px-0 lg:py-4 border-b-[0.5px] lg:border-[0.5px] border-gray-border rounded-t-lg lg:rounded-[4px] items-center outline-none ${
        isLastChildRight ? "lr-table-row bg-white" : "bg-gray-lighter"
      }`}
    >
      {columns.map(({ key, title, render, width }, i) => (
        <div
          key={i}
          className="flex items-center lg:block"
          style={{
            width: isMobile
              ? "100%"
              : `${width || (100 - customWidthSum) / (columns.length - customWidthColsNumber)}%`,
          }}
        >
          <p className="inline-block lg:hidden font-bold text-xs text-black-40 capitalize">
            {title}:
          </p>
          {isLoading ? (
            <p className="mx-[10px] lg:mx-4 h-4 rounded-md bg-gray-200 animate-pulse text-transparent">
              transparent text
            </p>
          ) : (
            <div className="px-[10px] lg:px-4 text-left">
              {render ? (
                render(rowData[key], rowData)
              ) : (
                <p key={i} className="text-xs">
                  {rowData[key]}
                </p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default LRTableRow;
