import React, { useState } from "react";
import LRRecommendation from "components/LRRecommendation";
import apiKeysIcon from "assets/icons/apiKeys.svg";
import documentationIcon from "assets/icons/documentation.svg";
import { useNavigate } from "react-router";
import { isMerchantAdmin } from "utility/storage";

const TopRecommendations = () => {
  const [isRecommendationsShowing, setIsRecommendationsShowing] = useState(true);
  const navigate = useNavigate();
  const handleRecommendationsViewToggle = () => {
    setIsRecommendationsShowing(!isRecommendationsShowing);
  };
  return (
    <div className="space-y-8 w-full">
      <div className="flex items-center justify-between">
        <p className="font-medium text-base">
          {isRecommendationsShowing ? "Top recommendations for you" : null}
        </p>
        <button className="outline-none" onClick={handleRecommendationsViewToggle}>
          <div className="flex items-center justify-end space-x-1">
            <p className="text-sm grotesk text-black-20">
              {isRecommendationsShowing ? "Hide" : "Show recommendations"}
            </p>
            {isRecommendationsShowing ? (
              <i className="ri-close-line text-black-20" />
            ) : (
              <i className="ri-add-line text-black-20" />
            )}
          </div>
        </button>
      </div>
      <div className={`container-start space-x-6 ${!isRecommendationsShowing && "invisible"}`}>
        {isMerchantAdmin() ? (
          <LRRecommendation
            icon={apiKeysIcon}
            emphasis={"Get API keys"}
            otherText={"to integrate Localramp with your apps."}
            handleStartClick={() => {
              navigate("/dashboard/settings/api");
            }}
          />
        ) : null}
        <LRRecommendation
          icon={documentationIcon}
          emphasis={"See documentation"}
          otherText={"to explore Localramp more."}
          handleStartClick={() => {
            window.open("https://docs.localramp.co/", "_blank");
          }}
        />
      </div>
    </div>
  );
};

export default TopRecommendations;
